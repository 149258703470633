import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInstructorReviews, clearReviews } from '../../redux/slices/reviewsSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../api/firebaseConfig';
import CartModal from '../CartModal/index';
import Footer from '../Footer/index'
import HeroSection from './DetailComponents/CourseDetailsHero';
import AboutCourse from './DetailComponents/AboutCourse';
import CourseFormat from './DetailComponents/CourseFormat';
import TopicsCovered from './DetailComponents/TopicsCovered';
import SampleSchedule from './DetailComponents/SampleSchedule';
import Instructor from '../../components/InstructorCard/InstructorCard';
import AvailableSessions from './DetailComponents/AvailableSessions';
import CourseInfoBar from './DetailComponents/CourseInfoBar';
import TemporarySignup from './TemporarySignup'
import BlogsFeaturedViewing from '../FeaturedViewing/BlogsFeaturedViewing';
import CoursesFeaturedViewing from '../FeaturedViewing/CoursesFeaturedViewing';

const CourseDetails = () => {
    const [showCartModal, setShowCartModal] = useState(false);
    // const location = useLocation();
    const { courseId } = useParams();
    // const course = location.state?.course || {};
    const course = useSelector(state => state.courses.courses.find(course => course.id === courseId));
    const [latestUser, setLatestUser] = useState(null);
    const user_obj = useSelector(state => state.user.user);
    // this is used to switch from actual enrollment to signup.
    const [disableEnrollment] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { reviews, status, error } = useSelector((state) => state.reviews);
    console.log(`${latestUser ? 'user found' : 'user does not exist'}`)

    useEffect(() => {
        if (user_obj && user_obj.uid) {
            const userDocRef = doc(db, 'users', user_obj.uid);
            const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
                if (docSnap.exists()) {
                    setLatestUser(docSnap.data());
                }
            });
            return () => unsubscribe();
        }
    }, [user_obj]);

    useEffect(() => {
        dispatch(clearReviews());
    }, [dispatch]);

    useEffect(() => {
        if (course.instructors?.length > 0) {
            const allReviewIds = course.instructors.flatMap(instructor => instructor.reviews);
            console.log("allReviewIds", allReviewIds)
            dispatch(fetchInstructorReviews(allReviewIds));
        }
    }, [dispatch, course.instructors]);

    if (status === 'loading') {
        return <div>Loading reviews...</div>;
    }

    if (status === 'failed') {
        return <div>Error fetching reviews: {error}</div>;
    }
    console.log("course check", course)

    return (
        <div>
            {/* Hero Section */}
            <HeroSection course={course}/>
            
            {/* Instructor Cards */}
            <div className="relative z-20 w-full sm:w-3/4 lg:w-full -mt-6">
                <CourseInfoBar 
                    duration={course.duration} 
                    styleFormat={course.styleFormat} 
                    instructors={course.instructors} 
                />
            </div>

            {/* Flex Layout: Left side 2/3, right side 1/3 */}
            <div className="flex flex-col lg:flex-row gap-12 px-4 lg:px-12 mt-8">
                
                {/* Left Column (3/5 of screen) */}
                <div className='w-full lg:w-3/5 px-2'>
                    <AboutCourse about={course.about} />
                    <div className="border-t border-gray-300 my-4 w-full"></div>
                    
                    <TopicsCovered topics={course.topicsCovered} />
                    <div className="border-t border-gray-300 my-4 w-full"></div>
                    
                    <CourseFormat format={course.courseFormat} />
                    <div className="border-t border-gray-300 my-4 w-full"></div>
                    
                    <SampleSchedule schedule={course.sampleSchedule} />
                    <div className="border-t border-gray-300 my-4 w-full"></div>

                    <div className="mt-12">
                        <h1 className='p-6 text-3xl font-medium'>Instructors</h1>
                        {course.instructors?.length > 0 ? (
                            course.instructors.map((instructor, index) => (
                                <Instructor 
                                    key={index} 
                                    instructor={instructor} 
                                    reviews={reviews.filter(review => review.instructorID === instructor.id)} 
                                />
                            ))
                        ) : (
                            <div>No instructors available for this course.</div>
                        )}
                    </div>
                </div>

                {/* Right Column (2/5 of screen, Sticky AvailableSessions) */}
                <div className='w-full lg:w-2/5 relative lg:z-20'>
                    <div className={`lg:sticky lg:top-36 ${disableEnrollment?(`lg:-mt-56`):(`lg:-mt-96`)}`}> {/* Sticky applied here */}
                        {disableEnrollment?(<TemporarySignup course={course}/>):<AvailableSessions course={course || {}} setShowCartModal={setShowCartModal} />}
                        {/* <AvailableSessions course={course || {}} setShowCartModal={setShowCartModal} /> */}
                    </div>
                </div>
            </div>
            <div className='mt-12'>
                <CoursesFeaturedViewing excludedCourseID={course.id} tags={course.category}/>
            </div>
            <div className='mt-12'>
                <BlogsFeaturedViewing/>
            </div>

            <Footer />

            {showCartModal && (
                <CartModal
                    onClose={() => setShowCartModal(false)}
                    onCheckout={() => {
                        setShowCartModal(false);
                        navigate('/checkout');
                    }}
                    isVisible={showCartModal}
                    courseImage={course.imageID}
                />
            )}
        </div>
    );
};

export default CourseDetails;
