import React from 'react';

const AboutCourse = ({ format }) => {
    return (
        <div className="mt-12 px-8">
            <h2 className="text-3xl font-medium mb-4">Course Format</h2>
            <p className="text-textGray text-base leading-relaxed">{format}</p>
        </div>
    );
};

export default AboutCourse;
