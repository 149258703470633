import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faStar, faUsers, faBalanceScale, faHandsHelping } from '@fortawesome/free-solid-svg-icons';
import heroImg from '../../assets/img/careerHero.jpg';

const coreValues = [
    { id: 1, title: "Innovation", description: "Pushing boundaries to create meaningful solutions.", icon: faLightbulb },
    { id: 2, title: "Quality", description: "Commitment to excellence in every aspect of our work.", icon: faStar },
    { id: 3, title: "Collaboration", description: "Success through teamwork and open communication.", icon: faUsers },
    { id: 4, title: "Integrity", description: "Ethical practices and personal accountability in all we do.", icon: faBalanceScale },
    { id: 5, title: "Inclusivity", description: "Fostering a diverse and respectful workplace.", icon: faHandsHelping },
];

const CareerCategory = ({ positions }) => {
  return (
    <div className="min-h-screen flex flex-col">
      {/* Hero Section */}
      <div className="relative w-full h-64 mt-4 md:h-96 lg:h-[650px]">
        <img 
          src={heroImg} 
          alt="Hero" 
          className="absolute inset-0 w-full h-full object-cover object-center animate-bounce-3" 
        />
        <div className="absolute inset-0 bg-black opacity-0"></div>
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-white text-4xl md:text-5xl lg:text-6xl font-bold">
            Join Our Team
          </h1>
        </div>
      </div>

      {/* Core Values Section */}
      <div className="mx-auto px-4 py-12 bg-white">
        <h2 className="text-4xl font-medium text-center mb-8">Our values are part of everything built here</h2>
            <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
                {coreValues.map((value) => (
                <div key={value.id} className="flex flex-col items-center text-center bg-bg-gray-50 rounded-lg p-6">
                    <FontAwesomeIcon icon={value.icon} className="text-6xl text-primary mb-4" />
                    <h3 className="text-xl font-semibold mb-2">{value.title}</h3>
                    <p className="text-gray-600">{value.description}</p>
                </div>
                ))}
            </div>
        </div>


      {/* Open Positions Section */}
      <div className="bg-white py-12">
        <div className="mx-auto px-4 max-w-4xl"> 
          <h2 className="text-3xl font-medium text-center mb-8">Find Your Role</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-y-12 gap-x-6 text-center">
          {positions.map((position, index) => (
                <Link 
                    to={`/careers/${position.id}`} 
                    key={index}
                    className="group bg-white max-w-sm mx-auto w-full border border-gray-100 shadow-lg rounded-2xl p-10 transform transition-all duration-300 hover:shadow-blue-100 hover:-translate-y-3"


                >
                    <div className="block">
                    <h3 className="text-2xl font-medium mb-2 text-text">
                        {position.title}
                    </h3>
                    <p className="text-gray-700 p-x-8 pt-4">
                        {position.description}
                    </p>
                    </div>
                </Link>
            ))}

          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default CareerCategory;
