import React from "react";
import CustomButton from "../../utilities/UIcomponents/buttons/AnimatedButton";
import { faArrowRightFromBracket, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

const PortalHero = ({ user, userInitial, imageUrl, handleLogout, setActiveTab }) => {
    return (
        <div className="w-full">
            {/* First Section - Image Background */}
            <div
                className="relative h-60 md:h-44 bg-cover bg-center"
                style={{
                    backgroundImage: `url(${imageUrl})`,
                }}
            ></div>

            {/* Second Section - White Background */}
            <div className="relative h-44 bg-transparent flex justify-center md:justify-end md:items-center bg-white">
                {/* User Information Wrapper */}
                <div className="
                    absolute left-1/2 top-0 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center
                    md:left-1/4 md:-top-1/2 md:-translate-x-40 md:translate-y-10 md:items-start lg:-translate-x-56">
                    
                    {/* User Initial */}
                    <div className="flex items-center justify-center bg-webDarker border-4 border-white text-white font-normal rounded-full w-24 h-24 md:w-32 md:h-32 text-3xl shadow-lg">
                        {userInitial}
                    </div>
                    
                    {/* User Name and Location */}
                    <div className="mt-4 text-center md:text-left">
                        <h1 className="font-medium text-2xl">{user.name}</h1>
                        <p className="text-sm text-gray-500 mt-1">New York, United States</p>
                    </div>
                </div>
                {/* Settings Sections */}
                <div className="flex flex-row justify-end items-center space-x-4 mt-28 md:mr-14 md:mt-0">
                    {/* Edit Profile Button */}
                    {/* <button
                        className="w-full md:w-auto px-4 py-2 bg-webDarker text-white rounded-full cursor-pointer text-nowrap text-base transition-colors duration-300 ease-in-out hover:bg-white hover:text-webDarker hover:border hover:border-webDarker"
                        onClick={() => setActiveTab('settings')}
                    >
                        Edit Profile
                    </button> */}
                    <CustomButton
                        onClick={() => setActiveTab('settings')}
                        icon={faPenToSquare}
                        bgColor="bg-webDarker"
                        hoverBgColor="hover:bg-white"
                        textColor="text-white"
                        hoverTextColor="hover:text-webDarker"
                        size="px-14 py-5 text-base"
                    >
                        Edit Profile
                    </CustomButton>
                    <CustomButton
                        onClick={handleLogout}
                        icon={faArrowRightFromBracket}
                        bgColor="bg-[#7aa2e338]"
                        hoverBgColor="hover:bg-webDarker"
                        textColor="text-webDarker"
                        hoverTextColor="hover:text-white"
                        size="px-14 py-5 text-base"
                    >
                        Log Out
                    </CustomButton>
                </div>

            </div>
        </div>
    );
};

export default PortalHero;
