import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeItemFromCart } from '../../redux/slices/cartSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faShoppingCart, faTrash, faLock, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { analytics } from '../../api/firebaseConfig';
import { logEvent } from 'firebase/analytics';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../../api/firebaseConfig';

function CartModal({ onClose, onCheckout, isVisible }) {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const modalRef = useRef(null);
  const secureCheckoutRef = useRef(null);

  const [courseImgs, setCourseImgs] = useState({}); 
  const [showAlert, setShowAlert] = useState(false); 
  useEffect(() => {
    const fetchImages = async () => {
      const urls = {};
      for (const item of cartItems) {
        if (item.imageID) {
          try {
            const storageRef = ref(storage, item.imageID);
            const url = await getDownloadURL(storageRef);
            urls[item.courseId] = url;
          } catch (error) {
            console.error(`Error fetching image for ${item.courseName}:`, error);
          }
        }
      }
      setCourseImgs(urls); // Update state with all the fetched URLs
    };

    if (cartItems.length > 0) {
      fetchImages();
    }
  }, [cartItems]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      const clickedInsideModal = modalRef.current && modalRef.current.contains(event.target);
      const clickedSecureCheckout = secureCheckoutRef.current && secureCheckoutRef.current.contains(event.target);
      const isTrashIcon = event.target.closest('.fa-trash'); // Check if the click is on the trash icon

      if (isVisible && !clickedInsideModal && !clickedSecureCheckout && !isTrashIcon) {
        logEvent(analytics, 'cart_close', { action: 'Clicked outside to close cart' });
        onClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [isVisible, onClose]);

  useEffect(() => {
    if (cartItems.length === 4 && !showAlert) {
      setShowAlert(true);
    } else if (cartItems.length < 4) {
      setShowAlert(false);
    }
  }, [cartItems.length, showAlert]);

  const handleDecreaseQuantity = (item) => {
    console.log("Removing Item:", item);
    if (item && item.uniqueSessionTimeId) {
      dispatch(removeItemFromCart({ uniqueSessionTimeId: item.uniqueSessionTimeId }));
      logEvent(analytics, 'cart_item_removed', { course_name: item.courseName, item_id: item.uniqueSessionTimeId });
    } else {
      console.error("Failed to remove item: Invalid item or undefined property.");
    }
  };

  const subtotal = cartItems.reduce((acc, item) => acc + item.quantity * item.price, 0);

  const getFullDates = (obj) => {
    if (!obj) return ''; // Ensure obj is not undefined
    const dateObj = new Date(obj);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is 0-based
    const day = dateObj.getDate().toString().padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  return (
    <div
      className={`${
        isVisible ? 'right-0' : '-right-full'
      } fixed top-0 w-full sm:w-[450px] lg:w-[450px] h-full bg-white shadow-xl flex flex-col transition-all duration-500 z-[1001] sm:rounded-l-3xl`}>
      <div ref={modalRef} className="flex flex-col justify-between h-full p-6 cart-content">
        {/* Custom Alert for Maximum Cart Items */}
        {showAlert && (
          <div className="flex items-center bg-blue-50 border-l-4 border-blue-500 text-webDarker p-4 mb-4 rounded-md">
            <div className="flex-1">
              
              <p className="font-semibold"><FontAwesomeIcon size='sm' icon={faExclamationTriangle} className='mr-1'/>Warning</p>
              <p className='text-sm'>A maximum of 4 items in your cart has been reached.</p>
            </div>
          </div>
        )}

        <div className="flex-1 overflow-y-auto">
          <div className="flex items-center justify-between mb-5">
            <h2 className="text-lg font-bold">Your Cart</h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} className="text-xl cursor-pointer" onClick={onClose} />
          </div>

          {cartItems.length === 0 ? (
            <div className="text-center py-20 text-gray-700">
              <FontAwesomeIcon icon={faShoppingCart} size="3x" />
              <p className="mt-5 text-lg">Oops, it's empty!</p>
            </div>
          ) : (
            cartItems.map((item) => (
              <div key={item.uniqueSessionTimeId} className="border-b border-gray-300 py-4 mb-4 bg-white shadow-sm transform transition-transform duration-300 rounded-lg">
                <div className="flex justify-between items-start">
                  <div className="w-16 h-16 mr-4">
                    {courseImgs[item.courseId] ? (
                      <img src={courseImgs[item.courseId]} alt={item.courseName} className="w-full h-full object-cover rounded-md" />
                    ) : (
                      <div className="w-full h-full bg-gray-200 rounded-md animate-pulse" />
                    )}
                  </div>
                  <div className="flex-1">
                    <h3 className="text-sm font-medium text-gray-700">{item.courseName}</h3>
                    <p className="text-sm text-gray-600 mt-1">{getFullDates(item.session.startDate)} | {item.timeChosen}</p>
                    <p className="text-xs text-gray-400 mt-1">All times are based in EST</p>
                  </div>
                  <div className="flex items-center justify-between space-x-2">
                    <span className="text-sm font-semibold text-gray-900">${item.price}</span>
                    <FontAwesomeIcon icon={faTrash} className="text-gray-400 cursor-pointer ml-4 hover:text-red-500 transition-all duration-200" onClick={() => handleDecreaseQuantity(item)} />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

        <div className="flex flex-col mt-5">
          <div className="border-t pt-4 border-gray-300 mb-4 text-lg font-semibold flex justify-between">
            <span>Subtotal</span>
            <span>${subtotal.toFixed(2)}</span>
          </div>
          <button
            ref={secureCheckoutRef}
            onClick={(e) => {
              e.stopPropagation();
              onCheckout();
              onClose();
            }}
            className="bg-blue-500 text-white py-3 px-6 rounded-lg shadow-md transition-all duration-300 hover:bg-blue-600 hover:shadow-lg uppercase font-medium tracking-wider flex items-center justify-center">
            <FontAwesomeIcon icon={faLock} className="mr-2" /> Secure Checkout
          </button>
        </div>
      </div>
    </div>
  );
}

export default CartModal;
