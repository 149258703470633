import React from 'react';

const Tabs = ({ tabs, activeTabValue, handleTabClick }) => {
  const handleTabChange = (index) => {
    if (handleTabClick && typeof handleTabClick === 'function') {
      handleTabClick(tabs[index].value);
    }
  };

  // Find the index of the active tab based on the activeTabValue
  const activeIndex = tabs.findIndex((tab) => tab.value === activeTabValue);

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="w-full sm:w-7/12 mx-auto">
        <div
          role="tablist"
          aria-label="tabs"
          className="relative w-full h-auto sm:h-12 grid grid-cols-2 items-center px-1 sm:px-1.5 rounded-full bg-gray-200 overflow-hidden shadow-md"
          style={{
            gridTemplateColumns: `repeat(${tabs.length}, 1fr)`,
          }}
        >
          <div
            className="absolute indicator top-0 bottom-0 left-0 rounded-full bg-white shadow transition-transform duration-300 ease-in-out"
            style={{
              width: `${100 / tabs.length}%`,
              transform: `translateX(${activeIndex * 100}%)`,
            }}
          ></div>

          {tabs.map((tab, index) => {
            const isActive = tab.value === activeTabValue;
            return (
              <button
                key={index}
                role="tab"
                aria-selected={isActive}
                aria-controls={`panel-${index}`}
                id={`tab-${index}`}
                tabIndex={isActive ? 0 : -1}
                onClick={() => handleTabChange(index)}
                className={`relative block h-full p-2 sm:px-4 text-base sm:text-sm rounded-full transition-colors duration-300 ${
                  isActive ? 'text-webDarker' : 'text-gray-800'
                }`}
                style={{ lineHeight: '1.5' }}
              >
                <span>{tab.name}</span>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Tabs;
