import React, { useState, useEffect } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../api/firebaseConfig';

const imageCache = {}; 

const CourseImage = ({ imagePath, imageWidth, imageHeight, rounded }) => {
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    let isMounted = true;
    if (imagePath) {
      if (imageCache[imagePath]) {
        setImageURL(imageCache[imagePath]);
      } else {
        const fetchImageURL = async () => {
          try {
            const storageRef = ref(storage, imagePath);
            const url = await getDownloadURL(storageRef);
            imageCache[imagePath] = url;
            if (isMounted) {
              setImageURL(url);
            }
          } catch (error) {
            console.error('Error fetching image URL:', error);
          }
        };

        fetchImageURL();
      }
    }

    return () => {
      isMounted = false;
    };
  }, [imagePath]);

  return (
    <>
      {imageURL ? (
        <img
          src={imageURL}
          alt="default"
          className={`${imageWidth || 'w-24'} ${imageHeight || 'h-24'} object-cover ${rounded || 'rounded-md'} shadow-3d`}
        />
      ) : (
        <div className="w-24 h-24 bg-gray-200 rounded-md animate-pulse" />
      )}
    </>
  );
};

export default CourseImage;
