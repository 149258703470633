import { format } from 'date-fns';
import moment from 'moment-timezone';

export const formatDateRange = (startDate, endDate) => {
    const start = format(new Date(startDate), 'MMMM d');
    const end = format(new Date(endDate), 'MMMM d');
    return `${start} - ${end}`;
};

export const formatTimeSlot = (time) => {
    return `${time.startTime} - ${time.endTime}`;
};
export const convertToReadableDate = (timestamp) => {
  if (timestamp && timestamp.seconds) {
      return new Date(timestamp.seconds * 1000).toLocaleDateString();
  }
  return timestamp;
};
export const convertSessionTime = (time, selectedTimeZone) => {
  try {
    // console.log("Received time (in EST):", time);

    const currentDate = moment().format('YYYY-MM-DD');
    const dateTimeString = `${currentDate} ${time}`;
    const dateTimeMoment = moment.tz(dateTimeString, 'YYYY-MM-DD h:mm A', 'America/New_York');
    const convertedTime = dateTimeMoment.tz(selectedTimeZone);

    // Format and return the converted time in "h:mm A" format (12-hour notation)
    return convertedTime.format('h:mm A');
  } catch (error) {
    console.error('Error converting time:', error);
    return time;
  }
};

export const normalizeDate = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export function setTimeOnDate(dateObject, timeString) {
  const [time, period] = timeString.split(' ');
  let [hours, minutes] = time.split(':');

  hours = parseInt(hours, 10);

  // Convert 12-hour clock to 24-hour clock
  if (period === 'PM' && hours !== 12) {
    hours += 12;
  } else if (period === 'AM' && hours === 12) {
    hours = 0;
  }

  dateObject.setHours(hours);
  dateObject.setMinutes(minutes);

  return dateObject;
}

