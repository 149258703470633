import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addCustomerResponseWithNames } from '../../redux/slices/customerResponsesSlice';
import ctaImg from '../../assets/img/Archive/landingPage/landing_page_contactus.png'
function ContactSection() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: ''
  });

  const status = useSelector((state) => state.customerResponses.status);
  const error = useSelector((state) => state.customerResponses.error);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { firstName, lastName, email, message } = formData;

    try {
      await dispatch(addCustomerResponseWithNames({ firstName, lastName, email, message })).unwrap();
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        message: ''
      });
      alert('Your message has been sent successfully!');
    } catch (error) {
      console.error('Failed to send message: ', error);
      alert('There was an error sending your message. Please try again.');
    }
  };

  return (
    <section className="flex flex-col md:flex-row items-center justify-between p-8 md:p-16 bg-white">
      <div className="md:w-1/2 text-center md:text-left">

      <img src={ctaImg} alt="Contact Us"></img>
      </div>
      <div id='contactUs' className="w-full md:w-1/2 bg-transparent p-8">
        <h2 className="text-4xl font-medium mb-6 text-center">Contact Us</h2>
        <p className="text-textGray mb-8 mr-8 text-center">
          As a client-first team, we’re here to help you make informed decisions with personalized support.
        </p>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <input
              type="text"
              placeholder="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
              className="p-4 border-none bg-[#e2e9f4] rounded-lg h-13 focus:outline-none focus:border-blue-500"
            />
            <input
              type="text"
              placeholder="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
              className="p-4 border-none bg-[#e2e9f4] rounded-lg h-13 focus:outline-none focus:border-blue-500"
            />
          </div>
          <div>
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full p-4 bg-[#e2e9f4] rounded-lg h-13 focus:outline-none focus:border-blue-500"
            />
          </div>
          <div>
            <textarea
              placeholder="Your Message"
              name="message"
              rows="4"
              value={formData.message}
              onChange={handleChange}
              required
              className="w-full p-4 bg-[#e2e9f4] rounded-lg h-13 focus:outline-none focus:border-blue-500"
            ></textarea>
          </div>
          <div className='flex justify-start'>
            <button
              type="submit"
              className="w-full md:w-auto px-4 py-2 bg-white text-center text-webDarker border border-webDarker text-base rounded-full hover:bg-hoverColor hover:text-white focus:outline-none transition duration-200"
            >
              Send the message
            </button>
          </div>
        </form>
        {status === 'loading' && <p className="mt-4 text-blue-500">Sending your message...</p>}
        {status === 'failed' && <p className="mt-4 text-red-500">Error: {error}</p>}
      </div>
    </section>
  );
}

export default ContactSection;