import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loginUser, clearErrors, registerUser, resetUserPassword } from '../../redux/slices/userSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faEnvelope, faLock, faUserAlt, faChild, faPhone, faGenderless, faCode, faSpinner } from '@fortawesome/free-solid-svg-icons';
import MobileLayout from './MobileLayout';
import useWindowSize from '../Portal/useWindowSize';
import { ToastContainer, toast } from 'react-toastify';


function LoginPage() {
  const size = useWindowSize();

  const [isSignUp, setIsSignUp] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [name, setName] = useState('');
  const [emailSignup, setEmailSignup] = useState('');
  const [passwordSignup, setPasswordSignup] = useState('');
  const [age, setAge] = useState('');
  const [programmingFluency, setProgrammingFluency] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [gender, setGender] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mobileSignupLoading, setMobileSignupLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/portal';
  const isMobile = size.width <= 1024;

  const toggleAuthMode = () => {
    setIsSignUp((prev) => !prev);
    setIsForgotPassword((prev) => !prev);
  };

  const toggleForgotPasswordMode = () => {
    setIsForgotPassword((prev) => !prev);
  };

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(loginUser({ email, password })).unwrap();
      navigate(from, { replace: true });
    } catch (error) {
      console.log(error.code);
      if (error.code === 'auth/invalid-credential') {
        toast.error('Wrong email or password.');
      } else {
        toast.error('Failed to log in. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitSignup = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await dispatch(registerUser({
        name,
        emailSignup,
        passwordSignup,
        age,
        programmingFluency,
        phoneNumber,
        gender
      })).unwrap();
      console.log(result);
      toggleAuthMode();
      return true;
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        toast.error('Email is already in use.');
      } else {
        toast.error('Failed to register. Please try again or change your email.');
      }
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const result = await dispatch(resetUserPassword(email)).unwrap();
      console.log("Result:", result);
      toast.success('Password reset email sent!');
    } catch (error) {
      console.log("Reset error:", error);
      if (error === 'auth/invalid-email') {
        toast.error('Invalid email format. Please enter a valid email.');
      } else if (error === 'auth/user-not-found') {
        toast.error('No account found with this email.');
      } else if (error === 'auth/too-many-requests') {
        toast.error('Too many requests. Please try again later.');
      } else {
        toast.error('Failed to send password reset email. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };
  

  
  

  return (
    isMobile ? (
      <MobileLayout
        loading={loading}
        setLoading={setLoading}
        isSignUp={isSignUp}
        isForgotPassword={isForgotPassword}
        toggleAuthMode={toggleAuthMode}
        toggleForgotPasswordMode={toggleForgotPasswordMode}
        email={email}
        setEmail={setEmail}
        password={password}
        setPassword={setPassword}
        emailSignup={emailSignup}
        setEmailSignup={setEmailSignup}            
        passwordSignup={passwordSignup}
        setPasswordSignup={setPasswordSignup}
        name={name}
        setName={setName}
        age={age}
        setAge={setAge}    
        programmingFluency={programmingFluency}
        setProgrammingFluency={setProgrammingFluency}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        gender={gender}
        setGender={setGender}
        handleSubmitLogin={handleSubmitLogin}
        handleSubmitSignup={handleSubmitSignup}
        handlePasswordReset={handlePasswordReset}
        showPassword={showPassword}
        togglePasswordVisibility={togglePasswordVisibility}
        ToastContainer={ToastContainer}
        mobileSignupLoading={mobileSignupLoading}
        setMobileSignupLoading={setMobileSignupLoading}
      />

    ) : (
      <main className="flex flex-col min-h-screen items-center justify-center w-full flex-1 px-20 text-center">
        <div className="relative w-2/3 max-w-4xl flex overflow-hidden rounded-2xl shadow-2xl bg-webDarker animate-blurIn">
          <div className={`absolute inset-0 w-1/2 bg-white text-white transition-transform duration-700 ease-in-out transform ${isSignUp ? 'translate-x-full' : 'translate-x-0'}`} />
          <div className="relative w-1/2 p-5 flex flex-col justify-center items-center">
            <div className="text-left font-bold">
              <span className={isSignUp ? 'text-white animate-slideInLeft' : 'text-webDarker'}>Albuddy</span>
            </div>
            <div className="py-10">
              <h2 className={`text-3xl font-medium mb-2 ${isSignUp ? 'text-white animate-blurIn' : 'text-webDarker'}`}>
                {isSignUp ? 'Welcome Back' : isForgotPassword ? 'Reset Your Password' : 'Sign in to your account'}
              </h2>
              <div className={`border-2 w-10 inline-block mb-2 ${isSignUp ? 'border-white animate-blurIn' : 'border-webDarker animate-slideInLeft'}`}></div>

              {!isSignUp && !isForgotPassword && (
                <form onSubmit={handleSubmitLogin} className="flex flex-col items-center">
                  <div className="bg-gray-100 w-64 p-2 flex items-center mb-3 animate-slideInLeft">
                    <FontAwesomeIcon icon={faEnvelope} className="flex items-start text-textGray m-2" />
                    <input type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" className="bg-gray-100 outline-none text-sm flex-1" required />
                  </div>
                  <div className="bg-gray-100 w-64 p-2 flex items-center mb-3 animate-slideInLeft">
                    <FontAwesomeIcon icon={faLock} className="flex items-start text-textGray m-2" />
                    <input type={showPassword ? 'text' : 'password'} name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" className="bg-gray-100 outline-none text-sm flex-1" required />
                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="flex items-start text-textGray m-2" onClick={togglePasswordVisibility} />
                  </div>
                  <button type="submit" className="border-2 text-webDarker border-webDarker rounded-full px-12 py-2 inline-block font-semibold animate-slideInLeft hover:bg-webDarker hover:text-white">
                    {loading ? <FontAwesomeIcon icon={faSpinner} spin className="mr-2" /> : 'Sign in'}
                  </button>
                  <button
                    type="button"
                    onClick={toggleForgotPasswordMode}
                    className="mt-4 text-blue-500 hover:text-blue-700"
                  >
                    Forgot Password?
                  </button>
                </form>
              )}

              {isSignUp && (
                <>
                  <p className="text-white my-3 mb-8 animate-blurIn">To keep connected with us please login with your personal info</p>
                  <button onClick={toggleAuthMode} className="border-2 text-white border-white rounded-full px-12 py-2 inline-block font-semibold animate-blurIn hover:bg-white hover:text-webDarker">
                    Sign in
                  </button>
                </>
              )}

              {isForgotPassword &&!isSignUp&&(
                <form onSubmit={handlePasswordReset} className="flex flex-col items-center">
                  <div className="bg-gray-100 w-64 p-2 flex items-center mb-3 animate-slideInLeft">
                    <FontAwesomeIcon icon={faEnvelope} className="flex items-start text-textGray m-2" />
                    <input
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter your email"
                      className="bg-gray-100 outline-none text-sm flex-1"
                      required
                    />
                  </div>
                  <button type="submit" className="border-2 text-webDarker border-webDarker rounded-full px-12 py-2 inline-block font-semibold animate-slideInLeft hover:bg-webDarker hover:text-white">
                    {loading ? <FontAwesomeIcon icon={faSpinner} spin className="mr-2" /> : 'Send Reset Link'}
                  </button>
                  <button
                    type="button"
                    onClick={toggleForgotPasswordMode}
                    className="mt-4 text-blue-500 hover:text-blue-700"
                  >
                    Back to Login
                  </button>
                </form>
              )}
            </div>
          </div>

          {/* Sign Up Panel */}
          <div className="relative w-1/2 p-5 flex flex-col justify-center items-center">
            <h2 className={`text-3xl font-medium mb-2 ${isSignUp ? 'text-webDarker' : 'text-white  animate-blurIn'}`}>
              {isSignUp ? 'Create your account' : 'Hello, Buddy!'}
            </h2>
            <div className={`border-2 w-10 inline-block mb-2 ${isSignUp ? 'border-webDarker animate-slideInRight' : 'border-white animate-blurIn'}`}></div>

            {isSignUp && (
              <form onSubmit={handleSubmitSignup} className="flex flex-col items-center">
                <div className="bg-gray-100 w-64 p-2 flex items-center mb-3 animate-slideInRight">
                  <FontAwesomeIcon icon={faUserAlt} className="flex items-start text-textGray m-2" />
                  <input type="text" name="name" placeholder="Name" value={name} onChange={(e)=>setName(e.target.value)} className="bg-gray-100 outline-none text-sm flex-1 px-1" required />
                </div>
                <div className="bg-gray-100 w-64 p-2 flex items-center mb-3 animate-slideInRight">
                  <FontAwesomeIcon icon={faEnvelope} className="flex items-start text-textGray m-2" />
                  <input type="email" name="emailSignup" value={emailSignup} onChange={(e)=>setEmailSignup(e.target.value)} placeholder="Email" className="bg-gray-100 outline-none text-sm flex-1 px-1" required />
                </div>
                <div className="bg-gray-100 w-64 p-2 flex items-center mb-3 animate-slideInRight">
                  <FontAwesomeIcon icon={faLock} className="flex items-start text-textGray m-2" />
                  <input type={showPassword ? 'text' : 'password'} name="passwordSignup" value={passwordSignup} onChange={(e)=>setPasswordSignup(e.target.value)} placeholder="Password" className="bg-gray-100 outline-none text-sm flex-1 px-1" required />
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="flex items-start text-textGray m-2" onClick={togglePasswordVisibility} />
                </div>
                <div className="bg-gray-100 w-64 p-2 flex items-center mb-3 animate-slideInRight">
                  <FontAwesomeIcon icon={faChild} className="flex items-start text-textGray m-2" />
                  <input type="text" name="age" value={age} onChange={(e)=>setAge(e.target.value)} placeholder="Age" className="bg-gray-100 outline-none text-sm flex-1 px-1" required />
                </div>
                <div className="bg-gray-100 w-64 p-2 flex items-center mb-3 animate-slideInRight">
                  <FontAwesomeIcon icon={faPhone} className="flex items-start text-textGray m-2" />
                  <input type="tel" name="phoneNumber" value={phoneNumber} onChange={(e)=>setPhoneNumber(e.target.value)} placeholder="Phone Number" className="bg-gray-100 outline-none text-sm flex-1 px-1" required />
                </div>
                <div className="bg-gray-100 w-64 p-2 flex items-center mb-3 animate-slideInRight">
                  <FontAwesomeIcon icon={faGenderless} className="flex items-start text-textGray m-2" />
                  <select name="gender" value={gender} onChange={(e)=>setGender(e.target.value)} placeholder="Gender" className="bg-gray-100 outline-none text-sm flex-1 px-1" required>
                    <option value="" disabled>gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="non-binary">Non-binary</option>
                    <option value="prefer-not-to-say">Prefer not to say</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <div className="bg-gray-100 w-64 p-2 flex items-center mb-3 animate-slideInRight">
                  <FontAwesomeIcon icon={faCode} className="flex items-start text-textGray m-2" />
                  <select name="programmingFluency" value={programmingFluency} onChange={(e)=>setProgrammingFluency(e.target.value)} placeholder="Programming Fluency" className="bg-gray-100 outline-none text-sm flex-1 px-1" required>
                    <option value="" disabled>Programming Level</option>
                    <option value="Beginner">Beginner</option>
                    <option value="Advance">Advance</option>
                    <option value="Expert">Expert</option>
                    <option value="prefer-not-to-say">Prefer not to say</option>
                    <option value="other">Other</option>
                  </select>
                </div>
                <button type="submit" className="border-2 text-center text-webDarker border-webDarker rounded-full px-12 py-2 inline-block font-semibold hover:bg-webDarker hover:text-white">
                  {loading ? <FontAwesomeIcon icon={faSpinner} spin className="mr-2" /> : 'Sign up'}
                </button>
              </form>
            )}

            {!isSignUp && (
              <>
                <p className="my-3 mb-8 text-white animate-blurIn">Fill up personal information and start the journey with us</p>
                <button onClick={toggleAuthMode} className="border-2 text-white border-white rounded-full px-12 py-2 inline-block font-semibold animate-blurIn hover:bg-white hover:text-webDarker">
                  Sign up
                </button>
              </>
            )}
          </div>
        </div>
        <ToastContainer />
      </main>
    )
  );
}

export default LoginPage;
