import axios from "axios";
const GOOGLE_MAPS_API_KEY = 'AIzaSyDTUqp4r8wNPPOv2igLfofpPrsgMLYHPIo';
/**
 * Helper function to fetch location name using Google Maps Geocoding API.
 * Returns a string in the format "City, Country".
 * @param {number} latitude - The latitude of the location.
 * @param {number} longitude - The longitude of the location.
 * @returns {Promise<string>} A promise that resolves to the location name (e.g., "City, Country").
 */
export const getLocationName = async (latitude, longitude) => {
  if (!latitude || !longitude) {
    return 'Unknown Location';
  }

  const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`;

  try {
    const response = await axios.get(geocodeUrl);

    if (response.data.status === 'OK' && response.data.results.length > 0) {

      const addressComponents = response.data.results[0].address_components;

      let city = '';
      let country = '';

      addressComponents.forEach(component => {
        if (component.types.includes('locality')) {
          city = component.long_name;
        } else if (component.types.includes('country')) {
          country = component.long_name;
        }
      });

      if (!city) city = 'Unknown City';
      if (!country) country = 'Unknown Country';

      return `${city}, ${country}`;
    } else {
      console.error('Error with Google Maps Geocoding API:', response.data.status);
      return 'Unknown Location';
    }
  } catch (error) {
    console.error('Error fetching location name:', error);
    return 'Unknown Location';
  }
};
