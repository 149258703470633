import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import CustomSelect from '../../utilities/customSelect';
import {createRescheduleRequest} from '../../redux/slices/RescheduleRequestSlice';
import CustomButton from '../../utilities/UIcomponents/buttons/AnimatedButton';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RescheduleModal = ({ onClose, course }) => {
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.courses.courses);
  const user = useSelector((state) => state.user.user);
  const [selectedSession, setSelectedSession] = useState(null);
  const [availableSessions, setAvailableSessions] = useState([]);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [selectedTime, setSelectedTime] = useState('');
  const [updatedCourse, setUpdatedCourse] = useState({ ...course }); // Track updated course details
  console.log("userobj:", user);
  const formatTimeSlot = (timeSlot) => {
    return timeSlot.replace(/[^0-9APM]/g, '');
  };

  useEffect(() => {
    const foundCourse = courses.find((c) => c.id === course.courseId);
    if (foundCourse && foundCourse.sessions) {
      setAvailableSessions(foundCourse.sessions);
      const initialSession =
        foundCourse.sessions.find((session) => session.sessionId === course.sessionId) ||
        foundCourse.sessions[0];
      setSelectedSession(initialSession);
      setUpdatedCourse({ ...course, sessionId: initialSession?.sessionId });
      if (initialSession) {
        const timesArray = Object.values(initialSession.times);
        
        // Add filtering logic here to exclude already registered times
        const filteredTimes = timesArray.filter((time) => {
          const registeredTimeKey = `${course.courseId}_${initialSession.sessionId}_${formatTimeSlot(time.startTime + time.endTime)}`;
          return time.isAvailable && !user.registeredCourses[registeredTimeKey];
        });
        
        setAvailableTimes(filteredTimes);
      }
    }
  }, [course, courses, user.registeredCourses]);

  const handleSessionChange = (sessionId) => {
    const session = availableSessions.find((s) => s.sessionId === sessionId);
    setSelectedSession(session);
    setUpdatedCourse((prev) => ({ ...prev, sessionId: sessionId }));
  
    if (session) {
      const timesArray = Object.values(session.times);
  
      // Modify the filtering logic to exclude registered time slots
      const filteredTimes = timesArray.filter((time) => {
        // Check if the user has already registered for this time slot and session
        const registeredTimeKey = `${course.courseId}_${session.sessionId}_${formatTimeSlot(time.startTime+time.endTime)}`;
        console.log("checking registeredTimeKey:", registeredTimeKey);
        console.log("checking user.registeredCourses:", user.registeredCourses);
        return time.isAvailable && !user.registeredCourses[registeredTimeKey];
      });
  
      setAvailableTimes(filteredTimes);
    }
    setSelectedTime('');
  };
  

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  // Check if the user has already rescheduled more than 2 times for this course and session
  const hasExceededRescheduleLimit = () => {
    const rescheduleRecords = user.rescheduleRecords || [];
    const rescheduleCount = rescheduleRecords.filter(
      (record) =>
        record.courseId === course.courseId && record.newSessionId === updatedCourse.sessionId
    ).length;
    return rescheduleCount >= 1;
  };

  // Handle confirm button click
  const handleConfirm = async () => {
    if (!selectedSession || !selectedTime) {
      toast.error("Please select a session and a new time.");
      return;
    }

    if (hasExceededRescheduleLimit()) {
      toast.error("You have reached the maximum number of reschedules.");
      return;
    }

    // Create deep copies of registeredCourses and orderHistory to modify
    const updatedRegisteredCourses = JSON.parse(JSON.stringify(user.registeredCourses)); // Deep copy using JSON methods
    const updatedOrderHistory = JSON.parse(JSON.stringify(user.orderHistory)); // Deep copy using JSON methods

    // Original and new course keys
    const originalCourseKey = `${course.courseId}_${course.sessionId}_${formatTimeSlot(course.timeSlot)}`;
    const newCourseKey = `${course.courseId}_${updatedCourse.sessionId}_${formatTimeSlot(selectedTime)}`;

    // Get the old course entry
    const oldCourseEntry = updatedRegisteredCourses[originalCourseKey];
    if (!oldCourseEntry) {
      toast.error("Original course entry not found.");
      return;
    }

    const oldTimeSlot = oldCourseEntry.timeSlot;

    // Remove the old entry
    delete updatedRegisteredCourses[originalCourseKey];

    // Create the updated course entry with new sessionId and timeSlot
    const updatedCourseEntry = {
      ...oldCourseEntry,
      sessionId: updatedCourse.sessionId,
      timeSlot: selectedTime,
    };

    // Add the updated entry with the new key
    updatedRegisteredCourses[newCourseKey] = updatedCourseEntry;

    // Update the timeSlot and sessionId in orderHistory
    // Object.keys(updatedOrderHistory).forEach((orderId) => {
    //   const order = updatedOrderHistory[orderId];
    //   order.courses.forEach((c) => {
    //     if (c.courseId === course.courseId && c.sessionId === course.sessionId) {
    //       c.sessionId = updatedCourse.sessionId;
    //       c.timeSlot = selectedTime;
    //     }
    //   });
    // });

    // Create a reschedule record
    const rescheduleRecord = {
      courseId: course.courseId,
      courseName: course.title,
      oldSessionId: course.sessionId,
      newSessionId: updatedCourse.sessionId,
      oldTimeSlot,
      newTimeSlot: selectedTime,
      date: new Date().toISOString(),
    };

    // Update user data
    const updatedUser = {
      ...user,
      registeredCourses: updatedRegisteredCourses,
      orderHistory: updatedOrderHistory,
      rescheduleRecords: [...(user.rescheduleRecords || []), rescheduleRecord],
    };
    // in admin, update the order history manually or not do anything
    const reScheduleReuqestInfo ={
      userId: user.uid,
      requestedCourseId: course.courseId,
      pendingUserDetailsUpdate: updatedUser,
      rescheduleRecord: rescheduleRecord,
      status: 'pending',
      dateRequested: new Date().toISOString(),
    }

    try {
      // const result = await dispatch(updateUserDetails(updatedUser)).unwrap();
      const result = await dispatch(createRescheduleRequest(reScheduleReuqestInfo)).unwrap();
      if (result) {
        toast.success("Requested successfully! Please refresh.");
        onClose();
      }
    } catch (error) {
      console.error('Failed to submit reschedule request:', error);
      const errorMessage = error.message || "An error occurred while rescheduling. Please try again.";
      toast.error(errorMessage);
    }
  };

  const sessionOptions = availableSessions.map((session) => ({
    value: session.sessionId,
    label: `${new Date(session.startDate).toLocaleDateString()} | ${new Date(
      session.startDate
    ).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - 
    ${new Date(session.endDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`,
  }));
  console.log("sessionOptions", sessionOptions);
  

  const timeOptions = availableTimes.map((time) => ({
    value: `${time.startTime} - ${time.endTime}`,
    label: `${time.startTime} - ${time.endTime}`,
  }));

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      {/* Modal container */}
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full relative overflow-y-auto max-h-screen">
        {/* Close button */}
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>

        {/* Modal title */}
        <h2 className="text-2xl font-bold text-center mb-4">Reschedule your class</h2>
        <p className="text-sm text-gray-500 text-center mb-6">
          Please be noted that your tutor may not confirm your reschedule request.
        </p>

        {/* Custom Select for Sessions */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Select the session</label>
          <CustomSelect
            options={sessionOptions}
            value={selectedSession ? selectedSession.sessionId : ''}
            onChange={handleSessionChange}
          />
        </div>

        {/* Session Date - Automatically filled based on session */}
        <div className="mb-4">
          <label className="block text-gray-700 mb-2">Session Date</label>
          <input
            type="text"
            value={
              selectedSession
                ? `${new Date(selectedSession.startDate).toLocaleDateString()} - ${new Date(
                    selectedSession.endDate
                  ).toLocaleDateString()}`
                : ''
            }
            readOnly
            className="w-full p-2 border border-gray-300 rounded-lg bg-gray-100 cursor-not-allowed"
          />
        </div>

        {/* Custom Select for Times */}
        <div className="mb-6">
          <label className="block text-gray-700 mb-2">New Time</label>
          <CustomSelect
            options={timeOptions}
            value={selectedTime}
            onChange={handleTimeChange}
            disabled={!selectedSession}
          />
        </div>

        {/* Confirm Button */}
        <div className='flex justify-center'>
          <CustomButton
            onClick={handleConfirm}
            icon={faCheck}
            bgColor="bg-[#7aa2e338]"
            hoverBgColor="hover:bg-webDarker"
            textColor="text-webDarker"
            hoverTextColor="hover:text-white"
            size="px-20 py-5 text-base"
                      >
              Confirm
          </CustomButton>

        </div>
        {/* <button
          className="w-full py-2 bg-blue-500 text-white font-normal rounded-lg hover:bg-blue-600 transition"
          onClick={handleConfirm}
          disabled={!selectedSession || !selectedTime} // Disable if no session or time is selected
        >
          Confirm
        </button> */}
      </div>
    </div>
  );
};

export default RescheduleModal;
