import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import TrackerBar from './TrackerBar';
import SampleSchedule from '../CourseDetails/DetailComponents/SampleSchedule';
import Instructor from '../InstructorCard/InstructorCard';

const CourseModal = ({ course, onClose }) => {
  const { reviews} = useSelector((state) => state.reviews);
  const [courseInModal, setCourseInModal] = useState(null);
  const [isScheduleVisible, setScheduleVisible] = useState(false);
  const [isInstructorVisible, setInstructorVisible] = useState(false);
  const courses = useSelector((state) => state.courses.courses);
    const convertTo24HourFormat = (time) => {
    time = time.trim();
    const timeRegex = /^(\d{1,2})(?::(\d{2}))?\s*(am|pm)?$/i;
    const match = time.match(timeRegex);
    if (!match) return null;

    let [hours, minutes, modifier] = [match[1], match[2], match[3]];
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes || '0', 10);

    if (modifier) {
      modifier = modifier.toLowerCase();
      if (modifier === 'pm' && hours !== 12) {
        hours += 12;
      } else if (modifier === 'am' && hours === 12) {
        hours = 0;
      }
    }

    return { hours, minutes };
  };

  const parseTimeSlot = (timeSlot) => {
    if (!timeSlot) return { startTime: null, endTime: null };
    const [start, end] = timeSlot.split(' - ');

    const startTime = convertTo24HourFormat(start);
    const endTime = convertTo24HourFormat(end);

    return { startTime, endTime };
  };

  const parseDate = (timestamp, timeObj) => {
    const date = timestamp.toDate(); // Convert Timestamp to Date
    if (timeObj) {
      date.setHours(timeObj.hours, timeObj.minutes, 0, 0);
    }
    return date;
  };

  const startDate = course.startDate; // Firebase Timestamp
  const endDate = course.endDate; // Firebase Timestamp
  const { startTime, endTime } = parseTimeSlot(course.timeSlot);
  const startDateObj = parseDate(startDate, startTime);
  const endDateObj = parseDate(endDate, endTime);
  const now = new Date();
  const isCourseExpired = endDateObj < now;

  useEffect(() => {
    if (courseInModal === null) {
      const foundCourse = courses.find((c) => c.id === course.courseId);
      setCourseInModal(foundCourse);
    }
  }, [course, courses, courseInModal]);

  if (!courseInModal) {
    return (
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
        <div className="bg-white p-8 rounded-lg shadow-md">
          <p>Loading course information...</p>
        </div>
      </div>
    );
  }

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ease-out"
      onClick={onClose}
    >
      <div
        className="relative bg-white w-11/12 max-w-3xl mx-auto p-8 rounded-lg shadow-lg overflow-y-auto max-h-[80vh] transform transition-transform duration-300 ease-out scale-95 hover:scale-100"
        onClick={(e) => e.stopPropagation()}
      >
        {isCourseExpired ? (
          <div className="flex flex-col items-center text-center animate-bounce-3">
            <FontAwesomeIcon icon={faExclamationTriangle} className="text-webDarker text-4xl mb-4" />
            <h2 className="text-xl font-medium text-textGray">Your course has expired</h2>
          </div>
        ) : (
          <>
            <h2 className="text-3xl font-medium text-text mb-6">{course.title}</h2>
            <p className="text-textGray text-lg mb-6">{course.description}</p>
            <div className="m-8">
              <TrackerBar startDate={startDateObj} endDate={endDateObj} sampleSchedule={course.sampleSchedule} />
            </div>

            {/* Toggleable Sections */}
            <div className="border-t border-gray-200 py-4 space-y-4">
              {/* Sample Schedule */}
              <div
                onClick={() => setScheduleVisible(!isScheduleVisible)}
                className={`flex justify-between items-center cursor-pointer p-4 rounded-lg text-webDarker backdrop-blur-md shadow-sm ${
                  isScheduleVisible ? 'bg-gray-100' : 'bg-white'
                } transition-all duration-300`}
              >
                <h3 className="text-lg font-medium">Sample Schedule</h3>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={`text-gray-500 transform transition-transform duration-300 ${
                    isScheduleVisible ? 'rotate-180' : 'rotate-0'
                  }`}
                />
              </div>
              {isScheduleVisible && (
                <div className="overflow-hidden transition-all duration-300 ease-out transform scale-y-0 origin-top animate-slideDown">
                  <SampleSchedule schedule={course.sampleSchedule} removeHeader={true} />
                </div>
              )}

              {/* Instructors */}
              <div
                onClick={() => setInstructorVisible(!isInstructorVisible)}
                className={`flex justify-between items-center cursor-pointer p-4 rounded-lg text-webDarker backdrop-blur-md shadow-sm ${
                  isInstructorVisible ? 'bg-gray-100' : 'bg-white'
                } transition-all duration-300`}
              >
                <h3 className="text-lg font-medium">Instructor</h3>
                <FontAwesomeIcon
                  icon={faChevronDown}
                  className={`text-gray-500 transform transition-transform duration-300 ${
                    isInstructorVisible ? 'rotate-180' : 'rotate-0'
                  }`}
                />
              </div>
              {isInstructorVisible && (
                <div className="overflow-hidden transition-all duration-300 ease-out transform scale-y-0 origin-top animate-slideDown">
                  {courseInModal.instructors?.length > 0 ? (
                    courseInModal.instructors.map((instructor, index) => (
                      <Instructor
                        key={index}
                        instructor={instructor}
                        reviews={reviews.filter((review) => review.instructorID === instructor.id)}
                        removeReviews={true}
                      />
                    ))
                  ) : (
                    <div className="p-4">No instructors available for this course.</div>
                  )}
                </div>
              )}
            </div>

            {/* Join Meeting Button */}
            <div className="sticky bottom-4 right-4 flex justify-end mt-6">
              <a
                href={course.meetingUrl}
                className="text-white bg-blue-600 hover:bg-blue-700 px-6 py-2 rounded-full text-lg shadow-md transition-all duration-300"
                target="_blank"
                rel="noopener noreferrer"
              >
                Join Meeting
              </a>
            </div>
          </>
        )}
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 text-2xl transition-colors duration-200"
        >
          &times;
        </button>
      </div>
    </div>
  );
};

export default CourseModal;