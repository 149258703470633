import React, { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';

const SearchBar = ({ initialValue = '', onSearchChange, placeholder, delay = 300 }) => {
  const [inputValue, setInputValue] = useState(initialValue);

  const debouncedChange = debounce(onSearchChange, delay);

  useEffect(() => {
    debouncedChange(inputValue);
    return () => debouncedChange.cancel();
  }, [inputValue, debouncedChange]);

  return (
    <input
      type="text"
      className="w-full py-2 px-4 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500 text-gray-600 placeholder-gray-400"
      value={inputValue}
      onChange={e => setInputValue(e.target.value)}
      placeholder={placeholder || 'Search Courses'}
    />
  );
};

export default SearchBar;
