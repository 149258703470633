import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../api/firebaseConfig';
import { useState, useEffect } from 'react';

// TODO: this thing is not working anymore, delete it gradually
const CourseImage = ({ imagePath }) => {
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    if (imagePath) {
      const fetchImageURL = async () => {
        try {
          const storageRef = ref(storage, imagePath);
          const url = await getDownloadURL(storageRef);
          setImageURL(url);
        } catch (error) {
          console.error("Error fetching image URL:", error);
        }
      };

      fetchImageURL();
    }
  }, [imagePath]);
  return imageURL ? imageURL : <p>Loading...</p>;
};

export default CourseImage;
