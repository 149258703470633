import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faUsers } from '@fortawesome/free-solid-svg-icons';
import CourseImage from '../../../utilities/getImageWithDiv'

const CourseInfoBar = ({ duration, styleFormat, instructors }) => {
    return (
        <div className="px-8">
            <div className="bg-gray-100 bg-opacity-95 p-4 shadow-custom-medium w-full sm:w-full lg:w-1/2 flex flex-col sm:flex-row justify-evenly items-center sm:items-start space-y-4 sm:space-y-0">
            <div className='flex flex-col sm:flex-row gap-4 p-2 items-center'>
                {/* Course Info */}
                <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-6 text-black text-sm md:text-base justify-between w-full sm:w-auto items-center sm:items-start">
                    <div className="flex w-full sm:w-auto justify-between items-center">
                        <FontAwesomeIcon icon={faClock} className="mr-2 sm:mr-4" />
                        <span className="text-right">{duration || 'N/A'}</span>
                    </div>
                    <div className="flex w-full sm:w-auto justify-between items-center">
                        <FontAwesomeIcon icon={faUsers} className="mr-2 sm:mr-4" />
                        <span className="text-right">{styleFormat || 'N/A'}</span>
                    </div>
                </div>

                {/* Instructors */}
                <div className="flex flex-wrap items-center justify-center space-x-4 mx-4 w-full sm:w-auto">
                    {instructors && instructors.length > 0 ? (
                        instructors.map((instructor, index) => (
                            <div key={index} className="flex w-full sm:w-auto justify-between items-center space-x-4 min-w-[100px]">
                                <CourseImage imagePath={instructor.imageUrl} imageWidth={'w-8'} imageHeight={'h-8'} rounded={'rounded-full'}/>
                                <div className="flex-1 text-right">
                                    <span className="text-gray-800 font-semibold text-sm md:text-base break-words text-center sm:text-left">
                                        {instructor.name}
                                    </span>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="text-center sm:text-left w-full">No instructors available</div>
                    )}
                </div>
            </div>

            </div>
        </div>
    );
};

export default CourseInfoBar;
