import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faSpinner } from '@fortawesome/free-solid-svg-icons';

const MobileLayout = ({
  email,
  setEmail,
  password,
  setPassword,
  emailSignup,
  setEmailSignup,
  passwordSignup,
  setPasswordSignup,
  name,
  setName,
  age,
  setAge,
  programmingFluency,
  setProgrammingFluency,
  phoneNumber,
  setPhoneNumber,
  gender,
  setGender,
  handleSubmitLogin,
  handleSubmitSignup,
  handlePasswordReset,
  showPassword,
  togglePasswordVisibility,
  loading,
  ToastContainer,
}) => {
  const [mode, setMode] = useState('signIn');

  const toggleMode = (newMode) => setMode(newMode);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (mode === 'signIn') {
      handleSubmitLogin(e);
    } else if (mode === 'signUp') {
      const signupSuccess = await handleSubmitSignup(e);
      if (signupSuccess) {
        setEmail(emailSignup);
        setPassword(passwordSignup);
        setMode('signIn');
      }
    } else if (mode === 'forgotPassword') {
      handlePasswordReset(e);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-sm">
        <div className="text-center mb-6">
          <span className="text-2xl font-bold">
            {mode === 'forgotPassword' ? 'Reset Password' : mode === 'signUp' ? 'Create your Account' : 'Login to your Account'}
          </span>
        </div>

        <form onSubmit={handleSubmit}>
          {/* Sign-Up Form Inputs */}
          {mode === 'signUp' && (
            <>
              <div className="mb-4">
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Full Name"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  placeholder="Age"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  required
                />
              </div>
              <div className="mb-4">
                <input
                  type="tel"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Phone Number"
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  required
                />
              </div>
              <div className="mb-4">
                <select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  required
                >
                  <option value="" disabled>Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="non-binary">Non-binary</option>
                  <option value="prefer-not-to-say">Prefer not to say</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div className="mb-4">
                <select
                  value={programmingFluency}
                  onChange={(e) => setProgrammingFluency(e.target.value)}
                  className="w-full px-4 py-2 border border-gray-300 rounded-md"
                  required
                >
                  <option value="" disabled>Select Programming Fluency</option>
                  <option value="Beginner">Beginner</option>
                  <option value="Advance">Advance</option>
                  <option value="Expert">Expert</option>
                  <option value="prefer-not-to-say">Prefer not to say</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </>
          )}

          {/* Email Input */}
          <div className="mb-4">
            <input
              type="email"
              value={mode === 'signUp' ? emailSignup : email}
              onChange={(e) => (mode === 'signUp' ? setEmailSignup(e.target.value) : setEmail(e.target.value))}
              placeholder="Email"
              className="w-full px-4 py-2 border border-gray-300 rounded-md"
              required
            />
          </div>

          {/* Password Input */}
          {mode !== 'forgotPassword' && (
            <div className="mb-4 flex items-center w-full relative">
              <input
                type={showPassword ? 'text' : 'password'}
                value={mode === 'signUp' ? passwordSignup : password}
                onChange={(e) => (mode === 'signUp' ? setPasswordSignup(e.target.value) : setPassword(e.target.value))}
                placeholder="Password"
                className="w-full px-4 py-2 border border-gray-300 rounded-md pr-10"
                required
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500 cursor-pointer"
                onClick={togglePasswordVisibility}
              />
            </div>
          )}

          {/* Submit Button */}
          <button
            type="submit"
            className="w-full py-2 px-4 bg-webDarker text-white rounded-md shadow-md hover:bg-hoverColor transition-colors"
          >
            {loading ? <FontAwesomeIcon icon={faSpinner} spin className="mr-2" /> : mode === 'forgotPassword' ? 'Send Reset Link' : mode === 'signUp' ? 'Sign up' : 'Sign in'}
          </button>
        </form>

        {/* Toggle Links */}
        <div className="mt-4 text-xs text-gray-500">
          {mode === 'signIn' && (
            <div>
              <button
                type="button"
                onClick={() => toggleMode('forgotPassword')}
                className="text-blue-500 hover:text-blue-700 mr-2"
              >
                Forgot Password?
              </button>
              <button
                type="button"
                onClick={() => toggleMode('signUp')}
                className="text-webDarker hover:underline"
              >
                Don't have an account? Sign up
              </button>
            </div>
          )}
          {mode === 'signUp' && (
            <button
              type="button"
              onClick={() => toggleMode('signIn')}
              className="text-webDarker hover:underline"
            >
              Already have an account? Sign in
            </button>
          )}
          {mode === 'forgotPassword' && (
            <button
              type="button"
              onClick={() => toggleMode('signIn')}
              className="text-blue-500 hover:text-blue-700"
            >
              Back to Login
            </button>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default MobileLayout;
