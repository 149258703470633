import React from 'react';

function ServicesSection() {
  return (
    <section className="services-section py-16 bg-white">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center gap-8 md:gap-16">
        {/* Block 1 */}
        <div className="flex flex-row items-start md:items-center text-left">
          <h1 className="text-5xl font-medium text-black">1K+</h1>
          <p className="mt-2 ml-4 text-base text-gray-600">Students trust Albuddy to help achieve academic goals</p>
        </div>

        {/* Block 2 */}
        <div className="flex flex-row items-start md:items-center text-left">
          <h1 className="text-5xl font-medium text-black">95%</h1>
          <p className="mt-2 ml-4 text-base text-gray-600">Student satisfaction rate with our tutoring</p>
        </div>

        {/* Block 3 */}
        <div className="flex flex-row items-start md:items-center text-left">
          <h1 className="text-5xl font-medium text-black">4.8</h1>
          <p className="mt-2 ml-4 text-base text-gray-600">Average student rating out of 5.00</p>
        </div>
      </div>
    </section>
  );
}

export default ServicesSection;
