// cartSlice.js
import { createSlice, nanoid } from '@reduxjs/toolkit';

const initialState = {
  items: [],
  totalCount: 0,
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItemToCart: (state, action) => {
      const newItem = action.payload;
      //added a check of max 4 because the metadata can only have 500 characters(TODO: destructure metadata)
      if (state.items.length >= 4) {
        return;
      }
      newItem.uniqueSessionTimeId = newItem.uniqueSessionTimeId || nanoid();
      newItem.quantity = 1;
      const existingItemIndex = state.items.findIndex(item => item.uniqueSessionTimeId === newItem.uniqueSessionTimeId);
      if (existingItemIndex >= 0) {
        //set it to = newItem.quanity to ensure it's always 1, change to+= if wanna increase
        state.items[existingItemIndex].quantity = newItem.quantity;
      } else {
        state.items.push({ ...newItem, quantity: newItem.quantity });
      }

      state.totalCount = state.items.reduce((total, item) => total + item.quantity, 0);
    },
    
    removeItemFromCart: (state, action) => {
      const { uniqueSessionTimeId } = action.payload;
      const existingItemIndex = state.items.findIndex(item => item.uniqueSessionTimeId === uniqueSessionTimeId);

      if (existingItemIndex >= 0) {
        const existingItem = state.items[existingItemIndex];
        if (existingItem.quantity > 1) {
          existingItem.quantity -= 1;
        } else {
          state.items.splice(existingItemIndex, 1);
        }
      }

      state.totalCount = state.items.reduce((total, item) => total + item.quantity, 0);
    },
    clearCart: (state) => {
      state.items = [];
      state.totalCount = 0;
    },
      
  },
});

export const { addItemToCart, removeItemFromCart, clearCart } = cartSlice.actions;

export default cartSlice.reducer;
