import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './slices/userSlice';
import blogReducer from './slices/blogSlice';
import courseReducer from './slices/courseSlice';
import cartReducer from './slices/cartSlice';
import applicationReducer  from './slices/ApplicationSlice';
import customerResponsesReducer from './slices/customerResponsesSlice';
import reviewsReducer from './slices/reviewsSlice';
import rescheduleSlice from './slices/RescheduleRequestSlice';

const coursesPersistConfig = {
  key: 'courses',
  storage,
  whitelist: ['courses', 'status', 'error'],
};
const userPersistConfig = {
  key: 'user',
  storage,
};
const cartPersistConfig = {
  key: 'cart',
  storage,
};

// depending on what you want to persist, add em here similar to coursePersistConfig

const rootReducer = combineReducers({
  user: persistReducer(userPersistConfig, userReducer),
  blog: blogReducer,
  courses: persistReducer(coursesPersistConfig, courseReducer),
  cart: persistReducer(cartPersistConfig, cartReducer),
  application: applicationReducer,
  customerResponses: customerResponsesReducer,
  reviews:reviewsReducer,
  reschedule: rescheduleSlice
});

export default rootReducer;
