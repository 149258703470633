

const blogFormatter = (content) => {
   return <div 
              className="
              prose 
              max-w-none 
              mt-6 
              prose-h1:text-3xl 
              prose-h1:font-medium 
              prose-h1:mb-0 
              prose-h1:mt-0
              prose-h2:text-2xl 
              prose-h2:font-medium 
              prose-h2:mb-0 
              prose-h2:mt-0
              prose-h3:text-2xl 
              prose-h3:font-medium 
              prose-h3:mb-0 
              prose-h3:mt-0
              prose-p:text-base 
              prose-p:leading-normal 
              prose-p:mb-0 
              prose-p:mt-0
              prose-a:text-webDarker 
              prose-a:underline 
              prose-img:rounded-lg 
              prose-img:shadow-lg 
              prose-blockquote:pl-4 
              prose-blockquote:border-l-4 
              prose-blockquote:border-gray-300 
              prose-blockquote:text-gray-700 
              prose-ul:list-disc 
              prose-ul:pl-5 
              prose-li:mb-0
              prose-li:mt-0
            "

              dangerouslySetInnerHTML={{ __html: content }} 
            />
};
const stripHtmlTags = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || '';
  };
export const blogFormatterTrimmed = (content, length = 115) => {
    // Step 1: Strip the HTML tags to get plain text
    const plainText = stripHtmlTags(content);
  
    // Step 2: Trim the text to the desired length
    const trimmedText = plainText.length > length ? plainText.slice(0, length) + '...' : plainText;
  
    // Step 3: Return the trimmed text along with original HTML structure
    return (
      <div
        className="
          text-sm
          text-textGray
          prose 
          max-w-none 
          mt-2
          mb-2 
          prose-h1:text-3xl 
          prose-h1:font-medium 
          prose-h1:mb-0 
          prose-h1:mt-0
          prose-h2:text-2xl 
          prose-h2:font-medium 
          prose-h2:mb-0 
          prose-h2:mt-0
          prose-h3:text-2xl 
          prose-h3:font-medium 
          prose-h3:mb-0 
          prose-h3:mt-0
          prose-p:text-base 
          prose-p:leading-normal 
          prose-p:mb-0 
          prose-p:mt-0
          prose-a:text-webDarker 
          prose-a:underline 
          prose-img:rounded-lg 
          prose-img:shadow-lg 
          prose-blockquote:pl-4 
          prose-blockquote:border-l-4 
          prose-blockquote:border-gray-300 
          prose-blockquote:text-gray-700 
          prose-ul:list-disc 
          prose-ul:pl-5 
          prose-li:mb-0
          prose-li:mt-0
        "
        dangerouslySetInnerHTML={{ __html: trimmedText }} // Display trimmed text with ellipsis
      />
    );
  };


export default blogFormatter;