// Navbar.jsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faCalendarAlt, faUsers, faChartLine } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  return (
    <div className="h-screen w-64 bg-webDarker text-white flex flex-col shadow-custom-deep">
      {/* Logo */}
      <div className="px-6 py-4 border-b border-neutral flex flex-col items-center">
        <h1 className="text-2xl font-Medium">Albuddy</h1>
        <h1 className="text-2xl font-normal">Admin Portal</h1>
      </div>

      {/* Navbar Items */}
      <div className="flex-1 overflow-y-auto">
        <ul className="flex flex-col mt-4 space-y-1">
          <li>
            <NavLink
              to="/adminportal/editcourses"
              className={({ isActive }) =>
                `flex items-center px-6 py-3 cursor-pointer transition ease-in-out duration-200 ${
                  isActive ? 'bg-hoverColor' : 'hover:bg-hoverColor'
                }`
              }
            >
              <FontAwesomeIcon icon={faBook} className="mr-3" />
              Edit Course(s)
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/adminportal/editspleschedules"
              className={({ isActive }) =>
                `flex items-center px-6 py-3 cursor-pointer transition ease-in-out duration-200 ${
                  isActive ? 'bg-hoverColor' : 'hover:bg-hoverColor'
                }`
              }
            >
              <FontAwesomeIcon icon={faCalendarAlt} className="mr-3" />
              Edit Sample Schedules
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/adminportal/refundrequests"
              className={({ isActive }) =>
                `flex items-center px-6 py-3 cursor-pointer transition ease-in-out duration-200 ${
                  isActive ? 'bg-hoverColor' : 'hover:bg-hoverColor'
                }`
              }
            >
              <FontAwesomeIcon icon={faUsers} className="mr-3" />
              Refund Requests
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/adminportal/reschedulerequests"
              className={({ isActive }) =>
                `flex items-center px-6 py-3 cursor-pointer transition ease-in-out duration-200 ${
                  isActive ? 'bg-hoverColor' : 'hover:bg-hoverColor'
                }`
              }
            >
              <FontAwesomeIcon icon={faChartLine} className="mr-3" />
              Reschedule Requests
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
