import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import ReviewForm from './ReviewForm'; 

const ReviewModal = ({ course, instructors, onClose, onSubmit }) => {
  const { submitStatus, submitError } = useSelector((state) => state.reviews);

  useEffect(() => {
    if (submitStatus === 'succeeded') {
      toast.success('Review submitted successfully!');
    } else if (submitStatus === 'failed') {
      toast.error(`Failed to submit review: ${submitError}`);
    }
  }, [submitStatus, submitError]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-300 ease-out">
      
      {/* Modal Content */}
      <div className="relative bg-white w-11/12 max-w-lg mx-auto p-8 rounded-lg shadow-lg overflow-y-auto h-auto min-h-[500px]">
        {/* Close button */}
        <button
          className="absolute top-3 right-3 text-gray-500 hover:text-gray-700 text-2xl transition-colors duration-200"
          onClick={onClose}
        >
          &times;
        </button>

        {/* Conditionally render the overlay for loading state */}
        {submitStatus === 'loading' && (
          <div className="absolute inset-0 z-50 flex items-center justify-center bg-white bg-opacity-80 rounded-lg">
            <div className="flex flex-col items-center">
              <FontAwesomeIcon icon={faSpinner} className="animate-spin text-blue-500 text-5xl mb-4" />
              <p className="text-blue-500 text-xl">Submitting your review...</p>
            </div>
          </div>
        )}

        {/* Render the review form when not in loading state */}
        {submitStatus !== 'loading' && (
          <ReviewForm courseTitle={course?.title} instructors={instructors} onSubmit={onSubmit} />
        )}
      </div>
    </div>
  );
};

export default ReviewModal;
