import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchRescheduleRequests } from '../../redux/slices/RescheduleRequestSlice.js';
import { faCalendar, faUserGroup, faCalendarAlt, faClock, faNoteSticky } from '@fortawesome/free-solid-svg-icons';
import CourseImage from '../../utilities/getImageWithDiv.jsx';
import TrackerBar from '../../components/CourseModal/TrackerBar.jsx';

const CourseTags = ({ categories }) => (
  <div className="absolute top-2 left-2 flex flex-wrap">
    {categories?.map((tag, index) => (
      <span
        key={index}
        className="bg-white/30 backdrop-blur-lg text-white rounded-md px-3 py-1 text-sm font-medium shadow-md mx-1"
      >
        {tag}
      </span>
    )) || <span>No categories available</span>}
  </div>
);

// Component for instructor list
const InstructorList = ({ instructors }) => (
  <div className="flex items-center mb-4">
    {instructors?.length > 0 ? (
      instructors.map((instructor, index) => (
        <div key={index} className="text-base font-semibold text-gray-700 mr-4 flex flex-row items-center gap-4">
          <CourseImage imagePath={instructor.imageUrl} imageWidth="w-12" imageHeight="h-12" rounded="rounded-full" />
          <div className="flex flex-col">
            {instructor.name}
            <span className="font-normal text-sm text-gray-500">{instructor.bio}</span>
          </div>
        </div>
      ))
    ) : (
      <p className="text-base font-semibold text-gray-700">Instructor information not available</p>
    )}
  </div>
);

const CourseCard = ({ course, onClick, onClickReschedule }) => {
  const dispatch = useDispatch();
  const [resReq, setResReq] = useState(null);
  const rescheduleReq = useSelector((state) => state.reschedule.requests);
  const courses = useSelector((state) => state.courses.courses);
  const user = useSelector((state) => state.user.user);

  // Find the full course details based on course ID
  const fullCourse = courses.find((c) => c.id === course?.courseId);

  // Fetch reschedule requests when the component mounts if no requests exist
  useEffect(() => {
    dispatch(fetchRescheduleRequests());
  }, [course, dispatch]);

  // Find the reschedule request related to this course and session
  useEffect(() => {
    const foundRescheduleReq = rescheduleReq.find(
      (req) =>
        req.rescheduleRecord.courseId === course?.courseId &&
        req.rescheduleRecord.oldSessionId === course?.sessionId &&
        req.rescheduleRecord.oldTimeSlot === course?.timeSlot &&
        req.userId === user?.uid
    );
    if (foundRescheduleReq) {
      setResReq(foundRescheduleReq);
    }
  }, [rescheduleReq, course, user?.uid]);

  // Convert Firestore timestamp to Date object
  const convertTimestampToDate = (timestamp) => {
    if (!timestamp) return 'N/A';
    return new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000).toLocaleDateString();
  };

  const startDate = convertTimestampToDate(course?.startDate);
  const endDate = convertTimestampToDate(course?.endDate);

  // Early return if fullCourse or course data is missing
  if (!fullCourse || !course) {
    return <div>Course data is loading or unavailable.</div>;
  }

  return (
    <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300 relative">
      {/* Left Side: Course Image and Tags */}
      <div className="relative md:w-1/3">
        <CourseImage imagePath={fullCourse.imageID} imageWidth="w-full" imageHeight="h-full" />
        <CourseTags categories={fullCourse.category} />
      </div>

      {/* Right Side: Course Details */}
      <div className="flex-1 p-6 flex flex-col justify-between relative">
        {/* Course Info */}
        <div className="flex items-center text-sm text-gray-600 mb-2">
          <p className="mr-4">
            <FontAwesomeIcon icon={faCalendar} className="mr-2 text-gray-600" /> {fullCourse.duration || 'N/A'}
          </p>
          <p>
            <FontAwesomeIcon icon={faUserGroup} className="mr-2 text-gray-600" /> {fullCourse.styleFormat || 'N/A'}
          </p>
        </div>

        {/* Course Title */}
        <h2 className="text-2xl font-semibold text-gray-800 mb-2">{fullCourse.title}</h2>

        {/* Separator Line */}
        <div className="border-b border-gray-300 mb-4"></div>

        {/* Instructors */}
        <InstructorList instructors={fullCourse.instructors} />

        {/* TrackerBar */}
        <div className="mb-8">
          <TrackerBar startDate={startDate} endDate={endDate} sampleSchedule={course.sampleSchedule} />
        </div>

        {/* Course Duration */}
        <div className="text-sm text-gray-600 mb-8">
          <div className="flex flex-col md:flex-row gap-2">
            <div className="flex items-center">
              <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
              <strong>Course Duration:</strong>
              <span className="ml-2">{startDate} - {endDate}</span>
            </div>
            <div className="flex items-center">
              <FontAwesomeIcon icon={faClock} className="mr-2" />
              <strong>Course Time:</strong>
              <span className="ml-2">{course.timeSlot}</span>
            </div>

            {/* Reschedule Request Section */}
            {resReq && (
              <div className="flex items-center">
                <FontAwesomeIcon icon={faNoteSticky} className="mr-2" />
                <strong>Reschedule Status:</strong>
                <span className={`ml-2 ${resReq.status === 'pending' ? 'animate-bounce' : ''}`}>{resReq.status}</span>
              </div>
            )}
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex justify-end mt-4 md:mt-0 md:absolute md:bottom-4 md:right-4 gap-4">
          <button
            className="text-sm text-primary border border-primary px-2 py-1 rounded-full hover:bg-primary hover:text-white transition"
            onClick={onClickReschedule}
          >
            Reschedule
          </button>
          <button
            onClick={onClick}
            className="text-sm bg-webDarker text-white border border-webDarker px-2 py-1 rounded-full hover:bg-white hover:text-webDarker transition"
          >
            View Course Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
