import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import whyAlbuddyImg from '../../assets/img/Archive/landingPage/landing_page_why_albuddy.png';

function WhyChooseUsSection() {
  return (
    <div className=" why us w-full grid grid-cols-1 md:grid-cols-2 gap-0"
    id='whyUs'>
      {/* Left Image Section */}
      <div className="flex justify-center">
        <img 
          src={whyAlbuddyImg} 
          alt="Albuddy tutoring session" 
          className="object-cover w-full h-full"
        />
      </div>

      {/* Right Content Section */}
      <div className="bg-blue-50 from-white to-[#e9f2fc] flex items-center py-16 px-8 md:px-16">
        <div className="space-y-8 max-w-lg mx-auto">
          <section className="text-left">
            <h2 className="font-medium text-3xl md:text-4xl text-gray-700 mb-4">
              Why should you choose us?
            </h2>
            <p className="text-textGray">
              Albuddy helps high school students succeed in advanced subjects and language learning through:
            </p>
          </section>

          {/* List of Benefits */}
          <section className="space-y-8">
            <div className="flex items-start">
              <FontAwesomeIcon icon={faCircleCheck} className="text-webDarker h-6 w-6 md:h-8 md:w-8" />
              <div className="ml-4">
                <h3 className="text-xl md:text-xl font-medium text-gray-700">Expert Tutors</h3>
                <p className="text-textGray text-sm md:text-base">
                  Learn from experienced instructors who simplify complex topics.
                </p>
              </div>
            </div>

            <div className="flex items-start">
              <FontAwesomeIcon icon={faCircleCheck} className="text-webDarker h-6 w-6 md:h-8 md:w-8" />
              <div className="ml-4">
                <h3 className="text-xl md:text-xl font-medium text-gray-700">Focused Language Classes</h3>
                <p className="text-textGray text-sm md:text-base">
                  Enhance fluency and comprehension with engaging lessons.
                </p>
              </div>
            </div>

            <div className="flex items-start">
              <FontAwesomeIcon icon={faCircleCheck} className="text-webDarker h-6 w-6 md:h-8 md:w-8" />
              <div className="ml-4">
                <h3 className="text-xl md:text-xl font-medium text-gray-700">Personalized Support</h3>
                <p className="text-textGray text-sm md:text-base">
                  Receive guidance tailored to your unique goals and needs.
                </p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default WhyChooseUsSection;
