import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearCart, removeItemFromCart } from '../../redux/slices/cartSlice';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import { createCheckoutSession } from '../../api/StripeService';
import { collection, getDocs } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { db, analytics } from '../../api/firebaseConfig';
import { logEvent } from 'firebase/analytics';
import Footer from '../../components/Footer';
import CourseImage from '../../utilities/getImageWithDiv';
const stripeKey = process.env.REACT_APP_STRIPE_TEST_PUBLISHABLE_KEY;
const stripePromise = loadStripe(stripeKey);

const Checkout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const userId = useSelector((state) => state.user.user?.uid);
  const [promoCode, setPromoCode] = useState('');
  const [promoMessage, setPromoMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [priceAfterPromo, setPriceAfterPromo] = useState(0);
  console.log("Cart items:", cartItems);
  const shippingCost = 0.0;
  const subtotal = cartItems.reduce((acc, item) => acc + item.quantity * item.price, 0);
  // const totalBeforeTax = subtotal + shippingCost;
  const total = subtotal + shippingCost;

  const handleCheckout = async () => {
    if (cartItems.length === 0) {
      alert("No courses in the cart");
      return;
    }
    if (!isLoggedIn) {
      setRedirecting(true);
      setLoading(true);

      setTimeout(() => {
        navigate('/login', { state: { from: '/checkout' } });
      }, 1000);

      return;
    }

    setLoading(true);

    try {
      const stripe = await stripePromise;
      const session = await createCheckoutSession(cartItems, userId, promoCode);
      logEvent(analytics, 'checkout_initiated', { user_id: userId, total: total, promo_code: promoCode });

      stripe.redirectToCheckout({ sessionId: session.id });
    } catch (error) {
      console.error(error.message);
      setLoading(false);
      if (error.message === "INVALID_PROMO_CODE") {
        setPromoMessage("Invalid promo code. Please try again.");
      } else {
        setPromoMessage("An error occurred during checkout. Please try again.");
      }
    }
  };

  useEffect(() => {
    logEvent(analytics, 'page_view', { page_title: 'Checkout Page' });
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get('success')) {
      console.log('Order placed! You will receive an email confirmation.');
      logEvent(analytics, 'checkout_success');
      dispatch(clearCart());
      navigate('/order-success');
    }

    if (query.get('canceled')) {
      console.log('Order canceled -- continue to shop around and checkout when you’re ready.');
      logEvent(analytics, 'checkout_canceled');
      navigate('/order-canceled');
    }
  }, [dispatch, navigate]);

  const handlePromoCheck = async () => {
    setLoading(true);
    setPromoMessage('');

    try {
      const promoCodesRef = collection(db, 'promoCode');
      const promoCodesSnapshot = await getDocs(promoCodesRef);
      const promoCodes = promoCodesSnapshot.docs.map(doc => doc.data());
      const promo = promoCodes.find(promo =>
        Object.values(promo).some(code => code.promoCode === promoCode)
      );

      if (promo) {
        const promoDetails = Object.values(promo).find(code => code.promoCode === promoCode);
        const promoType = promoDetails.type;
        const intPercentOff = promoDetails.discountedPercentage;
        const newPriceAfterPromo = subtotal * (1 - intPercentOff / 100);
        setDiscountPercentage(intPercentOff);
        setPriceAfterPromo(newPriceAfterPromo);
        setPromoMessage(`Successfully applied: ${promoType}`);
        logEvent(analytics, 'promo_applied', { promo_code: promoCode, discount: intPercentOff });
      } else {
        setPromoMessage('Invalid promo code. Please try again.');
        setDiscountPercentage(0);
        setPriceAfterPromo(subtotal);
        logEvent(analytics, 'promo_invalid', { promo_code: promoCode });
      }
    } catch (error) {
      console.error('Error checking promo code:', error);
      setPromoMessage('An error occurred. Please try again.');
      setDiscountPercentage(0);
      setPriceAfterPromo(subtotal);
      logEvent(analytics, 'promo_error', { promo_code: promoCode, error: error.message });
    } finally {
      setLoading(false);
    }
  };

  const handleDecreaseQuantity = (item) => {
    dispatch(removeItemFromCart({ uniqueSessionTimeId: item.uniqueSessionTimeId }));
    logEvent(analytics, 'cart_item_removed', { course_name: item.courseName, item_id: item.uniqueSessionTimeId });
  };

  const orderTotal = priceAfterPromo ? priceAfterPromo + shippingCost : subtotal + shippingCost;

  return (
    <>
    <div className="min-h-screen bg-white flex justify-center py-10">
      <div className="max-w-5xl w-full space-y-6 px-4">
        <div className="flex flex-col lg:flex-row lg:justify-between lg:items-start lg:space-x-4">
          {/* Order List Section */}
          <div className="lg:w-3/5 bg-white p-6 rounded-lg">
            <h2 className="text-4xl font-medium mb-4">Your Order List</h2>
            <div className="bg-white border border-gray-100 rounded-lg shadow-xl divide-y divide-gray-200">
              {cartItems.map((item, index) => (
                <div 
                  key={item.uniqueSessionTimeId} 
                  className="flex flex-col sm:flex-row items-start sm:items-center p-4 sm:space-x-4 overflow-hidden sm:justify-between"
                >
                  {/* Mobile layout: Image above text */}
                  <div className="grid grid-cols-1 sm:flex sm:flex-row sm:items-center w-full">
                    <CourseImage 
                      imagePath={item.imageID} 
                      imgWidth="w-24" 
                      imgHeight="h-24" 
                      className="mb-4 sm:mb-0 flex-shrink-0"
                    />
                    
                    {/* Course Details */}
                    <div className="flex flex-col mt-4 sm:flex-1 sm:ml-4">
                      <h3 className="text-lg font-medium mt-2 sm:mt-0">{item.courseName}</h3>
                      <p className="text-sm py-2 text-gray-500">
                        {item.session.startDate.split('T')[0]} - {item.session.endDate.split('T')[0]}
                      </p>
                      <p className="text-sm text-gray-500">{item.timeChosen}</p>
                    </div>
                  </div>

                  {/* Mobile Layout: Price and Trash Icon side-by-side */}
                  <div className="flex flex-row sm:flex-col justify-between items-center w-full sm:w-auto mt-4 sm:mt-0 space-x-6 sm:space-x-0 sm:space-y-4">
                    {/* Price */}
                    <span className="text-lg font-semibold">${item.price}</span>

                    {/* Trash Icon */}
                    <FontAwesomeIcon 
                      icon={faTrash} 
                      className="text-gray-400 cursor-pointer" 
                      onClick={() => handleDecreaseQuantity(item)} 
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>


          {/* Order Summary Section */}
          <div className="lg:w-2/5 pt-20 space-y-6">
            <div className="bg-white p-8 rounded-lg shadow-xl">
              <h3 className="text-lg font-medium mb-4">Order Summary</h3>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span>Subtotal</span>
                  <span>${subtotal.toFixed(2)}</span>
                </div>
                {discountPercentage > 0 && (
                  <div className="flex justify-between">
                    <span>Discount ({discountPercentage}%)</span>
                    <span>-${(subtotal * discountPercentage / 100).toFixed(2)}</span>
                  </div>
                )}
                <hr className="border-gray-300 my-4" />
                <div className="flex justify-between font-medium text-lg">
                  <span>Total</span>
                  <span>${orderTotal.toFixed(2)}</span>
                </div>
              </div>
              <button 
                onClick={handleCheckout} 
                className="w-full bg-webDarker text-white py-2 rounded-full mt-8 hover:bg-hoverColor transition-colors"
              >
                {loading ? (
                  <>
                    <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                    {redirecting ? "Redirecting to login page..." : "Redirecting to payment..."}
                  </>
                ) : (
                  "Check Out"
                )}
              </button>
            </div>

            {/* Promo Code Section */}
            <div className="bg-white p-6 rounded-lg shadow-xl">
              <h3 className="text-lg font-semibold mb-4">Promo Code</h3>
              <div className="flex space-x-2">
                <input 
                  type="text" 
                  value={promoCode} 
                  onChange={(e) => setPromoCode(e.target.value)} 
                  placeholder="Enter promo code" 
                  className="w-full px-4 py-2 border rounded-lg"
                />
                <button 
                  onClick={handlePromoCheck} 
                  className="bg-webDarker text-white px-4 py-2 rounded-full hover:bg-hoverColor transition-colors"
                >
                  Submit
                </button>
              </div>
              {promoMessage && (
                <p className={`mt-2 ${discountPercentage > 0 ? 'text-green-500' : 'text-red-500'}`}>
                  {promoMessage}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />

    </>
  );
};

export default Checkout;
