import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../api/firebaseConfig';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';

const CourseSignupForm = ({ course }) => {
  const user = useSelector((state) => state.user.user);
  const [submissionStatus, setSubmissionStatus] = useState('');
  const [alreadySignedUp, setAlreadySignedUp] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (user) {
      const checkExistingSignup = async () => {
        try {
          const q = query(
            collection(db, 'newComers'),
            where('email', '==', user.email),
            where('courseId', '==', course.id)
          );

          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
            setAlreadySignedUp(true);
          }
        } catch (error) {
          console.error('Error checking signup:', error);
        } finally {
          setLoading(false);
        }
      };

      checkExistingSignup();
    } else {
      setLoading(false);
    }
  }, [user, course.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const newSignup = {
      courseName: course?.title,
      courseId: course?.id,
      name: user?.name,
      email: user?.email,
      date: new Date().toLocaleString('en-US', { timeZone: 'America/New_York' }),
    };

    try {
      await addDoc(collection(db, 'newComers'), newSignup);
      const emailParams = {
        to_name: user?.name,
        to_email: user?.email,
        course_name: course?.title,
      };
      await emailjs.send(
        'service_j9xjuih',
        'template_dibt0jt',
        emailParams,
        'SWdW9oauCRNUg4BAC'
      );
      setSubmissionStatus('success');
      setAlreadySignedUp(true);
      
    } catch (error) {
      console.error('Error signing up:', error);
      setSubmissionStatus('error');
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center p-6">
        <FontAwesomeIcon icon={faSpinner} className="text-webDarker animate-spin text-4xl" />
      </div>
    );
  }

  if (!user) {
    return (
      <div className="bg-white p-10 rounded-xl shadow-xl mt-12 max-w-md mx-auto flex flex-col items-center justify-center space-y-6">
        <FontAwesomeIcon icon={faMinus} className="text-webDarker text-2xl" />
        <p className="text-webDarker text-xl font-medium text-center">Please log in to enroll in this course.</p>
        <Link
          to="/login"
          className="w-full text-center bg-blue-500 text-white py-3 rounded-lg text-lg font-medium hover:bg-blue-600 transition-all duration-300"
        >
          Log In
        </Link>
      </div>
    );
  }

  return (
    <div className="bg-white p-6 rounded-lg w-full shadow-md mt-8 text-center">
      <FontAwesomeIcon icon={faMinus} className="text-webDarker text-2xl" />
      <h3 className="text-2xl font-bold mb-4 text-center text-webDarker">Pre-Sign Up</h3>
      {alreadySignedUp ? (
        <div>
          <p className="text-green-500 mb-4 text-center font-normal text-base">Already signed up! Check you email and portal.</p>
          </div>
      
      ) : (
        <>
          {submissionStatus === 'success' && (
            <p className="text-primary mb-4 text-center">You have successfully signed up for the course!</p>
          )}
          {submissionStatus === 'error' && (
            <p className="text-red-400 mb-4 text-center">There was an error signing up. Please try again.</p>
          )}
          <form onSubmit={handleSubmit} className="flex justify-center items-center mt-6">
            <button
              type="submit"
              className={`w-1/3 max-w-xs bg-blue-500 text-white py-3 rounded-full flex justify-center items-center 
                font-semibold text-base transition-colors duration-300 
                ${submitting ? 'opacity-75 cursor-not-allowed' : 'hover:bg-blue-600'}`}
              disabled={submitting}
            >
              {submitting ? (
                <FontAwesomeIcon icon={faSpinner} className="animate-spin text-white" />
              ) : (
                'Sign Up Now!'
              )}
            </button>
          </form>

        </>
      )}
    </div>
  );
};

export default CourseSignupForm;
