import React from 'react';

const TopicsCovered = ({ topics }) => {
    return (
        <div className="mt-12 px-8">
            <h2 className="text-3xl font-medium mb-4">Topics Covered</h2>
            <ul className="list-disc list-inside">
                {topics.map((topic, index) => (
                    <li key={index} className="text-textGray text-base leading-relaxed">{topic}</li>
                ))}
            </ul>
        </div>
    );
};

export default TopicsCovered;
