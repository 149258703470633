// EditRescheduleRequests.jsx
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRescheduleRequests, approveRescheduleRequest, rejectRescheduleRequest } from '../../../redux/slices/RescheduleRequestSlice';
import { fetchUserDataStandalone } from '../../../redux/slices/userSlice';
import { toast } from 'react-toastify';
import _ from 'lodash';
import {RequestSection} from './RescheduleRequestComponents/layouts';


const formatTimeSlot = (timeSlot) => {
  return timeSlot.replace(/[^0-9APM]/g, '');
};

const EditRescheduleRequests = () => {
  const dispatch = useDispatch();


  // Select requests and loading/error states from the Redux store
  const { requests, loading, error } = useSelector((state) => state.reschedule);
  // State to track which request's details are expanded
  const [expandedRequestId, setExpandedRequestId] = useState(null);

  // Fetch the reschedule requests when the component mounts
  useEffect(() => {
    dispatch(fetchRescheduleRequests());
  }, [dispatch]);

  // Function to toggle the detail view of a specific request
  const toggleDetails = (id) => {
    setExpandedRequestId((prevId) => (prevId === id ? null : id));
  };

  // Function to approve or reject reschedule request--------------------------//

  const handleApprove = async (request) => {
    const confirmReject = window.confirm('Are you sure you want to approve this reschedule request?');
    if (!confirmReject) return;
    try {
      // Fetch the user's details based on the userId in the request
      // const userDetails = await dispatch(fetchUserData(request.userId)).unwrap();
      if (request.status !== 'pending') {
        toast.error('Request is not pending. Approval rejected.');
        return;
      }
      const userDetails = await fetchUserDataStandalone(request.userId);


      // Verify the user's ID and email
      if (userDetails.uid !== request.userId || userDetails.emailSignup !== request.pendingUserDetailsUpdate.emailSignup) {
        console.log(`user collection user==> id:${userDetails.uid} email:${userDetails.emailSignup}`)
        console.log(`requested user==> id:${userDetails.uid} email:${userDetails.emailSignup}`)
        toast.error('User ID or email does not match. Approval rejected.');
        return;
      }else{
        toast.success('User ID and email match. Approval successful.');
      }

      // Check if the status is pending
      if (request.status !== 'pending') {
        toast.error('Request is not in pending status.');
        return;
      }else{
        toast.success('Request is in pending status.');
      }

      // Verify the pendingUserDetailsUpdate orderHistory against the user's actual orderHistory
      const pendingOrderHistory = request.pendingUserDetailsUpdate.orderHistory;
      const actualOrderHistory = userDetails.orderHistory;

      // Deep comparison of all fields in orderHistory
      const isOrderHistoryMatch = _.isEqual(pendingOrderHistory, actualOrderHistory);
      if (!isOrderHistoryMatch) {
        console.log("Detailed Order History Mismatch:");

        // Log the JSON strings for visual inspection (as you were doing previously)
        console.log(`Pending Order History: ${JSON.stringify(pendingOrderHistory, null, 2)}`);
        console.log(`Actual Order History: ${JSON.stringify(actualOrderHistory, null, 2)}`);

        // Deeply compare using lodash and log the differences
        const differences = _.differenceWith(
          pendingOrderHistory,
          actualOrderHistory,
          _.isEqual
        );

        console.log("Differences between Pending and Actual Order History:", differences);

        // If the order history is an object and you need to compare each field
        Object.keys(pendingOrderHistory).forEach((key) => {
          if (!_.isEqual(pendingOrderHistory[key], actualOrderHistory[key])) {
            console.log(`Mismatch at key: ${key}`);
            console.log(`Pending: ${JSON.stringify(pendingOrderHistory[key], null, 2)}`);
            console.log(`Actual: ${JSON.stringify(actualOrderHistory[key], null, 2)}`);
          }
        });

        toast.error('Pending order history does not match user order history.');
        return;
      } else {
        toast.success('Pending order history matches user order history.');
      }


      // Verify that the intended course exists in registeredCourses
      const { courseId, oldSessionId, oldTimeSlot } = request.rescheduleRecord;
      const courseKey = `${courseId}_${oldSessionId}_${formatTimeSlot(oldTimeSlot)}`;

      if (!userDetails.registeredCourses[courseKey]) {
        toast.error('The course key does not exist in registered courses.');
        return;
      }else{
        toast.success('The course key exists in registered courses.');
      }

      // this is used to update the respective request in rescheduleRequests collection
      const updatedRequest = {
        ...request,
        status: 'approved',
        approvalTime: new Date().toISOString(),
      };
      // add new fields to request.rescheduledRecord to indicate approval.
      const approvedRescheduleRecord = {
        ...request.rescheduleRecord,
        status: 'approved',
        approvalTime: new Date().toISOString(),
      }
      //this updatedUser is used to update the user's whole doc data
      const updatedUser = {
        ...userDetails,
        registeredCourses: request.pendingUserDetailsUpdate.registeredCourses,
        rescheduleRecords: [
          ...(userDetails.rescheduleRecords || []),
          approvedRescheduleRecord,
        ],
      };

      // Update the user's data in Firestore
      //await dispatch(updateUser({ userId: request.userId, userDetails: updatedUser })).unwrap();
      console.log("finalizing check, updated user:", updatedUser);
      console.log("finalizing check, updated request:", updatedRequest);

      // Update the request in Firestore
      //await dispatch(approveRescheduleRequest(updatedUser, updatedRequest)).unwrap();
      await dispatch(approveRescheduleRequest({ updatedUser, updatedRequest })).unwrap();


      toast.success('Reschedule request approved successfully.');
    } catch (error) {
      toast.error(`Approval failed: ${error.message}`);
    }
  };

  // Function to handle rejection of a reschedule request
  const handleReject = async (request) => {
    // Confirm rejection from the user
    const confirmReject = window.confirm('Are you sure you want to reject this reschedule request?');
    if (!confirmReject) return;
  
    try {
      if (request.status !== 'pending') {
        toast.error('Request is not in pending status.');
        return;
      }
      // Dispatch the rejectRescheduleRequest thunk with the request ID
      await dispatch(rejectRescheduleRequest({ requestId: request.id })).unwrap();
      toast.success('Reschedule request rejected successfully.');
    } catch (error) {
      toast.error(`Rejection failed: ${error.message}`);
    }
  };
  //----------------------------------------------------------------------------//
  const pendingRequests = requests.filter((request) => request.status === 'pending');
  const approvedRequests = requests.filter((request) => request.status === 'approved');
  const rejectedRequests = requests.filter((request) => request.status === 'rejected');

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">Reschedule Requests</h1>

      {loading && <p>Loading reschedule requests...</p>}
      {error && <p className="text-red-500">Error: {error.message}</p>}

      {!loading && !error && (
        <div className="space-y-8">
          {/* Pending Requests Section */}
          <RequestSection 
            title="Pending Requests" 
            requests={pendingRequests} 
            expandedRequestId={expandedRequestId} 
            toggleDetails={toggleDetails} 
            handleApprove={handleApprove} 
            handleReject={handleReject} 
          />

          {/* Approved Requests Section */}
          <RequestSection 
            title="Approved Requests" 
            requests={approvedRequests} 
            expandedRequestId={expandedRequestId} 
            toggleDetails={toggleDetails} 
          />

          {/* Rejected Requests Section */}
          <RequestSection 
            title="Rejected Requests" 
            requests={rejectedRequests} 
            expandedRequestId={expandedRequestId} 
            toggleDetails={toggleDetails} 
          />
        </div>
      )}
    </div>
  );
};

export default EditRescheduleRequests;
