import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GeoPoint } from 'firebase/firestore';
import InteractiveStarRating from './InteractiveStarRating';
import CustomSelect from '../../utilities/customSelect';
import CourseImage from '../../utilities/getImageWithDiv';

const ReviewForm = ({ courseTitle, instructors, onSubmit }) => {
  const [rating, setRating] = useState(0); 
  const [comment, setComment] = useState(''); 
  const [userLocation, setUserLocation] = useState([]);
  const [selectedInstructor, setSelectedInstructor] = useState(null); 
  const user = useSelector((state) => state.user.user);
  const userID = user?.name;

  // Get user's location using Geolocation API
  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation(new GeoPoint(latitude, longitude));
      });
    }
  };

  // Call getUserLocation when component mounts
  useEffect(() => {
    getUserLocation();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedInstructor) {
      toast.error("Please select an instructor before submitting the review.");
      return;
    }
    if (rating === 0) {
      toast.error("Please provide a rating before submitting the review.");
      return;
    }
    if (!comment.trim()) {
      toast.error("Please enter a comment before submitting the review.");
      return;
    }

    const reviewData = {
      rating,
      comment,
      userLocation,
      userID,
      instructorID: selectedInstructor,
    };
    onSubmit(reviewData);

    setRating(0);
    setComment('');
  };

  const selectedInstructorObj = instructors.find((instructor) => instructor.id === selectedInstructor);

  return (
    <div className="max-w-lg mx-auto bg-white rounded-lg shadow-md p-8 md:p-8 sm:p-4">
      <h2 className="text-3xl md:text-3xl sm:text-2xl font-medium text-center mb-6">Write your review</h2>
      <h3 className="text-base md:text-base sm:text-sm text-textGray font-normal text-center mb-4">{courseTitle}</h3>
      {/* Instructor Image */}
      {selectedInstructorObj && (
        <div className="relative mb-6 flex flex-col items-center justify-center animate-blurIn">
            {/* Course Image */}
            <CourseImage imagePath={selectedInstructorObj.imageUrl} />

            {/* Tag-like Bio Section */}
            <div className="flex items-center justify-center mt-4">
            <span className="items-center text-webDarker text-xs px-3 py-1 rounded-full shadow-lg">
                {selectedInstructorObj.bio}
            </span>
            </div>
        </div>
      )}

      {/* Instructor Selection */}
      <div className="mb-6">
        <h4 className="text-sm md:text-sm sm:text-xs font-medium mb-2">Select Instructor:</h4>
        <CustomSelect
          options={instructors.map((instructor) => ({
            label: instructor.name,
            value: instructor.id,
          }))}
          value={selectedInstructor}
          onChange={setSelectedInstructor}
          className="w-full sm:text-sm md:text-base text-xs"
        />
      </div>

      {/* Rating Section */}
      <div className="flex justify-between items-center mb-6">
        <span className="text-sm md:text-sm sm:text-xs font-medium">Rate</span>
        <div className="flex-grow flex justify-center items-center">
          <InteractiveStarRating rating={rating} setRating={setRating} />
        </div>
        <span className="text-lg md:text-lg sm:text-sm font-medium ml-2">{rating.toFixed(1)}</span>
      </div>

      {/* Comment Section */}
      <textarea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        placeholder="Write your comment here..."
        rows="4"
        className="w-full border border-gray-300 rounded-lg p-4 mb-6 resize-none focus:outline-none focus:border-blue-500 sm:text-sm md:text-base text-xs"
      ></textarea>

      {/* Submit Button */}
      <button
        onClick={handleSubmit}
        className="w-full bg-[#EBF1FB] text-webDarker py-4 px-4 rounded-full text-base transition duration-300 hover:bg-webDarker hover:text-white sm:text-sm md:text-base"
      >
        Submit your review
      </button>
    </div>
  );
};

export default ReviewForm;
