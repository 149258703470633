import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faDiscord, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet';

function Footer() {
  return (
    <footer className="bg-[#041D34] text-white py-8">
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=yes" />
      </Helmet>
      <div className="w-full flex flex-col sm:flex-row justify-between items-center sm:items-start px-4 sm:px-6 lg:px-8">
        {/* Footer Links */}
        <div className="flex flex-wrap gap-4 sm:gap-8 mb-6 sm:mb-0 text-center sm:text-left">
          <a href="/courses" className="text-white hover:text-hoverColor transition-colors">Courses</a>
          <a href="/" className="text-white hover:text-hoverColor transition-colors">About Us</a>
          <a href="/blogs" className="text-white hover:text-hoverColor transition-colors">Blogs</a>
          <a href="/careers" className="text-white hover:text-hoverColor transition-colors">Careers</a>
          <a href="/FAQ" className="text-white hover:text-hoverColor transition-colors">FAQ</a>
        </div>

        {/* Contact Info */}
        <div className="flex flex-col sm:flex-row items-center sm:items-start gap-8 text-center sm:text-left">
          <div className='flex flex-col items-center sm:items-start gap-2'>
            <p className="font-semibold">Email:</p>
            <span className="text-white text-xs">hello@albuddy.com</span>
          </div>
          <div className='flex flex-col items-center sm:items-start gap-2'>
            <p className="font-semibold">Address:</p>
            <span className="text-white text-xs">P.O. Box 19901, 00100</span>
            <span className='text-xs'>8 The Green Ste B, Dover, DE</span>
          </div>
          <div className='flex flex-col items-center sm:items-start gap-2'>
            <p className='font-semibold'>Social:</p>
            <div className='flex gap-4'>
              <a href="https://m.facebook.com/61550014559364/" target="_blank" rel="noopener noreferrer" className="text-webDarker hover:text-blue-500 transition-colors">
                <FontAwesomeIcon icon={faFacebook} size="xl" />
              </a>
              <a href="https://www.instagram.com/albuddy_edu/" target="_blank" rel="noopener noreferrer" className="text-webDarker hover:text-pink-500 transition-colors">
                <FontAwesomeIcon icon={faInstagram} size="xl" />
              </a>
              <a href="https://discord.gg/EEKjMNrVQd" target="_blank" rel="noopener noreferrer" className="text-webDarker hover:text-indigo-500 transition-colors">
                <FontAwesomeIcon icon={faDiscord} size="xl" />
              </a>
              <a href="https://www.linkedin.com/company/albuddy" target="_blank" rel="noopener noreferrer" className="text-webDarker hover:text-blue-700 transition-colors">
                <FontAwesomeIcon icon={faLinkedin} size="xl" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-6 text-gray-400">
        Albuddy &copy; {new Date().getFullYear()}
      </div>
    </footer>
  );
}

export default React.memo(Footer);
