import React from 'react';

const AboutCourse = ({ about }) => {
    return (
        <div className="mt-12 px-8">
            <h2 className="text-3xl font-medium mb-4">About This Course</h2>
            <p className="text-textGray text-base leading-relaxed">{about}</p>
        </div>
    );
};

export default AboutCourse;
