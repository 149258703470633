// rescheduleSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from '../../api/firebaseConfig';
import { collection, doc, setDoc, query, where, getDocs, getDoc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';

// Async thunk to add a new reschedule request
export const createRescheduleRequest = createAsyncThunk(
  'reschedule/createRescheduleRequest',
  async (requestDetails, { rejectWithValue }) => {
    try {
        const { userId, requestedCourseId, rescheduleRecord } = requestDetails;
        const q = query(
            collection(db, 'rescheduleRequests'),
            where('userId', '==', userId),
            where('requestedCourseId', '==', requestedCourseId)
        );
        const querySnapshot = await getDocs(q);
        let isDuplicate = false;
        querySnapshot.forEach((doc) => {
            const existingRequest = doc.data();
            console.log("processing")
            if (existingRequest?.rescheduleRecord) {
                const existedRecords = existingRequest?.rescheduleRecord;
                console.log("Existed records:", existedRecords);
                if(
                    existedRecords.oldSessionId === rescheduleRecord.oldSessionId &&
                    existedRecords.newSessionId === rescheduleRecord.newSessionId &&
                    existedRecords.oldTimeSlot === rescheduleRecord.oldTimeSlot &&
                    existedRecords.newTimeSlot === rescheduleRecord.newTimeSlot
                ){
                    console.log("Duplicate request found:", existedRecords);
                    isDuplicate = true;
                }
            }
        });
        if (isDuplicate) {
            return rejectWithValue({ message: 'You have already submitted a similar reschedule request.' });
        }

        const requestRef = doc(collection(db, 'rescheduleRequests'));
        await setDoc(requestRef, requestDetails);
        return { id: requestRef.id, ...requestDetails };

        } catch (error) {
        return rejectWithValue({ message: error.message });
        }
  }
);
// Async thunk to add a new reschedule request for the admin portal
export const fetchRescheduleRequests = createAsyncThunk(
    'reschedule/fetchRescheduleRequests',
    async (_, { rejectWithValue }) => {
      try {
        const querySnapshot = await getDocs(collection(db, 'rescheduleRequests'));
        
        const requests = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
  
        return requests;
      } catch (error) {
        return rejectWithValue({ message: error.message });
      }
    }
  );

// Async thunk to approve a reschedule request
export const approveRescheduleRequest = createAsyncThunk(
  'reschedule/approveRescheduleRequest',
  async ({ updatedUser, updatedRequest }, { rejectWithValue }) => {
    try {
      console.log('Starting to approve reschedule request...');

      // 1. Fetch all required documents
      const userRef = doc(db, 'users', updatedUser.uid);
      const requestRef = doc(db, 'rescheduleRequests', updatedRequest.id);

      const userDoc = await getDoc(userRef);
      const requestDoc = await getDoc(requestRef);

      if (!userDoc.exists()) {
        throw new Error(`User document not found for email: ${updatedUser.emailSignup} and uid: ${updatedUser.uid}`);
      }

      if (!requestDoc.exists()) {
        throw new Error(`Reschedule request document not found for request ID: ${updatedRequest.id}`);
      }

      console.log('User and reschedule request documents fetched successfully.');

      const requestData = requestDoc.data();
      if (requestData.status !== 'pending') {
        throw new Error(`Reschedule request is not in pending status. Current status: ${requestData.status}`);
      }

      const courseRef = doc(db, 'courses', requestData.requestedCourseId);
      const courseDoc = await getDoc(courseRef);

      if (!courseDoc.exists()) {
        throw new Error(`Course document not found for id: ${requestData.requestedCourseId}`);
      }

      const newSessionDocRef = doc(collection(courseRef, 'sessions'), requestData.rescheduleRecord.newSessionId);
      const oldSessionDocRef = doc(collection(courseRef, 'sessions'), requestData.rescheduleRecord.oldSessionId);
      const newSessionDoc = await getDoc(newSessionDocRef);
      const oldSessionDoc = await getDoc(oldSessionDocRef);

      if (!newSessionDoc.exists()) {
        throw new Error('New session document not found.');
      }
      if (!oldSessionDoc.exists()) {
        throw new Error('Old session document not found.');
      }

      console.log('New and old session documents fetched successfully.');

      const sessionDataNew = newSessionDoc.data();
      const sessionDataOld = oldSessionDoc.data();

      const newTimeSlotStartTime = requestData.rescheduleRecord.newTimeSlot.split('-')[0].trim();
      const newTimeSlotEndTime = requestData.rescheduleRecord.newTimeSlot.split('-')[1].trim();
      const oldTimeSlotStartTime = requestData.rescheduleRecord.oldTimeSlot.split('-')[0].trim();
      const oldTimeSlotEndTime = requestData.rescheduleRecord.oldTimeSlot.split('-')[1].trim();

      // Check if the old and new time slots are in the same session
      const isSameSession = requestData.rescheduleRecord.newSessionId === requestData.rescheduleRecord.oldSessionId;

      if (isSameSession) {
        console.log('Both time slots are in the same session. Updating a single session.');

        // 2. Modify the same session's time slots
        const updatedTimes = JSON.parse(JSON.stringify(sessionDataNew.times));

        // Decrease new slot availability
        Object.keys(updatedTimes).forEach(key => {
          const timeSlot = updatedTimes[key];
          if (timeSlot.startTime === newTimeSlotStartTime && timeSlot.endTime === newTimeSlotEndTime) {
            timeSlot.slots -= 1;
            if (timeSlot.slots === 0) {
              timeSlot.isAvailable = false;
            }
          }
        });

        // Increase old slot availability
        Object.keys(updatedTimes).forEach(key => {
          const timeSlot = updatedTimes[key];
          if (timeSlot.startTime === oldTimeSlotStartTime && timeSlot.endTime === oldTimeSlotEndTime) {
            timeSlot.slots += 1;
            timeSlot.isAvailable = true;
          }
        });

        // Update the session with both changes
        await updateDoc(newSessionDocRef, { times: updatedTimes });
        console.log('Session updated successfully with both old and new time slots.');

      } else {
        console.log('Time slots are in different sessions. Updating separately.');

        // 3. Handle the case where old and new time slots are in different sessions

        // Update new session time slot
        const updatedNewTimes = JSON.parse(JSON.stringify(sessionDataNew.times));
        Object.keys(updatedNewTimes).forEach(key => {
          const timeSlot = updatedNewTimes[key];
          if (timeSlot.startTime === newTimeSlotStartTime && timeSlot.endTime === newTimeSlotEndTime) {
            timeSlot.slots -= 1;
            if (timeSlot.slots === 0) {
              timeSlot.isAvailable = false;
            }
          }
        });

        // Update old session time slot
        const updatedOldTimes = JSON.parse(JSON.stringify(sessionDataOld.times));
        Object.keys(updatedOldTimes).forEach(key => {
          const timeSlot = updatedOldTimes[key];
          if (timeSlot.startTime === oldTimeSlotStartTime && timeSlot.endTime === oldTimeSlotEndTime) {
            timeSlot.slots += 1;
            if (timeSlot.slots > 0) {
              timeSlot.isAvailable = true;
            }
          }
        });

        await Promise.all([
          updateDoc(newSessionDocRef, { times: updatedNewTimes }),
          updateDoc(oldSessionDocRef, { times: updatedOldTimes })
        ]);

        console.log('New and old session updated separately.');
      }

      // Update user document and request status
      await updateDoc(userRef, updatedUser);
      await updateDoc(requestRef, {
        ...updatedRequest,
        status: 'approved',
        approvalTime: new Date().toISOString(),
      });

      console.log('User document and reschedule request updated successfully.');
      toast.success('Reschedule request approved successfully.');
      return { success: true, message: 'Reschedule request approved successfully.' };

    } catch (error) {
      console.error('Error approving reschedule request:', error);
      toast.error(`Approval failed: ${error.message}`);
      return rejectWithValue(error.message);
    }
  }
);





// Async thunk to reject a reschedule request
export const rejectRescheduleRequest = createAsyncThunk(
  'reschedule/rejectRescheduleRequest',
  async ({ requestId, status = 'rejected' }, { rejectWithValue }) => {
    try {
      const requestRef = doc(db, 'rescheduleRequests', requestId);

      // Check if the request exists in Firestore
      const requestDoc = await getDoc(requestRef);
      if (!requestDoc.exists()) {
        return rejectWithValue({ message: 'Request not found.' });
      }

      // Update the request's status to 'rejected' and set rejectionTime
      await updateDoc(requestRef, {
        status,
        rejectionTime: new Date().toISOString(),
      });

      return { requestId, status };
    } catch (error) {
      console.error('Error rejecting reschedule request:', error);
      return rejectWithValue({ message: error.message });
    }
  }
);


const rescheduleSlice = createSlice({
  name: 'reschedule',
  initialState: {
    requests: [],
    loading: false,
    error: null,
    approvalStatus: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createRescheduleRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createRescheduleRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.requests.push(action.payload)
      })
      .addCase(createRescheduleRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchRescheduleRequests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRescheduleRequests.fulfilled, (state, action) => {
        state.loading = false;
        state.requests = action.payload;
      })
      .addCase(fetchRescheduleRequests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(approveRescheduleRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.approvalStatus = 'pending';
      })
      .addCase(approveRescheduleRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.approvalStatus = 'succeeded';
        toast.success(action.payload.message);
      })
      .addCase(approveRescheduleRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
        state.approvalStatus = 'failed';
        toast.error(state.error);
      })
      .addCase(rejectRescheduleRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(rejectRescheduleRequest.fulfilled, (state, action) => {
        state.loading = false;
        const requestIndex = state.requests.findIndex(request => request.id === action.payload.requestId);
        if (requestIndex !== -1) {
          state.requests[requestIndex].status = action.payload.status;
        }
        toast.success('Reschedule request rejected successfully.');
      })
      .addCase(rejectRescheduleRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
        toast.error(state.error);
      });
  },
});

export default rescheduleSlice.reducer;
