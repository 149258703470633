import React from 'react';
import bloombergSmall from '../../assets/srcSetImages/bloomberg/bloomberg-small.webp';
import cornellSmall from '../../assets/srcSetImages/cornell/cornell-small.webp';
import goldmanSmall from '../../assets/srcSetImages/GoldMan/goldMan-small.webp';
import jhuSmall from '../../assets/srcSetImages/johnsHopkins/jhu-small.webp';
import metaSmall from '../../assets/srcSetImages/meta/meta-small.webp';
import mitSmall from '../../assets/srcSetImages/mit/mit-small.png';
import nyuSmall from '../../assets/srcSetImages/nyu/nyu-small.webp';
import rbcSmall from '../../assets/srcSetImages/RBC/rbc-small.webp';

function AffiliationsSection() {
    return (
        <section className="py-10 overflow-hidden">
            <h2 className="text-3xl text-center font-medium text-gray-800 mb-12 animate-slideInFromLeft">
                Our Collaboration with
            </h2>
            <div className="flex flex-wrap md:flex-nowrap justify-evenly items-center gap-5 md:gap-8 px-4 md:px-20">
                <img
                    src={goldmanSmall}
                    alt="Goldman Sachs Logo"
                    className="h-24 md:h-20 transition-transform transform hover:scale-105 opacity-85 hover:opacity-100"
                />
                <img
                    src={rbcSmall}
                    alt="RBC Logo"
                    className="h-24 md:h-20 transition-transform transform hover:scale-105 opacity-85 hover:opacity-100"
                />
                <img
                    src={bloombergSmall}
                    alt="Bloomberg Logo"
                    className="h-24 md:h-20 transition-transform transform hover:scale-105 opacity-85 hover:opacity-100"
                />
                <img
                    src={metaSmall}
                    alt="Meta Logo"
                    className="h-24 md:h-20 transition-transform transform hover:scale-105 opacity-85 hover:opacity-100"
                />
                <img
                    src={mitSmall}
                    alt="MIT Logo"
                    className="h-24 md:h-20 transition-transform transform hover:scale-105 opacity-85 hover:opacity-100"
                />
                <img
                    src={cornellSmall}
                    alt="Cornell Logo"
                    className="h-24 md:h-20 transition-transform transform hover:scale-105 opacity-85 hover:opacity-100"
                />
                <img
                    src={nyuSmall}
                    alt="NYU Logo"
                    className="h-24 md:h-20 transition-transform transform hover:scale-105 opacity-85 hover:opacity-100"
                />
                <img
                    src={jhuSmall}
                    alt="JHU Logo"
                    className="h-24 md:h-20 transition-transform transform hover:scale-105 opacity-85 hover:opacity-100"
                />
            </div>
        </section>
    );
}

export default AffiliationsSection;
