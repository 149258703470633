// AdminPortal.jsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from './AdminNavbar';
import { useSelector } from 'react-redux';

const AdminPortal = () => {
    const courses = useSelector((state) => state.courses.courses);

    // useEffect(() => {
    //     if(courses.length === 0) {
    //       dispatch(fetchCourses());
    //     }
    // }, [courses, dispatch]);

    return (
        <div className="flex h-screen">
        {/* Navbar */}
        <Navbar />

        {/* Main Content */}
        <div className="flex-1 p-8 bg-white text-black overflow-y-auto border-t-2 border-gray-200">
            {/* Page Header */}
            <div className=" mb-8 pb-4 flex items-center justify-between">
            {/* Tabs */}
            <div className="flex space-x-8">
                <span className="text-xl font-semibold text-black cursor-pointer border-b-4 border-primary pb-2">
                Overview
                </span>
            </div>
            </div>

            {/* Analytics Cards */}
            <div className="grid grid-cols-4 gap-6 mb-8">
            <div className="bg-secondaryBackground p-4 rounded-lg shadow-custom-light text-center">
                <h3 className="text-lg text-black">Current number of users</h3>
                <p className="text-3xl font-bold text-primary">30,380</p>
            </div>
            <div className="bg-secondaryBackground p-4 rounded-lg shadow-custom-light text-center">
                <h3 className="text-lg text-black">Current number of courses</h3>
                <p className="text-3xl font-bold text-black">{courses.length}</p>
            </div>
            </div>            
            <div className="bg-white rounded-lg shadow-md p-6">
                <Outlet />
            </div>
        </div>
        </div>
    );
};

export default AdminPortal;
