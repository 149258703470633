import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getDocs, collection } from 'firebase/firestore';
import { db } from '../../api/firebaseConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faSpinner, faCalendar, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import getImage from '../../utilities/getImage';

const PreSignup = () => {
    const user = useSelector(state => state.user.user);
    const courses = useSelector(state => state.courses.courses);
    const [usersSignup, setUsersSignup] = useState([]);
    const [userSignupCourses, setUserSignupCourses] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNewComers = async () => {
            setLoading(true);
            try {
                const querySnapshot = await getDocs(collection(db, 'newComers'));
                const newComersData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                const filteredUsersSignup = newComersData.filter(newComer => 
                    newComer?.email === user.email && newComer?.name
                );
                setUsersSignup(filteredUsersSignup);
            } catch (error) {
                console.error('Error fetching newComers:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchNewComers();
    }, [user.email]);

    useEffect(() => {
        if (usersSignup.length > 0 && courses.length >0) {
            const coursesMap = new Map(courses.map(course=> [course.id, course]));
            const combinedData = usersSignup.map(signup=>{
                const course = coursesMap.get(signup.courseId);
                return {
                    signup: signup,
                    course: course
                }
            });

            setUserSignupCourses(combinedData);
        }else{
            setUserSignupCourses([]);
        }
    },[usersSignup, courses]);
    console.log("combinedData", userSignupCourses)

    const SignUpCard = ({ signup, course }) => {
      const image = getImage({ imagePath: course.imageID });
    
      return (
        <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
          {/* Left Side: Course Image and Tags */}
          <div className="relative md:w-1/3">
            {/* Course Image */}
            <img src={image} alt={course.title} className="w-full h-full object-cover" />
    
            {/* Tags */}
            <div className="absolute top-2 left-2 flex flex-wrap">
              {course.category.map((tag, index) => (
                <span
                  key={index}
                  className="bg-white/30 backdrop-blur-lg text-white rounded-md px-3 py-1 text-sm font-medium shadow-md"
                >
                  {tag}
                </span>
              ))}
            </div>
          </div>
    
          {/* Right Side: Course Details */}
          <div className="flex-1 p-6 flex flex-col justify-between">
            {/* Course Info */}
            <div className="flex items-center text-sm text-gray-600 mb-2">
              <p className="mr-4">
                <FontAwesomeIcon icon={faCalendar} className='mr-2 text-webDarker'/> {course.duration}
              </p>
              <p>
                <FontAwesomeIcon icon={faUserGroup} className='mr-2 text-webDarker'/> {course.styleFormat}
              </p>
            </div>
    
            {/* Course Title */}
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">{course.title}</h2>
    
            {/* Separator Line */}
            <div className="border-b border-gray-300 mb-4"></div>
    
            {/* Instructors */}
            <div className="flex items-center mb-4">
              {course.instructors.map((instructor, index) => (
                <p key={index} className="text-base font-semibold text-gray-700 mr-4 flex flex-row items-center gap-4">
                  <img src={getImage({ imagePath: instructor.imageUrl })} alt={instructor.name} className='w-12 h-12 rounded-full'></img>
                  <div className='flex flex-col'>
                    {instructor.name}<span className='font-normal text-sm text-textGray'>{instructor.bio}</span>
                  </div>
                  
                </p>
              ))}
            </div>
    
            {/* Signed Up Date and Link */}
            <div className="flex flex-col sm:flex-row sm:justify-between items-start sm:items-center">
              <div className="text-sm text-gray-600 mb-2 sm:mb-0">
                <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                <strong>Signed Up Date:</strong>{" "}
                {new Date(signup.date).toLocaleDateString()}
              </div>
              <Link
                to={`/courses/${course.id}`}
                state={{ course }}
                className="text-sm text-primary hover:underline"
              >
                View course details
              </Link>
            </div>
          </div>
        </div>
      );
    };
    return (
      <div className="container mx-auto p-6">
        {loading ? (
          <div className="flex justify-center items-center py-16">
            <FontAwesomeIcon icon={faSpinner} className="text-webDarker animate-spin text-4xl" />
          </div>
        ) : userSignupCourses.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-1 gap-6 animate-blurIn">
            {userSignupCourses.map(({ signup, course }) => (
              <SignUpCard key={signup.id} signup={signup} course={course} />
            ))}
          </div>
        ) : (
          <div className="text-center py-16">
            <p className="text-lg text-textGray">No signups found for this user.</p>
          </div>
        )}
      </div>
    );
      
}

export default React.memo(PreSignup);
