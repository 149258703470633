import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomButton = ({
  onClick,
  children,
  className = '',
  bgColor = 'bg-[#7aa2e338]',
  textColor = 'text-webDarker',
  hoverBgColor = 'hover:bg-webDarker',
  hoverTextColor = 'hover:text-white',
  rounded = 'rounded-full',
  size = 'px-12 py-6 text-base',
  icon = null,
  iconClass = 'text-xl',
  ...rest
}) => {
  return (
    <button
      onClick={onClick}
      className={`relative w-full sm:w-1/2 ${size} ${bgColor} ${textColor} ${rounded} cursor-pointer transition-colors duration-300 ease-in-out ${hoverBgColor} ${hoverTextColor} overflow-hidden group ${className}`}
      {...rest}
    >
      <span
        className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out group-hover:-translate-x-full"
      >
        {children}
      </span>
      {icon && (
        <span
          className={`absolute inset-0 flex items-center justify-center opacity-0 transform translate-x-full transition-all duration-300 ease-in-out group-hover:opacity-100 group-hover:translate-x-0`}
        >
          <FontAwesomeIcon icon={icon} className={iconClass} />
        </span>
      )}
    </button>
  );
};

CustomButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  hoverBgColor: PropTypes.string,
  hoverTextColor: PropTypes.string,
  rounded: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.object,
  iconClass: PropTypes.string,
};

export default CustomButton;


/*
example usages:
      **Default Button
        <CustomButton onClick={confirmSelection}>
            Continue Anyway
        </CustomButton>

      **Button with Custom Colors
        <CustomButton
            onClick={confirmSelection}
            bgColor="bg-[#ff5733]"
            hoverBgColor="hover:bg-green-600"
            textColor="text-white"
            hoverTextColor="hover:text-yellow-500"
        >
            Save Changes
        </CustomButton>

      **Button with Icon on Hover
        <CustomButton
            onClick={confirmSelection}
            icon={faCheckCircle}
            bgColor="bg-[#4CAF50]"
            hoverBgColor="hover:bg-[#45a049]"
            textColor="text-white"
            hoverTextColor="hover:text-white"
        >
            Submit
        </CustomButton>

      **Large Rounded Button with Different Icon
        <CustomButton
            onClick={cancelAction}
            icon={faTimes}
            bgColor="bg-red-500"
            hoverBgColor="hover:bg-red-700"
            textColor="text-white"
            hoverTextColor="hover:text-white"
            rounded="rounded-lg"
            size="px-16 py-8 text-lg"
            iconClass="text-2xl"
        >
            Cancel
        </CustomButton>

      **Full-Width Button
        <CustomButton
            onClick={confirmSelection}
            icon={faArrowRight}
            bgColor="bg-blue-500"
            hoverBgColor="hover:bg-blue-700"
            textColor="text-white"
            hoverTextColor="hover:text-white"
            size="px-20 py-10 text-xl"
        >
            Proceed
        </CustomButton>
*/