// src/pages/Home/index.js
import React from 'react';
import Footer from '../../components/Footer';
import HeroSection from '../../components/HeroSection/HeroSection';
import ServicesSection from '../../components/ServiceSection/ServiceSection';
import WhyChooseUsSection from '../../components/WhyChooseUsSection/WhyChooseUsSection';
import Testimonials from '../../components/TestimonialsSection/TestimonialsSection';
import CTASection from '../../components/CtaSection/CtaSection';
// import HistorySection from '../../components/OurHistory';
import AffiliationsSection from '../../components/AffiliationSection';
import FeaturedCourses from '../../components/FeaturedCourses/FeaturedCourses';
import { useEffect } from 'react';
import {analytics} from '../../api/firebaseConfig';
import { logEvent } from 'firebase/analytics';
function Home() {
  useEffect(() => {
    logEvent(analytics, 'page_view',{ page_title: 'Home Page'});
  }, [])
  return (
    <>
      <main>
        <div className='main'>
        <HeroSection />
        <ServicesSection />
        <WhyChooseUsSection />
        <Testimonials />
        <FeaturedCourses />
        <AffiliationsSection />
        <CTASection />
        </div>
      </main>

      <Footer />
    </>
  );
}

export default React.memo(Home);
