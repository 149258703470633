import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faBan, faClockFour,faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import CourseCard from './CourseCard';
import useWindowSize from './useWindowSize';
import {convertToReadableDate} from '../../utilities/parseTime';
import {parseTimeSlot, parseTimeSlot2} from './Utility';
import { createPortal } from 'react-dom';


export const RendarNextClass = ({ upcomingCourses, handleCourseClick, handleCourseClickReschedule }) => {
    return (
        <div className="mt-6">
            <h3 className="text-lg font-semibold mb-12 mt-12">Your Active Classes:</h3>
            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-8">
                {upcomingCourses.length > 0 ? (
                    upcomingCourses.map((course, index) => (
                        <CourseCard 
                            key={index}
                            title={course.title}
                            course={course}
                            instructor={course.instructor}
                            startDate={convertToReadableDate(course.startDate)}
                            endDate={convertToReadableDate(course.endDate)}
                            onClick={() => handleCourseClick(course)}
                            onClickReschedule={() => handleCourseClickReschedule(course)}
                        />
                    ))
                ) : (
                    <p className="text-gray-400 text-left">
                        <FontAwesomeIcon icon={faBan} /> No classes found.
                    </p>
                )}
            </div>
        </div>
    );
};



export const RenderCalendar = ({
    events,
    selectedYear,
    selectedMonth,
    getEventMarkerColor,
    handleCellClick,
    setSelectedYear,
    setSelectedMonth,
    handleIconClick,
    colorPalette,
}) => {
    const { width } = useWindowSize();
    const isMobile = width < 768;

    const [popupData, setPopupData] = useState(null);
    const popupRef = useRef(null);
    const sortedEvents = [...events].sort((a, b) => {
        const startA = parseTimeSlot(a.timeSlot).start;
        const startB = parseTimeSlot(b.timeSlot).start;
        return startA - startB;
    });
    // const parseTimeSlot2 = (timeSlot) => {
    //     // Assuming timeSlot is a string like '1:30 PM - 3:00 PM' or '13:30 - 15:00'
    //     const [startStr, endStr] = timeSlot.split(' - ');
    
    //     const parseTime = (timeStr) => {
    //         let time = timeStr.trim();
    //         let period = '';
    
    //         // Check for AM/PM format
    //         if (time.toLowerCase().includes('am') || time.toLowerCase().includes('pm')) {
    //             if (time.toLowerCase().includes('am')) {
    //                 period = 'AM';
    //                 time = time.toLowerCase().replace('am', '').trim();
    //             } else {
    //                 period = 'PM';
    //                 time = time.toLowerCase().replace('pm', '').trim();
    //             }
    //         }
    
    //         let [hours, minutes] = time.split(':').map(Number);
    //         if (isNaN(minutes)) {
    //             minutes = 0;
    //         }
    
    //         if (period === 'PM' && hours !== 12) {
    //             hours += 12;
    //         } else if (period === 'AM' && hours === 12) {
    //             hours = 0;
    //         }
    
    //         return hours + minutes / 60;
    //     };
    
    //     const start = parseTime(startStr);
    //     const end = parseTime(endStr);
    
    //     return { start, end };
    // };
    
    const renderDaySchedule = (events) => {
        // Create time slots for every 30 minutes
        const timeSlots = [];
        for (let hour = 0; hour < 24; hour++) {
            timeSlots.push({ time: hour, events: [] });     // For :00 minutes
            timeSlots.push({ time: hour + 0.5, events: [] }); // For :30 minutes
        }
    
        // Map events to their corresponding time slots
        events.forEach((event) => {
            const { start, end } = parseTimeSlot2(event.timeSlot);
    
            const startIndex = Math.floor(start * 2);
            const endIndex = Math.ceil(end * 2);
    
            for (let i = startIndex; i < endIndex; i++) {
                if (timeSlots[i]) {
                    timeSlots[i].events.push(event);
                }
            }
        });
    
        // Function to format time into 12-hour format
        const formatTime = (time) => {
            const hour = Math.floor(time);
            const minutes = time % 1 === 0 ? '00' : '30';
            const period = hour >= 12 ? 'PM' : 'AM';
            const hour12 = hour % 12 === 0 ? 12 : hour % 12;
            return `${hour12}:${minutes} ${period}`;
        };
    
        return (
            <div className="space-y-1">
                {timeSlots.map(({ time, events }, index) => (
                    <div key={index} className="flex items-start">
                        <div className="w-16 text-right pr-2 text-gray-600 text-xs md:text-xs">
                            {formatTime(time)}
                        </div>
                        <div className="flex-1 border-b border-gray-200 pb-2">
                            {events.length > 0 ? (
                                events.map((event, idx) => (
                                    <div
                                        key={idx}
                                        className="p-2 bg-blue-100 rounded mb-1 cursor-pointer hover:bg-blue-200"
                                        onClick={(e) => {
                                            handleIconClick(e, event);
                                            setPopupData(null);
                                        }}
                                    >
                                        <div className="text-xs font-medium">
                                            {event.title}
                                        </div>
                                        <div className="text-xs mt-1 text-gray-600">
                                            <FontAwesomeIcon icon={faClockFour} className="mr-1 text-gray-400" />
                                            {event.timeSlot}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="h-4"></div> 
                            )}
                        </div>
                    </div>
                ))}
            </div>
        );
    };
    
    

    useEffect(() => {
        if (popupData) {
            const handleClickOutside = (event) => {
                if (
                    popupRef.current &&
                    !popupRef.current.contains(event.target) &&
                    !event.target.classList.contains('more-indicator')
                ) {
                    setPopupData(null);
                }
            };
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [popupData]);

    useLayoutEffect(() => {
        if (popupData && popupData.needsAdjustment) {
            if (popupRef.current) {
                const popupRect = popupRef.current.getBoundingClientRect();
                const viewportWidth = window.innerWidth;
                const viewportHeight = window.innerHeight;

                let left = popupData.position.left;
                let top = popupData.position.top;

                if (left + popupRect.width > viewportWidth) {
                    left = viewportWidth - popupRect.width - 10;
                }
                if (left < 0) {
                    left = 10;
                }

                if (top + popupRect.height > viewportHeight) {
                    top = popupData.targetRect.top - popupRect.height;
                    if (top < 0) {
                        top = 10;
                    }
                }

                setPopupData({
                    ...popupData,
                    position: {
                        top,
                        left,
                    },
                    needsAdjustment: false,
                });
            }
        }
    }, [popupData]);

    const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
    const firstDay = new Date(selectedYear, selectedMonth, 1).getDay();
    const maxEvents = isMobile ? 1 : 2; // Maximum events to show in a cell

    const renderEventAbbreviations = (eventsForDay, isMobile, currentDate) => {
        const colorMapper = {};
        let colorIndex = 0;

        const visibleEvents = eventsForDay.slice(0, maxEvents);
        const remainingCount = eventsForDay.length - visibleEvents.length;

        return (
            <>
                {visibleEvents.map((event, index) => {
                    const { courseId } = event;
                    if (!colorMapper[courseId]) {
                        colorMapper[courseId] = colorPalette[colorIndex % colorPalette.length];
                        colorIndex++;
                    }
                    // Get the assigned color for the current courseId
                    const assignedColor = colorMapper[courseId];
                    const abbreviation = event.title
                        .split(' ')
                        .map((word) => word.charAt(0).toUpperCase())
                        .join('');

                    return (
                        <div
                            key={index}
                            className={`${assignedColor} ${
                                isMobile
                                    ? 'w-2 h-2 border-2 rounded-full cursor-pointer'
                                    : 'text-white text-xs font-semibold px-2 py-1 rounded mb-1 text-center'
                            } cursor-pointer`}
                            onClick={(e) => handleIconClick(e, event)}
                        >
                            {!isMobile && abbreviation}
                        </div>
                    );
                })}
                {remainingCount > 0 && (
                    <div
                        className="text-xs text-webDarker cursor-pointer more-indicator"
                        onClick={(e) => {
                            e.stopPropagation();
                            const rect = e.target.getBoundingClientRect();
                            setPopupData({
                                events: eventsForDay,
                                position: {
                                    top: rect.bottom,
                                    left: rect.left,
                                },
                                targetRect: rect,
                                needsAdjustment: true,
                            });
                        }}
                    >
                        +{remainingCount} more
                    </div>
                )}
            </>
        );
    };

    const calendarCells = [];
    for (let i = 0; i < firstDay; i++) {
        calendarCells.push(
            <td key={`empty-start-${i}`} className="border border-gray-200"></td>
        );
    }

    for (let day = 1; day <= daysInMonth; day++) {
        const currentDate = new Date(selectedYear, selectedMonth, day);
        const eventsForDay = sortedEvents.filter((event) => {
            const eventStartDate = new Date(event.parsedStartDate);
            const eventEndDate = new Date(event.parsedEndDate);
            return (
                (currentDate >= eventStartDate && currentDate <= eventEndDate) ||
                currentDate.toDateString() === eventStartDate.toDateString()
            );
        });

        let cellClass = 'bg-white';
        eventsForDay.forEach((event) => {
            const className = getEventMarkerColor(event, currentDate);
            if (className) {
                cellClass = className;
            }
        });

        const isToday = currentDate.toDateString() === new Date().toDateString();
        calendarCells.push(
            <td
                key={day}
                className={`h-20 w-16 md:h-32 md:w-32 lg:h-40 lg:w-40 border border-gray-200 ${
                    isToday ? 'bg-blue-200' : ''
                } ${cellClass} relative`}
                onClick={() => handleCellClick(day)}
            >
                <span
                    className={`absolute top-1 left-1 text-xs md:text-sm lg:text-xl font-normal p-4 text-gray-800 ${
                        isToday ? 'underline text-webDarker' : ''
                    }`}
                >
                    {day}
                </span>
                <div className="absolute bottom-1 left-1 space-y-1 md:space-y-2 lg:space-y-3">
                    {renderEventAbbreviations(eventsForDay, isMobile, currentDate)}
                </div>
            </td>
        );
    }

    while (calendarCells.length % 7 !== 0) {
        calendarCells.push(
            <td
                key={`empty-end-${calendarCells.length}`}
                className="border border-transparent"
            ></td>
        );
    }

    const weeks = [];
    for (let i = 0; i < calendarCells.length; i += 7) {
        weeks.push(<tr key={`week-${i / 7}`}>{calendarCells.slice(i, i + 7)}</tr>);
    }

    return (
        <div className="p-0 bg-white rounded-lg relative">
            {isMobile ? (
                // Mobile Design
                <div className="flex items-center justify-between mb-4">
                    <button
                        onClick={() => {
                            if (selectedMonth === 0) {
                                setSelectedYear(selectedYear - 1);
                                setSelectedMonth(11);
                            } else {
                                setSelectedMonth(selectedMonth - 1);
                            }
                        }}
                        className="flex items-center justify-center w-10 h-10 text-textGray bg-gray-100 rounded-full hover:bg-gray-200 focus:outline-none"
                    >
                        <FontAwesomeIcon icon={faAngleLeft}/>
                    </button>
                    <div className="text-center">
                        <span className="text-lg font-medium text-text">
                            {new Date(selectedYear, selectedMonth).toLocaleString('default', {
                                month: 'long',
                            })}
                        </span>
                        <br />
                        <span className="text-sm text-textGray">{selectedYear}</span>
                    </div>
                    <button
                        onClick={() => {
                            if (selectedMonth === 11) {
                                setSelectedYear(selectedYear + 1);
                                setSelectedMonth(0);
                            } else {
                                setSelectedMonth(selectedMonth + 1);
                            }
                        }}
                        className="flex items-center justify-center w-10 h-10 text-textGray bg-gray-100 rounded-full hover:bg-gray-200 focus:outline-none"
                    >
                        
                        <FontAwesomeIcon icon={faAngleRight}/>
                    </button>
                </div>
            ) : (
                // Desktop Design
                <div className="flex items-center justify-items-start space-x-8 p-4 mb-4">
                    <div className="flex items-center justify-center">
                        <button
                            onClick={() => setSelectedYear(selectedYear - 1)}
                            className="text-gray-500 hover:text-gray-700 focus:outline-none"
                        >
                            <FontAwesomeIcon icon={faAngleLeft}/>
                        </button>
                        <span className="mx-2 text-3xl px-4 font-medium text-text">
                            {selectedYear}
                        </span>
                        <button
                            onClick={() => setSelectedYear(selectedYear + 1)}
                            className="text-gray-500 hover:text-gray-700 focus:outline-none"
                        >
                            <FontAwesomeIcon icon={faAngleRight}/>
                        </button>
                    </div>
                    <div className="flex items-center">
                        <button
                            onClick={() => {
                                if (selectedMonth === 0) {
                                    setSelectedYear(selectedYear - 1);
                                    setSelectedMonth(11);
                                } else {
                                    setSelectedMonth(selectedMonth - 1);
                                }
                            }}
                            className="text-textGray hover:text-gray-700 focus:outline-none"
                        >
                            <FontAwesomeIcon icon={faAngleLeft}/>
                        </button>
                        <span className="mx-2 text-3xl px-4 font-medium text-text">
                            {new Date(selectedYear, selectedMonth).toLocaleString('default', {
                                month: 'long',
                            })}
                        </span>
                        <button
                            onClick={() => {
                                if (selectedMonth === 11) {
                                    setSelectedYear(selectedYear + 1);
                                    setSelectedMonth(0);
                                } else {
                                    setSelectedMonth(selectedMonth + 1);
                                }
                            }}
                            className="text-textGray hover:text-gray-700 focus:outline-none"
                        >
                            <FontAwesomeIcon icon={faAngleRight}/>
                        </button>
                    </div>
                </div>
            )}
            <table className="w-full table-fixed border-collapse">
                <thead>
                    <tr>
                        {['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'].map((day) => (
                            <th
                                key={day}
                                className="w-1/7 text-sm md:text-base text-start md:p-4 border border-gray-200 font-light text-gray-600"
                            >
                                {day}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>{weeks}</tbody>
            </table>
            {/* Popup for showing all events of a particular day */}
            {popupData &&
                createPortal(
                    <div
                        ref={popupRef}
                        className="fixed bg-white border border-gray-300 rounded-lg shadow-lg"
                        style={{
                            top: popupData.position.top,
                            left: popupData.position.left,
                            minWidth: '300px',
                            zIndex: 1000,
                        }}
                    >
                        <div className="p-4">
                            <h3 className="text-lg font-normal mb-4 flex flex-row">
                                Schedule for{' '}
                                <div className='text-lg ml-4 text-textGray'>
                                    <FontAwesomeIcon icon={faCalendarAlt} className='mr-1' />
                                    {new Date(
                                        popupData.events[0].parsedStartDate
                                    ).toLocaleDateString()}
                                </div>
                            </h3>
                            <div className="space-y-2 max-h-64 overflow-y-auto">
                                {renderDaySchedule(popupData.events)}
                            </div>
                        </div>
                    </div>,
                    document.body
                )}
        </div>
    );
};




