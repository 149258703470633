import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { RefundButton } from './Utility';
import CourseImage from '../../utilities/getImageWithDiv';
import ReviewModal from '../../components/WriteReview/ReviewModal';
import { submitReview, clearSubmitState } from '../../redux/slices/reviewsSlice';


export const OrderHistory = ({ orders, user }) => {
    // Helper function to transform order ID to a 16-digit string
    function transformTo16DigitString(inputString) {
        let asciiString = inputString
            .split('')
            .map((char) => char.charCodeAt(0))
            .join('');

        if (asciiString.length > 16) {
            asciiString = asciiString.substring(0, 16);
        }

        while (asciiString.length < 16) {
            asciiString += '0';
        }

        return asciiString;
    }

    // Get courses from Redux store using useSelector
    const courses = useSelector((state) => state.courses.courses);

    // State to map course IDs to course titles
    const [courseTitles, setCourseTitles] = useState({});

    const [isReviewModalOpen, setReviewModalOpen] = useState(false);
    const [currentCourse, setCurrentCourse] = useState(null);
    const [instructors, setInstructors] = useState(null);
    const dispatch = useDispatch();

    const openReviewModal = (courseMappedObj) => {
        console.log("courseMappedObj:", courseMappedObj);
        setCurrentCourse(courseMappedObj);
        setInstructors(courseMappedObj.instructors);
        setReviewModalOpen(true);
    };

    // Function to close the review modal
    const closeReviewModal = () => {
        setReviewModalOpen(false);
        setCurrentCourse(null);
        setInstructors(null);
        dispatch(clearSubmitState());
    };

    const handleReviewSubmit = (reviewData) => {
        console.log("handleReviewSubmit:", reviewData);
        dispatch(submitReview(reviewData)).then(() => {
            closeReviewModal();
        });
    };


    useEffect(() => {
        const titles = {};
        if (courses) {
            courses.forEach((course) => {
                titles[course.id] = course.title || 'Unknown Course';
            });
            setCourseTitles(titles);
        }
    }, [courses]);

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
        return date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    };

    const getImageId = (courseId) => {
        const course = courses.find((course) => course.id === courseId);
        if (course) {
            return course.imageID;
        }
    };

    const getReduxCourse = (courseId) => {
        const course = courses.find((course) => course.id === courseId);
        if (course) {
            return course;
        }
    };

    return (
        <div className="order-history-container w-full mx-auto py-2 bg-none rounded-lg">
            {/* Display orders */}
            {orders && Object.keys(orders).length > 0 ? (
                Object.keys(orders).map((orderId) => {
                    const order = orders[orderId];
                    const totalQuantity = order.courses.length;
                    const totalAmount = order.courses.reduce(
                        (acc, course) => acc + (course.amount || order.amount / 100),
                        0
                    );

                    // Create mappers for images and Redux course data
                    const imageMapper = {};
                    const reduxCourseMapper = {};
                    order.courses.forEach((course) => {
                        const imageID = getImageId(course.courseId);
                        const reduxCourse = getReduxCourse(course.courseId);
                        imageMapper[course.courseId] = imageID;
                        reduxCourseMapper[course.courseId] = reduxCourse;
                    });

                    return (
                        <div key={orderId} className="p-4 mb-6 border border-gray-200 rounded-lg shadow-md bg-white">
                            {/* Order Date and ID (Mobile) */}
                            <div className="md:hidden flex flex-col justify-start items-start mb-4 space-y-2">
                                <div>
                                    <h4 className="text-textGray text-sm">Order placed</h4>
                                    <p className="text-lg font-medium">{formatDate(order.created)}</p>
                                </div>
                                <div>
                                    <h4 className="text-textGray text-sm">Total</h4>
                                    <p className="text-lg font-medium">${totalAmount}</p>
                                </div>
                                <div>
                                    <h4 className="text-textGray text-sm">Qty</h4>
                                    <p className="text-lg font-medium">{totalQuantity}</p>
                                </div>
                                <div>
                                    <h4 className="text-textGray text-sm">Order ID</h4>
                                    <p className="text-lg font-medium">#{transformTo16DigitString(orderId)}</p>
                                </div>
                            </div>
                            {/* Order Date and ID (Desktop) */}
                            <div className="hidden md:flex flex-col md:flex-row justify-between items-start md:items-center mb-6 space-y-2 md:space-y-0">
                                <div className="w-full md:w-auto flex justify-between items-center md:items-start space-x-4">
                                    <div>
                                        <h4 className="text-textGray text-sm mb-2">Order placed</h4>
                                        <p className="text-xl font-medium">{formatDate(order.created)}</p>
                                    </div>
                                    <div>
                                        <h4 className="text-textGray text-sm mb-2">Total</h4>
                                        <p className="text-xl font-medium">${totalAmount}</p>
                                    </div>
                                </div>
                                <div className="w-full md:w-auto flex justify-between items-center md:items-start space-x-4">
                                    <div>
                                        <h4 className="text-textGray text-sm mb-2">Qty</h4>
                                        <p className="text-xl font-medium">{totalQuantity}</p>
                                    </div>
                                    <div>
                                        <h4 className="text-textGray text-sm mb-2">Order ID</h4>
                                        <p className="text-xl font-medium">#{transformTo16DigitString(orderId)}</p>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-4" />
                            {/* Courses List */}
                            <div className="space-y-4">
                                {order.courses.map((course, index) => (
                                    <div
                                        key={index}
                                        className="flex flex-col md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-4 p-4 rounded-md bg-gray-50"
                                    >
                                        {/* Course Image */}
                                        <div className="rounded-lg overflow-hidden w-full h-40 md:w-32 md:h-32">
                                            <CourseImage imagePath={imageMapper[course.courseId]} />
                                        </div>
                                        {/* Course Details */}
                                        <div className="flex-grow space-y-2">
                                            <h3 className="text-gray-800 font-semibold text-lg">
                                                {courseTitles[course.courseId] || 'Unknown Course'}
                                            </h3>
                                            {/* Hardcoded Date and Days Info */}
                                            <span className="block text-gray-600 text-xs">{getReduxCourse(course.courseId)?.duration}</span>
                                            <span className="block text-gray-600 text-xs mb-2">{course.timeSlot}</span>
                                            {/* Action Buttons */}
                                            <div className="flex flex-col py-2 md:flex-row items-start md:items-center space-y-4 md:space-y-0 md:space-x-2 mt-2">
                                                <Link
                                                    to={`/courses/${course.courseId}`}
                                                    state={{ course: reduxCourseMapper[course.courseId] }}
                                                    className="text-white text-sm flex items-center bg-webDarker py-2 px-4 rounded-full transition duration-300 ease-in-out hover:bg-hoverColor"
                                                >
                                                    <FontAwesomeIcon icon={faRotate} size='sm' className='mr-2'/><span>Buy it again</span>
                                                </Link>
                                                <button 
                                                    className=" text-gray-700 text-sm border border-gray-300 py-2 px-4 
                                                    rounded-full transition duration-300 ease-in-out hover:border hover:border-gray-700"
                                                    onClick={() => openReviewModal(reduxCourseMapper[course.courseId])}
                                                >
                                                    Write Review
                                                </button>
                                                <RefundButton user={user} order={order} />
                                            </div>
                                        </div>
                                        {/* Course Price */}
                                        <div className="text-right w-full md:w-auto">
                                            <p className="text-lg text-gray-800 font-normal">
                                                ${course.amount || order.amount / 100}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    );
                })
            ) : (
                <div className="text-center py-6">
                    <span className="text-gray-500 text-lg">No orders found</span>
                </div>
            )}
            {/* Review Modal */}
            {isReviewModalOpen && (
                <ReviewModal
                    course={currentCourse}
                    instructors={instructors}
                    onClose={closeReviewModal}
                    onSubmit={handleReviewSubmit}
                />
            )}
        </div>
    );
};
