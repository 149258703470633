const RescheduleRequestCard = ({ request, expandedRequestId, toggleDetails, handleApprove, handleReject }) => (
    <div key={request.id} className="bg-white shadow-md rounded-lg p-6">
      {/* Collapsible Header */}
      <div className="flex justify-between items-center">
        <div>
          <p><span className="font-bold">Request ID:</span> {request.id}</p>
          <p><span className="font-bold">User Name:</span> {request.pendingUserDetailsUpdate?.name}</p>
          <p><span className="font-bold">Course Name:</span> {request.rescheduleRecord.courseName}</p>
          <p><span className="font-bold">Status:</span> {request.status}</p>
          <p><span className="font-bold">Date Requested:</span> {new Date(request.dateRequested).toLocaleString()}</p>
        </div>
  
        {/* Toggle Button */}
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
          onClick={() => toggleDetails(request.id)}
        >
          {expandedRequestId === request.id ? 'Hide Details' : 'Show Details'}
        </button>
      </div>
  
      {/* Conditionally Render Detailed View */}
      {expandedRequestId === request.id && (
        <div className="mt-4">
          {/* User Information Section */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2 text-gray-700">User Information</h3>
            <p><span className="font-bold">Email:</span> {request.pendingUserDetailsUpdate?.email}</p>
            <p><span className="font-bold">Phone Number:</span> {request.pendingUserDetailsUpdate?.phoneNumber}</p>
            <p><span className="font-bold">Gender:</span> {request.pendingUserDetailsUpdate?.gender}</p>
            <p><span className="font-bold">Programming Fluency:</span> {request.pendingUserDetailsUpdate?.programmingFluency}</p>
          </div>
  
          {/* Reschedule Information Section */}
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2 text-gray-700">Reschedule Information</h3>
            <p><span className="font-bold">Old Session ID:</span> {request.rescheduleRecord.oldSessionId}</p>
            <p><span className="font-bold">New Session ID:</span> {request.rescheduleRecord.newSessionId}</p>
            <p><span className="font-bold">Old Time Slot:</span> {request.rescheduleRecord.oldTimeSlot}</p>
            <p><span className="font-bold">New Time Slot:</span> {request.rescheduleRecord.newTimeSlot}</p>
          </div>
  
          {/* Approve/Reject Buttons for Pending Requests */}
          {request.status === 'pending' && (
            <div className="mt-4 flex justify-end space-x-4">
              <button 
                onClick={() => handleApprove(request)}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition"
              >
                Approve
              </button>
              <button 
                onClick={() => handleReject(request)}
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition"
              >
                Reject
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
  
  // Layout component for a section (Pending, Approved, Rejected)
  export const RequestSection = ({ title, requests, expandedRequestId, toggleDetails, handleApprove, handleReject }) => (
    <div className="space-y-4">
      <h2 className="text-2xl font-semibold mb-4 text-gray-700">{title}</h2>
      {requests.length > 0 ? (
        requests.map((request) => (
          <RescheduleRequestCard
            key={request.id}
            request={request}
            expandedRequestId={expandedRequestId}
            toggleDetails={toggleDetails}
            handleApprove={handleApprove}
            handleReject={handleReject}
          />
        ))
      ) : (
        <p className="text-gray-500">No {title.toLowerCase()} requests found.</p>
      )}
    </div>
  );