import {collection, addDoc } from 'firebase/firestore';
import { db } from '../../api/firebaseConfig';

export const RefundButton = ({ order, user }) => {
    const handleRefundRequest = async () => {
        try {
            const refundRequest = {
                userName: user.name,
                userEmail: user.email,
                orderRequested: order,
            };
            const docRef = await addDoc(collection(db, "refundRequests"), refundRequest);
            alert("Refund request submitted successfully!");
            console.log('Document written with ID: ', docRef.id);
        } catch (error) {
            console.error('Error adding document: ', error);
        }
    };

    return (
        <button
            className=" text-gray-700 text-sm border border-gray-300 py-2 px-4 rounded-full transition duration-300 ease-in-out hover:border hover:border-gray-700"
            onClick={handleRefundRequest}
        >
            Request Refund
        </button>
    );
};



const normalizeDate = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const getPastEvents = (courses) => {
    const now = new Date();
    return courses.filter(course => {
        const { parsedEndDate } = course;
        const courseEndDate = normalizeDate(parsedEndDate);
        const currentDate = normalizeDate(now);
        return courseEndDate < currentDate;
    });
};

export const getOngoingEvents = (courses) => {
    const now = new Date();
    return courses.filter(course => {
        const { parsedStartDate, parsedEndDate } = course;
        const courseStartDate = normalizeDate(parsedStartDate);
        const courseEndDate = normalizeDate(parsedEndDate);
        const currentDate = normalizeDate(now);
        return courseStartDate <= currentDate && courseEndDate >= currentDate;
    });
};

export const getUpcomingEvents = (courses) => {
    const now = new Date();
    const threeDaysFromNow = new Date(now);
    threeDaysFromNow.setDate(now.getDate() + 3);

    return courses.filter(course => {
        const { parsedStartDate } = course;
        const courseStartDate = normalizeDate(parsedStartDate);
        const currentDate = normalizeDate(now);
        const upcomingDate = normalizeDate(threeDaysFromNow);
        return courseStartDate > currentDate && courseStartDate <= upcomingDate;
    });
};

export const getFutureEvents = (courses) => {
    const now = new Date();
    const threeDaysFromNow = new Date(now);
    threeDaysFromNow.setDate(now.getDate() + 3);

    return courses.filter(course => {
        const { parsedStartDate } = course;
        const courseStartDate = normalizeDate(parsedStartDate);
        const futureDate = normalizeDate(threeDaysFromNow);
        return courseStartDate > futureDate;
    });
};


export const parseTimeSlot = (timeSlot) => {
    const [startTime, endTime] = timeSlot.split(" - ");

    const to24HourDate = (timeString) => {
        let [time, period] = timeString.split(" ");
        let [hours, minutes] = time.split(":").map(Number);

        if (period === "PM" && hours !== 12) hours += 12;
        if (period === "AM" && hours === 12) hours = 0;

        const date = new Date();
        date.setHours(hours, minutes, 0, 0);
        return date;
    };

    return { start: to24HourDate(startTime), end: to24HourDate(endTime) };
};

export const parseTimeSlot2 = (timeSlot) => {
    // Assuming timeSlot is a string like '1:30 PM - 3:00 PM' or '13:30 - 15:00'
    const [startStr, endStr] = timeSlot.split(' - ');

    const parseTime = (timeStr) => {
        let time = timeStr.trim();
        let period = '';

        // Check for AM/PM format
        if (time.toLowerCase().includes('am') || time.toLowerCase().includes('pm')) {
            if (time.toLowerCase().includes('am')) {
                period = 'AM';
                time = time.toLowerCase().replace('am', '').trim();
            } else {
                period = 'PM';
                time = time.toLowerCase().replace('pm', '').trim();
            }
        }

        let [hours, minutes] = time.split(':').map(Number);
        if (isNaN(minutes)) {
            minutes = 0;
        }

        if (period === 'PM' && hours !== 12) {
            hours += 12;
        } else if (period === 'AM' && hours === 12) {
            hours = 0;
        }

        return hours + minutes / 60;
    };

    const start = parseTime(startStr);
    const end = parseTime(endStr);

    return { start, end };
};


