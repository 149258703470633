import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import CourseImage from '../../utilities/getImageWithDiv';

function CoursePreview({ course }) {
    // crops out the description to only 150 words and stuff.
    const shortDescription = course.description.split(" ").slice(0, 20).join(" ") + "...";
    return (
        <Link to={`/courses/${course.id}`} className="block text-inherit bg-none h-full">
            <div className="flex flex-col rounded-2xl overflow-hidden w-full h-full animate-slideDownPositionLow transition-transform transform hover:-translate-y-2 shadow-custom-medium  relative">
                
                {/* Top left corner: Tags */}
                <div className="absolute top-4 left-4 flex gap-2">
                    {course.category.map((cat, index) => (
                        <span key={index} className="bg-white/30 backdrop-blur-lg text-white rounded-md px-3 py-1 text-sm font-medium shadow-md">
                            {cat}
                        </span>
                    ))}
                </div>

                {/* Course Image */}
                <CourseImage imagePath={course.imageID} imageWidth="w-full" imageHeight="h-[192px]"/>
                <div className="p-4 flex flex-col justify-between flex-grow bg-white">
                    {/* Course Title */}
                    <h3 className="text-lg md:text-xl font-medium text-gray-900 mb-2">{course.title}</h3>

                    {/* Course Details */}
                    <div className="flex flex-col gap-2 text-gray-600 mb-4">
                        <div className="flex items-center text-sm">
                            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2 text-blue-600" />
                            {course?.duration}
                        </div>
                        <div className="flex items-center text-sm">
                            <FontAwesomeIcon icon={faUsers} className="mr-2 text-blue-600" />
                            {course?.styleFormat}
                        </div>
                    </div>

                    {/* Short Description */}
                    <p className="text-sm text-gray-500 mb-4 line-clamp-3">{shortDescription}</p>
                </div>

                {/* Bottom: Instructor Information */}
                <div className="p-4 border-t border-gray-200 bg-gray-50">
                    {course.instructors?.length > 0 && (
                        <div className="space-y-4">
                            {course.instructors.map((instructor, index) => (
                                <div key={index} className="flex items-center">
                                    <CourseImage imagePath={instructor.imageUrl} imageWidth={'w-8'} imageHeight={'h-8'} rounded={'rounded-full'}/>
                                    <div className='flex flex-row justify-between w-full ml-3'>
                                        <h4 className="text-sm font-medium text-black">{instructor.name}</h4>
                                        <p className="text-xs text-textGray">{instructor.bio}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </Link>
    );
}

export default CoursePreview;
