import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as fullStar, faStarHalfAlt as halfStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons';

const StarRating = ({ rating }) => {
    const totalStars = 5;
    const fullStars = Math.floor(rating); // Full stars
    const hasHalfStar = rating % 1 !== 0; // Check if there's a decimal part for half star

    return (
        <div className="flex items-center">
            {/* Full stars */}
            {Array(fullStars).fill(0).map((_, i) => (
                <FontAwesomeIcon key={i} icon={fullStar} className="text-[#69D4DD] text-xl" />
            ))}
            {/* Half star */}
            {hasHalfStar && (
                <FontAwesomeIcon icon={halfStar} className="text-[#69D4DD] text-xl" />
            )}
            {/* Empty stars */}
            {Array(totalStars - fullStars - (hasHalfStar ? 1 : 0)).fill(0).map((_, i) => (
                <FontAwesomeIcon key={i} icon={emptyStar} className="text-gray-300 text-xl" />
            ))}
        </div>
    );
};

export default StarRating;
