import React from 'react';
import getImage from '../../../utilities/getImage';

const CourseDetailsHero = ({ course }) => {
    const { title, description, imageID} = course;
    const image = getImage({ imagePath: imageID });

    const splitTitle = (title) => {
        if (!title) return '';
        return title.includes(':')
            ? title.split(':').map((part, index) => (
                  <span key={index}>
                      {part}
                      {index === 0 && ':'}
                      <br />
                  </span>
              ))
            : title;
    };

    return (
        <section
            style={{
                position: 'relative',
                backgroundImage: image ? `url(${image})` : 'none',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '450px',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
            }}
            className="course-hero"
        >
            {/* Overlay */}
            <div className="absolute inset-0 bg-black bg-opacity-50"></div>

            {/* Content */}
            <div className="relative z-10 flex flex-col mb-2 justify-center pl-4 md:pl-16 w-full lg:w-1/2 h-full">
                <h1 className="text-white text-2xl md:text-4xl font-normal mb-4 md:mb-6">
                    {splitTitle(title)}
                </h1>
                <p className="text-white text-sm md:text-lg opacity-75 font-light w-full md:w-3/4 mb-4">
                    {description}
                </p>
            </div>

           
        </section>
    );
};

export default CourseDetailsHero;
