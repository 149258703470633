import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CoursePreview from '../../components/CoursePreview/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheckCircle,faSearch, faBookOpen, faWrench } from '@fortawesome/free-solid-svg-icons';
import SearchBar from '../../components/SearchBar/index';
import Footer from '../../components/Footer';
import Fuse from 'fuse.js';
import { analytics } from '../../api/firebaseConfig';
import { logEvent } from 'firebase/analytics';

function CoursePage() {
  const { courses, status, error } = useSelector((state) => state.courses);
  const [searchResult, setSearchResult] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedPriceRange, setSelectedPriceRange] = useState('');

  useEffect(() => {
    if (status === 'succeeded') {
      const options = {
        keys: ['title', 'description', 'instructor'],
        includeScore: true,
        threshold: 0.4,
      };
      const fuse = new Fuse(courses, options);

      let result = courses;

      // Apply search term filter
      if (searchTerm !== '') {
        result = fuse.search(searchTerm).map(({ item }) => item);
        logEvent(analytics, 'search', { search_term: searchTerm });
      }

      // Apply category filter
      if (selectedCategory) {
        result = result.filter((course) => course.category.includes(selectedCategory));
        logEvent(analytics, 'filter_category', { category: selectedCategory });
      }

      // Apply price range filter
      if (selectedPriceRange) {
        const [minPrice, maxPrice] = selectedPriceRange.split('-').map(Number);
        result = result.filter((course) => course.price >= minPrice && course.price <= maxPrice);
        logEvent(analytics, 'filter_price', { price_range: selectedPriceRange });
      }

      setSearchResult(result);
    }
  }, [courses, searchTerm, selectedCategory, selectedPriceRange, status]);

  
  const renderSloganGrid = () => {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-12">
        <div className="flex flex-col items-center justify-center p-4 border-r border-gray-300 last:border-none">
          <FontAwesomeIcon icon={faSearch} className="text-blue-600 text-4xl mb-4" />
          <span className="text-lg font-semibold text-gray-800 text-center">Find Your Course</span>
        </div>
        <div className="flex flex-col items-center justify-center p-4 border-r border-gray-300 last:border-none">
          <FontAwesomeIcon icon={faBookOpen} className="text-blue-600 text-4xl mb-4" />
          <span className="text-lg font-semibold text-gray-800 text-center">Tailor Your Learning</span>
        </div>
        <div className="flex flex-col items-center justify-center p-4 border-r border-gray-300 last:border-none">
          <FontAwesomeIcon icon={faWrench} className="text-blue-600 text-4xl mb-4" />
          <span className="text-lg font-semibold text-gray-800 text-center">Customize Learning</span>
        </div>
        <div className="flex flex-col items-center justify-center p-4">
          <FontAwesomeIcon icon={faCheckCircle} className="text-blue-600 text-4xl mb-4" />
          <span className="text-lg font-semibold text-gray-800 text-center">Achieve Your Goals</span>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen flex flex-col">
      <main className="flex-grow">
        {/* Hero Section */}
        <div className="bg-none py-10">
          <header className="flex flex-col md:flex-row items-center justify-center mb-10 px-4 md:px-10 text-center md:text-left max-w-7xl mx-auto">
            <div>
              <h1 className="text-4xl md:text-5xl font-medium text-webDarker text-center">Explore Our Courses</h1>
              <section className="mt-4">
                <p className="text-lg text-textGray text-center max-w-screen-md">
                Discover the perfect course to reach your academic goals! 
                Whether you want to boost your grades, learn a new language, 
                or prepare for exams, explore our range of expertly designed 
                courses tailored for high school students. Find the right path 
                for you and start learning today!
                </p>
              </section>
            </div>
          </header>
        </div>

        {/* Main Content Wrapper */}
        <div className="px-4 md:px-10 max-w-7xl mx-auto">
          {/* Slogan Section */}
          {renderSloganGrid()}

          {/* Search and Select Section */}
          <div className="flex flex-col lg:flex-row justify-between items-center mb-10">
            <div className="flex gap-4 w-full lg:w-auto">
              <select
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                className="p-2 border border-gray-300 rounded-lg text-gray-700 w-full lg:w-auto"
              >
                <option value="">All Categories</option>
                <option value="Entrepreneur">Entrepreneur</option>
                <option value="Innovation">Innovation</option>
                <option value="Startup">Startup</option>
                <option value="AI Tools">AI Tools</option>
                <option value="The Future">The Future</option>
                <option value="AI">AI</option>
                <option value="Job Ready">Job Ready</option>
                <option value="Projects">Projects</option>
                <option value="Website">Website</option>
              </select>
              <select
                value={selectedPriceRange}
                onChange={(e) => setSelectedPriceRange(e.target.value)}
                className="p-2 border border-gray-300 rounded-lg text-gray-700 w-full lg:w-auto"
              >
                <option value="">All Prices</option>
                <option value="100-400">$100 - 400</option>
                <option value="400-1000">$400 - 1000</option>
                <option value="1000-2000">$1000 - 2000</option>
              </select>
            </div>
            <div className="w-full lg:w-auto mt-4 lg:mt-0">
              <SearchBar onSearchChange={setSearchTerm} placeholder="Search Courses..." delay={500} className="w-full lg:w-auto" />
            </div>
          </div>

          {/* Course Grid Section */}
          <div className="text-center mb-6">
            <p className="text-lg text-gray-800">Showing {searchResult.length} results</p>
          </div>
          {status === 'loading' ? (
            <div className="flex justify-center items-center py-10">
              <FontAwesomeIcon icon={faSpinner} className="text-4xl text-webDarker animate-spin" />
            </div>
          ) : error ? (
            <p className="text-red-400 text-center">Error: {error}</p>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-8 mb-10 auto-rows-fr">
              {searchResult.map((course) => (
                <div key={course.id} className="rounded-lg animate-blurIn">
                  <CoursePreview course={course} />
                </div>
              ))}
            </div>
          )}
        </div>
      </main>
      <Footer />
    </div>
  );
}

export default React.memo(CoursePage);
