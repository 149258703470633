import React, { useEffect, useState } from 'react';

const TrackerBar = ({ startDate, endDate, sampleSchedule }) => {
  const [currentPosition, setCurrentPosition] = useState(0);
  const [sortedWeeks, setSortedWeeks] = useState([]);

  useEffect(() => {
    const weeks = Object.keys(sampleSchedule).sort((a, b) => {
      const weekA = parseInt(a.split(' ')[1], 10);
      const weekB = parseInt(b.split(' ')[1], 10);
      return weekA - weekB;
    });

    setSortedWeeks(weeks);
    const now = new Date();
    const totalDuration = new Date(endDate) - new Date(startDate);
    const elapsedTime = now - new Date(startDate);
    const progressPercentage = Math.min(elapsedTime / totalDuration, 1) * 100;

    setCurrentPosition(progressPercentage);
  }, [startDate, endDate, sampleSchedule]);

  return (
    <div className="flex items-center space-x-4 mt-6">
      <span className="text-xs text-textGray">{new Date(startDate).toLocaleDateString()}</span>
      <div className="flex-1 h-2 bg-blue-100 rounded-full relative">
        <div
          className="absolute left-0 top-0 h-full bg-blue-900 rounded-full transition-width duration-500 z-5"
          style={{ width: `${currentPosition}%` }}
        />
        <div className="absolute inset-0 flex justify-between z-10">
          {sortedWeeks.map((week, index) => (
            <div key={index} className="relative flex flex-col items-center w-full">
              <div className={`w-2 h-2 rounded-full bg-gray-500`} />
              <span className="text-xs mt-2">
                <span className="hidden sm:inline">{week}</span>
                <span className="sm:hidden">{`W${index + 1}`}</span>
              </span>
            </div>
          ))}
        </div>
        <div
          className="absolute z-20"
          style={{
            left: `${currentPosition}%`,
            top: '-3px',
            transform: 'translateX(-50%)',
          }}
        >
          <div className="w-3 h-3 rounded-full bg-blue-500 border-2 border-blue-500 animate-beating"></div>
        </div>
      </div>
      <span className="text-xs text-textGray">{new Date(endDate).toLocaleDateString()}</span>
    </div>
  );
};

export default TrackerBar;
