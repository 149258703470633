// NotFound.jsx
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFrown, faHome } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex flex-col items-center justify-center text-center bg-blue-50 relative overflow-hidden">
      {/* Decorative Background Shape */}
      <div
        className="absolute inset-0 bg-no-repeat bg-center bg-cover opacity-20 animate-blurIn"
      ></div>

      {/* Content Container */}
      <div className="relative flex flex-col items-center z-10 bg-secondaryBackground bg-opacity-80 backdrop-filter backdrop-blur-lg p-8 rounded-lg shadow-custom-deep max-w-md w-full animate-fadeInUp">
        {/* Icon */}
        <FontAwesomeIcon
          icon={faFrown}
          className="text-6xl text-accent mb-4 animate-beating"
          aria-hidden="true"
        />

        {/* Title */}
        <h1 className="text-6xl font-extrabold text-textGray mb-4 animate-zoomIn">404</h1>

        {/* Message */}
        <p className="text-xl text-neutral mb-6">
          Oops! The page you're looking for isn't here.
        </p>
        <p className="text-textGray mb-6">
          You might have the wrong address, or the page may have moved.
        </p>

        {/* Action Button */}
        <button
          onClick={() => navigate('/')}
          className="flex items-center justify-center bg-button text-white font-semibold py-3 px-6 rounded-full shadow-custom-medium hover:bg-hoverColor transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50"
          aria-label="Navigate to Home Page"
        >
          <FontAwesomeIcon icon={faHome} className="mr-2 animate-slideIconInLeft" />
          Take Me Home
        </button>
      </div>
    </div>
  );
}

export default React.memo(NotFound);
