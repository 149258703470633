import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Andrew from '../../assets/img/Andrew.png';
import Aryaman from '../../assets/img/AryamanDubeyImg.jpg';
import Reece from '../../assets/img/ReeceRiherdImg.jpg';
import Steven from '../../assets/img/stevenImg.jpg';

const testimonials = [
  {
    quote: "Innovation is the outcome of a habit, not a random act.",
    author: "Aryaman Dubey",
    role: "Ex-Goldman Sachs, Tesla Engineer",
    image: Aryaman
  },
  {
    quote: "The advance of technology is based on making it fit in so that you don't really even notice it, so it's part of everyday life.",
    author: "Reece Riherd",
    role: "Ex-Uber, Goldman Sachs Engineer",
    image: Reece
  },
  {
    quote: "The technology you use impresses no one. The experience you create with it is everything.",
    author: "Steven Liu",
    role: "Albuddy Lead Developer",
    image: Steven
  },
  {
    quote: "Once a new technology rolls over you, if you're not part of the steamroller, you're part of the road.",
    author: "Andrew",
    role: "Ex-Meta Engineer",
    image: Andrew
  },
];

function Testimonials() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [direction, setDirection] = useState('');
  const [animationClass, setAnimationClass] = useState('');
  const totalSets = Math.ceil(testimonials.length / 3);

  const nextTestimonials = () => {
    setDirection('right');
    setActiveIndex((prevIndex) => (prevIndex + 1) % totalSets);
  };

  const prevTestimonials = () => {
    setDirection('left');
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? totalSets - 1 : prevIndex - 1
    );
  };

  const goToTestimonialSet = (index) => {
    setDirection(index > activeIndex ? 'right' : 'left');
    setActiveIndex(index);
  };

  useEffect(() => {
    if (direction) {
      const animation = direction === 'right' ? 'animate-slideInRight' : 'animate-slideInLeft';
      setAnimationClass(animation);

      const timeoutId = setTimeout(() => {
        setAnimationClass('');
      }, 600);

      return () => clearTimeout(timeoutId);
    }
  }, [direction, activeIndex]);

  const displayedTestimonials = testimonials.slice(
    activeIndex * 3,
    activeIndex * 3 + 3
  );

  return (
    <section id='mot' className="text-center font-sans py-12 bg-white">
      <h2 className="text-3xl font-medium mb-12">Meet Our Tutors</h2>
      <div className="flex items-center justify-center relative gap-12 md:gap-20">
        {totalSets > 1 && (
          <FontAwesomeIcon
            icon={faChevronLeft}
            className="text-2xl text-gray-600 cursor-pointer hidden md:block hover:text-hoverColor transform transition-transform duration-300 hover:scale-150"
            onClick={prevTestimonials}
            aria-label="Previous testimonials"
          />
        )}
        <div className={`flex flex-col md:flex-row items-center gap-6 transition-all duration-500 ${animationClass}`}>
          {displayedTestimonials.map((testimonial, index) => (
            <div 
              key={index} 
              className="w-full max-w-xs h-auto p-5 bg-transparent rounded-lg text-center flex flex-col justify-between"
              style={{ minHeight: '300px' }}
            >
              <div className="w-20 h-20 rounded-full mx-auto mb-5 overflow-hidden">
                <img src={testimonial.image} alt={testimonial.author} className="w-full h-full object-cover"/>
              </div>
              <p className="text-gray-700 mb-4 flex-grow">{testimonial.quote}</p>
              <div className="mt-auto">
                <p className="text-lg font-normal text-webDarker mb-2">{testimonial.author}</p>
                <p className="text-sm text-gray-500 font-sans">{testimonial.role}</p>
              </div>
            </div>
          ))}
        </div>
        {totalSets > 1 && (
          <FontAwesomeIcon
            icon={faChevronRight}
            className="text-2xl text-gray-600 cursor-pointer hidden md:block hover:text-hoverColor transform transition-transform duration-300 hover:scale-150"
            onClick={nextTestimonials}
            aria-label="Next testimonials"
          />
        )}
      </div>
      {totalSets > 1 && (
        <div className="mt-6">
          {Array.from({ length: totalSets }).map((_, index) => (
            <span
              key={index}
              className={`inline-block w-2.5 h-2.5 mx-1.5 rounded-full cursor-pointer transition-all duration-300 ${index === activeIndex ? 'w-4 h-4 bg-webDarker' : 'bg-[#66d2e8]'}`}
              onClick={() => goToTestimonialSet(index)}
              aria-label={`Go to testimonials set ${index + 1}`}
            ></span>
          ))}
        </div>
      )}
    </section>
  );
}

export default Testimonials;
