import React, { useEffect, useState, useCallback, useMemo } from 'react';
import CourseImage from '../../utilities/getImageWithDiv';
import StarRating from './StarRating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faUserGraduate, faBook, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { getLocationName } from '../../api/GoogleMap';
import { useSelector } from 'react-redux';

const Instructor = ({ instructor, reviews, removeReviews }) => {
    const [reviewLocations, setReviewLocations] = useState({});
    const [showAllReviews, setShowAllReviews] = useState(false);
    const courses = useSelector(state => state.courses.courses);
    const averageRating = reviews.length > 0 
        ? (reviews.reduce((sum, review) => sum + review.rating, 0) / reviews.length).toFixed(1)
        : 'N/A';

    // Memoized function to calculate total course count
    const totalCoursesCount = useCallback((coursesArray) => {
        let totalCount = 0;
        coursesArray.forEach(course => {
            course.instructors.forEach((instructorItem) => {
                if (instructorItem.id === instructor.id) {
                    totalCount++;
                }
            });
        });
        return totalCount;
    }, [instructor.id]);

    const totalCourseCount = useMemo(() => totalCoursesCount(courses), [courses, totalCoursesCount]);

    useEffect(() => {
        const fetchReviewLocations = async () => {
            const locations = {};

            for (const review of reviews) {
                if (review.userLocation) {
                    const { latitude, longitude } = review.userLocation;
                    try {
                        const locationName = await getLocationName(latitude, longitude);
                        locations[review.userID] = locationName;
                    } catch (error) {
                        console.error('Error fetching location for review:', review.userID, error);
                        locations[review.userID] = 'Unknown Location';
                    }
                } else {
                    locations[review.userID] = 'Location not available';
                }
            }

            setReviewLocations(locations);
        };

        if (reviews.length > 0) {
            fetchReviewLocations();
        }
    }, [reviews]);

    const handleShowMoreReviews = () => {
        setShowAllReviews(true);
    };

    return (
        <section className="px-6 bg-white">
            {/* Instructor Header */}
            <div className="flex flex-col mb-8">
                <div className='flex flex-row items-center mb-4'>
                    <h2 className="text-xl mr-4 font-medium text-black"># {instructor?.name || 'Unknown Instructor'}, {instructor?.bio}</h2>
                </div>
                <div className='flex flex-row items-center'>
                    {instructor.imageUrl && (
                        <CourseImage imagePath={instructor.imageUrl} imageWidth='w-24' imageHeight='h-24' rounded='rounded-full'/>
                    )}
                    <div className="flex-1">
                        <div className="flex flex-col items-baseline text-sm text-gray-500 mt-4 space-x-4">
                            <span className="flex items-center">
                                <FontAwesomeIcon icon={faStar} className="text-[#69D4DD] mr-4" /> {/* Instructor Rating Icon */}
                                <strong className='mr-1'>{averageRating}</strong> Instructor Rating
                            </span>
                            <span className="flex items-center">
                                <FontAwesomeIcon icon={faCommentDots} className="text-[#69D4DD] mr-4" /> {/* Reviews Icon */}
                                <strong className='mr-1'>{reviews.length}</strong> Reviews
                            </span>
                            <span className="flex items-center">
                                <FontAwesomeIcon icon={faUserGraduate} className="text-[#69D4DD] mr-4" /> {/* Students Icon */}
                                <strong className='mr-1'>25,678</strong> Students
                            </span>
                            <span className="flex items-center">
                                <FontAwesomeIcon icon={faBook} className="text-[#69D4DD] mr-4" /> {/* Courses Icon */}
                                <strong className='mr-1'>{totalCourseCount}</strong> Course(s)
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Instructor Description */}
            <p className="text-textGray text-base mb-6 leading-relaxed">
                {instructor?.self || ''}
            </p>
            <div className="border-t border-gray-300 my-4 w-full"></div>

            {/* Reviews Section */}
            {!removeReviews && (
                <div className="reviews-section mb-8">
                    <h3 className="text-xl text-gray-600 font-medium">Reviews</h3>
                    {reviews.length > 0 ? (
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 py-2">
                            {reviews.slice(0, showAllReviews ? reviews.length : 4).map((review, index) => (
                                <div key={index} className="bg-gray-100 p-6 shadow-sm rounded-lg">
                                    <div className="flex items-center mb-2 py-2">
                                        <StarRating rating={review.rating} />
                                        <span className="ml-2 text-xl font-bold text-gray-800">{review.rating.toFixed(1)}</span>
                                    </div>
                                    <p className="text-textGray text-sm">"{review.comment || 'No comment available.'}"</p>
                                    <p className="text-black text-sm font-medium mt-2">
                                        {review.userID || 'Anonymous'}, {reviewLocations[review.userID] || 'Loading location...'}
                                    </p>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <p className="text-gray-600">No reviews available for this instructor.</p>
                    )}
                </div>
            )}

            {/* "See more reviews" link if there are more than 4 reviews */}
            {!showAllReviews && reviews.length > 4 && !removeReviews && (
                <div className="mt-6">
                    <button
                        className="text-blue-600 mb-2 hover:underline cursor-pointer"
                        onClick={handleShowMoreReviews}
                    >
                        See more reviews
                    </button>
                </div>
            )}
        </section>
    );
};

export default Instructor;
