import React from 'react';
import newHeroImg from '../../assets/img/Archive/landingPage/landing_page_hero_image.png';
import { analytics } from '../../api/firebaseConfig';
import { logEvent } from 'firebase/analytics';
import { useNavigate } from 'react-router-dom';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import CustomButton from '../../utilities/UIcomponents/buttons/AnimatedButton';

function HeroSection() {
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    // TODO: set up google analytics in firebase console.
    logEvent(analytics, 'button_click', { button_name: 'Get Started' });
    navigate('/courses');
  };

  const handleDiscordClick = () => {
    window.open('https://discord.gg/EEKjMNrVQd', '_blank', 'noopener,noreferrer');
    logEvent(analytics, 'link_click', { link_name: 'Discord' });
  };

  return (
    <section 
      className="relative flex items-center justify-start text-left py-16 px-8 h-screen bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${newHeroImg})` }}
    >
      <div className="absolute inset-0 bg-black opacity-40"></div>
      <div className="relative z-10 max-w-2xl text-white font-sans flex flex-col justify-center items-start h-full px-6 md:px-16">
        <h1 className="text-[2.5rem] md:text-[3.5rem] font-bold mb-6">
          <span className="text-white font-normal">Get help from the expert tutors</span><br />
        </h1>
        <p className="text-lg md:text-lg mb-8">
          Choose your project, learn the skills, build your future. Starting with the basics and advancing all the way to mastering development.
        </p>
        <div className="flex flex-col md:flex-row items-start gap-8">
          {/* <button 
            onClick={handleGetStartedClick}
            className="w-full md:w-auto px-8 py-4 bg-white text-webDarker rounded-full cursor-pointer text-lg transition-colors duration-300 ease-in-out hover:bg-webDarker hover:text-white"
          >
            Get started now
          </button> */}
          <CustomButton
            onClick={handleGetStartedClick}
            icon={faRightToBracket}
            bgColor="bg-webDarker"
            hoverBgColor="hover:bg-white"
            textColor="text-white"
            hoverTextColor="hover:text-webDarker"
            size="px-24 py-8 text-lg"
            rounded="rounded-full"
          >
              Get started now
          </CustomButton>
          {/* <a 
            href="https://discord.gg/EEKjMNrVQd"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleDiscordClick}
            className="w-full md:w-auto px-8 py-4 bg-[#7aa2e338] text-white rounded-full cursor-pointer text-lg transition-colors duration-300 ease-in-out hover:bg-webDarker hover:text-white"
          >
            <span className="flex items-center">
              <FontAwesomeIcon className="mr-2" icon={faDiscord} />
              Join Discord
            </span>
          </a> */}
          <CustomButton
            onClick={handleDiscordClick}
            icon={faDiscord}
            bgColor="bg-[#7aa2e338]"
            hoverBgColor="hover:bg-[#7289da]"
            textColor="text-white"
            hoverTextColor="hover:text-white"
            size="px-24 py-8 text-lg"
            rounded="rounded-full"
          >
              Join Discord
          </CustomButton>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
