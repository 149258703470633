import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { db } from '../../api/firebaseConfig';
import { collection, getDocs, getDoc, onSnapshot } from 'firebase/firestore';

// this async func fetches all the reviews for an instructor and serializes them
const fetchInstructorData = async (instructorRef) => {
  try {
    const instructorDocSnap = await getDoc(instructorRef);

    if (instructorDocSnap.exists()) {
      const instructorData = instructorDocSnap.data();

      const reviews = instructorData.reviews ? instructorData.reviews : [];

      const reviewIds = await Promise.all(
        reviews.map(async (reviewRef) => {
          try {
            const reviewDocSnap = await getDoc(reviewRef);
            if (reviewDocSnap.exists()) {
              return reviewDocSnap.id;
            } else {
              console.warn(`Review not found: ${reviewRef.id}`);
              return null;
            }
          } catch (error) {
            console.error(`Error fetching review: ${reviewRef.id}`, error);
            return null;
          }
        })
      );

      const filteredReviewIds = reviewIds.filter(reviewId => reviewId !== null);

      return {
        id: instructorDocSnap.id,
        ...instructorData,
        reviews: filteredReviewIds
      };
    } else {
      console.warn(`Instructor with ID ${instructorRef.id} not found.`);
      return null;
    }
  } catch (error) {
    console.error("Error fetching instructor data: ", error);
    return null;
  }
};

// fetchCourses is deprecated, no longer used.
export const fetchCourses = createAsyncThunk(
  'courses/fetchCourses',
  async (_, { rejectWithValue }) => {
    try {
      const coursesSnapshot = await getDocs(collection(db, 'courses'));
      const courses = [];

      for (const courseDoc of coursesSnapshot.docs) {
        try {
          const courseData = courseDoc.data();
          let createdAt = courseData.createdAt;
          if (createdAt && typeof createdAt.toDate === 'function') {
            createdAt = createdAt.toDate().toISOString();
          } else {
            createdAt = null;
          }
          const sessionsSnapshot = await getDocs(collection(db, `courses/${courseDoc.id}/sessions`));
          const instructors = await Promise.all(
            courseData.instructors.map(instructorRef => fetchInstructorData(instructorRef))
          );
          const filteredInstructors = instructors.filter(instructor => instructor !== null);

          const sessions = sessionsSnapshot.docs.map(sessionDoc => {
            const sessionData = sessionDoc.data();
            const startDate = sessionData.startDate?.toDate().toISOString();
            const endDate = sessionData.endDate?.toDate().toISOString();
            let availability = 0;

            if (sessionData.times) {
              for (const key in sessionData.times) {
                if (sessionData.times[key].isAvailable) {
                  availability += sessionData.times[key].slots;
                }
              }
            }
            return {
              sessionId: sessionDoc.id,
              ...sessionData,
              startDate,
              endDate,
              availability,
            };
          });

          courses.push({
            id: courseDoc.id,
            ...courseData,
            createdAt,
            sessions,
            instructors: filteredInstructors,
          });
        } catch (sessionError) {
          console.error(`Error fetching sessions for course ${courseDoc.id}:`, sessionError);
        }
      }
      return courses;

    } catch (error) {
      console.error('Error fetching courses:', error);
      return rejectWithValue('Failed to fetch courses. Please try again later.');
    }
  },
);
// use listenToCourses here to listen to course changes and update the courses array in redux realtime.
export const listenToCourses = createAsyncThunk(
  'courses/listenToCourses',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const coursesRef = collection(db, 'courses');

      const unsubscribe = onSnapshot(coursesRef, async (coursesSnapshot) => {
        const courses = [];

        for (const courseDoc of coursesSnapshot.docs) {
          try {
            const courseData = courseDoc.data();

            let createdAt = courseData.createdAt;
            if (createdAt && typeof createdAt.toDate === 'function') {
              createdAt = createdAt.toDate().toISOString();
            } else {
              createdAt = null;
            }

            // Fetch sessions
            const sessionsSnapshot = await getDocs(collection(db, `courses/${courseDoc.id}/sessions`));
            const sessions = sessionsSnapshot.docs.map(sessionDoc => {
              const sessionData = sessionDoc.data();
              const startDate = sessionData.startDate?.toDate().toISOString();
              const endDate = sessionData.endDate?.toDate().toISOString();
              let availability = 0;

              if (sessionData.times) {
                for (const key in sessionData.times) {
                  if (sessionData.times[key].isAvailable) {
                    availability += sessionData.times[key].slots;
                  }
                }
              }
              return {
                sessionId: sessionDoc.id,
                ...sessionData,
                startDate,
                endDate,
                availability,
              };
            });

            const instructors = await Promise.all(
              courseData.instructors.map(instructorRef => fetchInstructorData(instructorRef))
            );
            const filteredInstructors = instructors.filter(instructor => instructor !== null);

            courses.push({
              id: courseDoc.id,
              ...courseData,
              createdAt,
              sessions,
              instructors: filteredInstructors,
            });
          } catch (error) {
            console.error(`Error processing course ${courseDoc.id}:`, error);
          }
        }

        dispatch(coursesUpdated(courses));
      });

      // Return the unsubscribe function for cleanup
      return unsubscribe;
    } catch (error) {
      console.error('Error setting up courses listener:', error);
      return rejectWithValue('Failed to set up courses listener.');
    }
  }
);

const courseSlice = createSlice({
  name: 'courses',
  initialState: {
    courses: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    clearCourses: state => {
        state.courses = [];
    },
    coursesUpdated: (state, action) => {
      state.courses = action.payload;
      state.status = 'succeeded';
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchCourses.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(fetchCourses.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.courses = action.payload;
        })
        .addCase(fetchCourses.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
        .addCase(listenToCourses.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(listenToCourses.fulfilled, (state) => {
          state.status = 'succeeded';
        })
        .addCase(listenToCourses.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
        });
    }
});

export default courseSlice.reducer;
export const { clearCourses, coursesUpdated } = courseSlice.actions;

