import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import CourseImage from "../../utilities/getImageWithDiv";

const CoursesFeaturedViewing = ({ excludedCourseID, tags }) => {
  const navigate = useNavigate();
  const { courses } = useSelector((state) => state.courses);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerView, setItemsPerView] = useState(3);

  const filteredCourses = React.useMemo(
    () =>
      courses.filter(
        (course) =>
          course.id !== excludedCourseID && // Exclude the currently viewed course
          course.category.some((category) => tags.includes(category)) // Check if any category matches any tag
      ),
    [courses, excludedCourseID, tags]
  );
  console.log("tags:", tags);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setItemsPerView(3);
      } else if (window.innerWidth >= 768) {
        setItemsPerView(2);
      } else {
        setItemsPerView(1);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 < filteredCourses.length - itemsPerView + 1 ? prevIndex + 1 : 0
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? filteredCourses.length - itemsPerView : prevIndex - 1
    );
  };

  if (filteredCourses.length === 0) return null;

  const handleCourseClick = (courseId) => {
    navigate(`/courses/${courseId}`);
  };

  return (
    <div className="relative bg-gray-50 py-10 px-4 overflow-hidden">
      <h1 className="text-3xl font-medium mb-6 mx-6 text-left">You may be interested</h1>
      <div className="overflow-hidden w-full">
        <div
          className="flex transition-transform duration-500"
          style={{
            transform: `translateX(-${currentIndex * (100 / itemsPerView)}%)`,
            width: `100%`,
          }}
        >
          {filteredCourses.map((course) => (
            <div
              key={course.id}
              className="p-4 flex-shrink-0 cursor-pointer" 
              style={{
                minWidth: `${100 / itemsPerView}%`,
                maxWidth: `${100 / itemsPerView}%`,
              }}
              onClick={() => handleCourseClick(course.id)}
            >
              <div className="h-full bg-white rounded-lg hover:shadow-xl hover:scale-105 transition-all duration-300 flex flex-col justify-between relative">
                {/* Image Container with Tags */}
                <div className="relative">
                  {/* Render Tags at Top Left */}
                  <div className="absolute top-2 left-2 flex space-x-2">
                    {course.category.map((category, idx) => (
                      <span
                        key={idx}
                        className="bg-white/30 backdrop-blur-lg text-white rounded-md px-3 py-1 text-sm font-medium shadow-md"
                      >
                        {category}
                      </span>
                    ))}
                  </div>

                  {/* Image */}
                  <CourseImage
                    imagePath={course.imageID}
                    imageWidth="w-full"
                    imageHeight="h-48"
                    rounded="rounded-none"
                  />
                </div>

                {/* Course Content */}
                <div className="p-6 flex-grow">
                  <h2 className="text-left text-lg font-semibold mb-2 text-gray-800 hover:text-hoverColor transition-colors duration-300">
                    {course.title}
                  </h2>
                  <p className="text-left text-sm text-gray-600 mb-6 line-clamp-3">
                    {course.description}
                  </p>
                  <div className="text-left border-t pt-4 mt-4 border-gray-200">
                    {course.instructors.map((instructor, idx) => (
                      <div key={idx} className="mb-2 flex items-center justify-between">
                        <p className="text-sm font-medium text-gray-700">{instructor.name}</p>
                        <p className="text-sm text-gray-500">{instructor.bio}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Navigation Buttons */}
      <button
        className="absolute top-1/2 transform -translate-y-1/2 left-2 bg-white p-2 rounded-full shadow-lg hover:shadow-xl hover:bg-gray-100 transition-all duration-300"
        onClick={handlePrev}
        aria-label="Previous Slide"
      >
        <FontAwesomeIcon icon={faChevronLeft} className="text-gray-600" />
      </button>
      <button
        className="absolute top-1/2 transform -translate-y-1/2 right-2 bg-white p-2 rounded-full shadow-lg hover:shadow-xl hover:bg-gray-100 transition-all duration-300"
        onClick={handleNext}
        aria-label="Next Slide"
      >
        <FontAwesomeIcon icon={faChevronRight} className="text-gray-600" />
      </button>
    </div>
  );
};

export default CoursesFeaturedViewing;
