import React, { useState } from 'react';
import { doc, getDoc, collection, addDoc } from 'firebase/firestore';
import { db } from '../../../api/firebaseConfig';

const DuplicateCourseButton = () => {
  const [courseId, setCourseId] = useState(''); // State to hold the course ID to duplicate from
  const [newCourseName, setNewCourseName] = useState(''); // State to hold the new course name
  const [isDuplicating, setIsDuplicating] = useState(false); // State to indicate if duplication is in progress
  const [message, setMessage] = useState(''); // State to show messages or errors

  /**
   * Function to duplicate a course document within the courses collection.
   */
  const duplicateCourse = async () => {
    if (!courseId) {
      setMessage('Please enter the course ID to duplicate from.');
      return;
    }
    if (!newCourseName) {
      setMessage('Please enter a new course name.');
      return;
    }

    setIsDuplicating(true); // Start the duplicating process
    setMessage('');

    try {
      // Reference to the original course document
      const courseDocRef = doc(db, 'courses', courseId);

      // Retrieve the original course document
      const courseSnapshot = await getDoc(courseDocRef);

      if (courseSnapshot.exists()) {
        // Get the data of the original course
        const courseData = courseSnapshot.data();

        // Append the new course name to the duplicated data
        const newCourseData = {
          ...courseData,
          title: newCourseName, // Change the title to the new course name
          createdAt: new Date(), // Optional: Add a new createdAt timestamp
        };

        // Create a new document with the modified data
        const newCourseRef = await addDoc(collection(db, 'courses'), newCourseData);

        setMessage(`Course duplicated successfully! New Course ID: ${newCourseRef.id}`);
        setIsDuplicating(false); // End the duplicating process
        setCourseId(''); // Reset the input field for course ID
        setNewCourseName(''); // Reset the input field for course name
      } else {
        setMessage('Original course not found.');
        setIsDuplicating(false);
      }
    } catch (error) {
      setMessage(`Error duplicating course: ${error.message}`);
      setIsDuplicating(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
        <h2 className="text-2xl font-semibold text-gray-700 mb-6">Duplicate Course</h2>

        {/* Input field for the Course ID */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Enter course ID to duplicate from"
            value={courseId}
            onChange={(e) => setCourseId(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>

        {/* Input field for the New Course Name */}
        <div className="mb-6">
          <input
            type="text"
            placeholder="Enter new course name"
            value={newCourseName}
            onChange={(e) => setNewCourseName(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>

        {/* Duplicate Course Button */}
        <button
          className={`w-full py-3 text-white rounded-lg transition-colors duration-300 ${
            isDuplicating ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'
          }`}
          onClick={duplicateCourse} // Call duplicateCourse without parameters
          disabled={isDuplicating} // Disable button while duplicating
        >
          {isDuplicating ? 'Duplicating...' : 'Duplicate Course'}
        </button>

        {/* Message display for success or error */}
        {message && <p className="text-center mt-6 text-gray-700">{message}</p>}
      </div>
    </div>
  );
};

export default DuplicateCourseButton;
