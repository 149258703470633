import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../../../api/firebaseConfig';

const AddWeeksForm = () => {
  const courses = useSelector((state) => state.courses.courses);
  const [courseId, setCourseId] = useState('');
  const [newWeek, setNewWeek] = useState('');
  const [dayTitles, setDayTitles] = useState({}); // Store titles for each day, format: { "Saturday": "Understanding Literary Themes" }
  const [dayActivities, setDayActivities] = useState({}); // Store activities for each day, format: { "Saturday": ["Activity 1", "Activity 2"] }
  const [selectedDays, setSelectedDays] = useState(['Saturday', 'Sunday']);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // List of all possible days
  const allDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  // Handler for adding a new week to the selected course
  const handleAddWeek = async () => {
    const courseToUpdate = courses.find((course) => course.id === courseId);

    if (!courseToUpdate) {
      setError('Course not found. Please enter a valid course ID.');
      return;
    }

    try {
      const courseDocRef = doc(db, 'courses', courseId);
      const courseSnapshot = await getDoc(courseDocRef);
      
      if (!courseSnapshot.exists()) {
        setError('Course document does not exist in Firestore.');
        return;
      }

      const currentSampleSchedule = courseSnapshot.data().sampleSchedule || {};

      const weekKey = `Week ${newWeek}`;
      if (currentSampleSchedule.hasOwnProperty(weekKey)) {
        setError(`Week ${newWeek} already exists in the schedule. Each week must be unique.`);
        return;
      }

      const newWeekSchedule = selectedDays.reduce((acc, day) => {
        const dayTitle = dayTitles[day];
        const activities = dayActivities[day];
        if (dayTitle && activities && activities.length > 0) {
          // Construct the title as "Day: Title"
          const titleKey = `${day}: ${dayTitle}`;
          acc[titleKey] = activities;
        }
        return acc;
      }, {});

      if (Object.keys(newWeekSchedule).length === 0) {
        setError('Please add at least one day with a title and activities.');
        return;
      }

      const updatedSampleSchedule = { ...currentSampleSchedule, [weekKey]: newWeekSchedule };

      await updateDoc(courseDocRef, { sampleSchedule: updatedSampleSchedule });

      // Show success message and reset form
      setSuccessMessage(`Week ${newWeek} has been successfully added to the course!`);
      setNewWeek('');
      setDayTitles({});
      setDayActivities({});
      setSelectedDays(['Saturday', 'Sunday']);
      setError('');
    } catch (err) {
      setError(`Failed to update the course: ${err.message}`);
    }
  };

  const handleTitleChange = (day, value) => {
    setDayTitles({ ...dayTitles, [day]: value });
  };

  const handleActivityChange = (day, index, value) => {
    const updatedActivities = dayActivities[day] ? [...dayActivities[day]] : [];
    updatedActivities[index] = value;
    setDayActivities({ ...dayActivities, [day]: updatedActivities });
  };

  const addActivityInput = (day) => {
    const updatedActivities = dayActivities[day] ? [...dayActivities[day], ''] : [''];
    setDayActivities({ ...dayActivities, [day]: updatedActivities });
  };

  const handleDayRangeChange = (range) => {
    switch (range) {
      case 'weekdays':
        setSelectedDays(allDays.slice(0, 5)); // Monday to Friday
        break;
      case 'weekend':
        setSelectedDays(['Saturday', 'Sunday']);
        break;
      case 'fullWeek':
        setSelectedDays(allDays);
        break;
      default:
        setSelectedDays([]);
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-xl font-semibold mb-4">Add New Week to Course Schedule</h2>

      {/* Input for Course ID */}
      <div className="mb-4">
        <label htmlFor="courseId" className="block text-sm font-medium text-gray-700 mb-2">
          Course ID:
        </label>
        <input
          type="text"
          id="courseId"
          value={courseId}
          onChange={(e) => setCourseId(e.target.value)}
          className="w-full border border-gray-300 rounded-md p-2 focus:ring focus:ring-blue-200"
          placeholder="Enter course ID"
        />
      </div>

      {/* Input for Week Number */}
      <div className="mb-4">
        <label htmlFor="newWeek" className="block text-sm font-medium text-gray-700 mb-2">
          New Week Number:
        </label>
        <input
          type="text"
          id="newWeek"
          value={newWeek}
          onChange={(e) => setNewWeek(e.target.value)}
          className="w-full border border-gray-300 rounded-md p-2 focus:ring focus:ring-blue-200"
          placeholder="e.g., 3 (for Week 3)"
        />
      </div>

      {/* Day Range Selection */}
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-2">Select Day Range:</label>
        <div className="flex gap-4">
          <button
            type="button"
            className={`px-4 py-2 rounded-md border ${
              selectedDays.length === 5 ? 'bg-blue-600 text-white' : 'border-blue-600 text-blue-600'
            }`}
            onClick={() => handleDayRangeChange('weekdays')}
          >
            Monday - Friday
          </button>
          <button
            type="button"
            className={`px-4 py-2 rounded-md border ${
              selectedDays.length === 2 ? 'bg-blue-600 text-white' : 'border-blue-600 text-blue-600'
            }`}
            onClick={() => handleDayRangeChange('weekend')}
          >
            Saturday - Sunday
          </button>
          <button
            type="button"
            className={`px-4 py-2 rounded-md border ${
              selectedDays.length === 7 ? 'bg-blue-600 text-white' : 'border-blue-600 text-blue-600'
            }`}
            onClick={() => handleDayRangeChange('fullWeek')}
          >
            Monday - Sunday
          </button>
        </div>
      </div>

      {/* Title and Activity Inputs for Each Selected Day */}
      {selectedDays.map((day) => (
        <div key={day} className="mb-4">
          <h3 className="text-sm font-medium text-gray-700 mb-2">{day} Activities</h3>
          {/* Input for Day Title */}
          <input
            type="text"
            value={dayTitles[day] || ''}
            onChange={(e) => handleTitleChange(day, e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2 mb-2 focus:ring focus:ring-blue-200"
            placeholder={`Enter title for ${day} (e.g., Understanding Literary Themes)`}
          />
          {/* Input for Activities */}
          {(dayActivities[day] || ['']).map((activity, index) => (
            <input
              key={`${day}-${index}`}
              type="text"
              value={activity}
              onChange={(e) => handleActivityChange(day, index, e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2 mb-2 focus:ring focus:ring-blue-200"
              placeholder={`Activity ${index + 1}`}
            />
          ))}
          <button
            type="button"
            onClick={() => addActivityInput(day)}
            className="text-sm text-blue-600 hover:underline"
          >
            + Add Another Activity
          </button>
        </div>
      ))}

      {/* Error Message */}
      {error && <p className="text-red-500 mb-4">{error}</p>}

      {/* Success Message */}
      {successMessage && <p className="text-green-500 mb-4">{successMessage}</p>}

      {/* Submit Button */}
      <button
        onClick={handleAddWeek}
        className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 transition"
      >
        Add Week to Schedule
      </button>
    </div>
  );
};

export default AddWeeksForm;
