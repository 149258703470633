import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import authService from '../../api/AuthService';
import { db } from '../../api/firebaseConfig';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { getAuth, deleteUser,fetchSignInMethodsForEmail } from 'firebase/auth';

const auth = getAuth();
export const loginUser = createAsyncThunk(
  'user/loginUser',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const userCredential = await authService.loginWithEmail(email, password);
      const uid = userCredential.user.uid;

      const userDoc = await getDoc(doc(db, 'users', uid));
      const userData = userDoc.data();
      return { uid, email: userCredential.user.email, ...userData };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const registerUser = createAsyncThunk(
  'user/registerUser',
  async ({ name, emailSignup, passwordSignup, age, programmingFluency, phoneNumber, gender,registeredCourses }, { dispatch, rejectWithValue }) => {
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, emailSignup);
      if (signInMethods.length > 0) {
        throw new Error('Email is already in use.');
      }

      const userCredential = await authService.signUpWithEmail(emailSignup, passwordSignup);
      const uid = userCredential.user.uid;

      const additionalUserData = {
        name,
        emailSignup,
        age,
        programmingFluency,
        phoneNumber,
        gender,
        //registeredCourses:{},
      };
      
      await setDoc(doc(db, 'users', uid), additionalUserData);
      return { uid, ...additionalUserData };

    } catch (error) {
      const user = auth.currentUser;
      if(user){
        const signInMethods = await fetchSignInMethodsForEmail(auth, user.email);
        if(signInMethods.length ===1){
          await deleteUser(user);
        }
      }
      return rejectWithValue(error);
    }
  }
);

export const logoutUser = createAsyncThunk('user/logoutUser', async (_, { rejectWithValue }) => {
  try {
    await authService.logout();
    return;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

export const updateUserDetails = createAsyncThunk(
  'user/updateUserDetails',
  async (userDetails, { getState, rejectWithValue }) => {
    try {
      const uid = getState().user.user.uid;
      await updateDoc(doc(db, 'users', uid), userDetails);
      return userDetails;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const resetUserPassword = createAsyncThunk(
  'user/resetUserPassword',
  async (email, { rejectWithValue }) => {
    try {
      await authService.resetPassword(email);
      return true;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


export const fetchUserData = createAsyncThunk(
  'user/fetchUserData',
  async(uid, {rejectWithValue}) =>{
    try{
      const userDoc = await getDoc(doc(db,'users',uid));
      if(userDoc.exists()){
        return {uid, ...userDoc.data()};
      }else{
        throw new Error('User document not found');
      }

    }catch(error){
      return rejectWithValue(error.message);
    }
  }
);
export const fetchUserDataStandalone = async (uid) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', uid));
    if (userDoc.exists()) {
      return { uid, ...userDoc.data() };
    } else {
      throw new Error('User document not found');
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

export const setUser =createAsyncThunk(
  'user/setUser',  
  async(user, {rejectWithValue})=>{
    try{
      const userDoc = await getDoc(doc(db, 'users,', user.uid));
      if(userDoc.exists()){
        return {uid: user.uid, email: user.email, ...userDoc.data()};
      }else{
        throw new Error('User doc not found');
      }
    }catch(error){
      return rejectWithValue(error.message);
    }
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    isLoggedIn: false,
    status: 'idle',
    error: null
  },
  reducers: {
    initializeState(state, action) {
      state.user = action.payload;
      state.isLoggedIn = !!action.payload;
    },
    clearErrors(state) {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoggedIn = true;
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = 'failed';
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.user = action.payload;
        state.isLoggedIn = true;
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.error = action.payload;
        state.status = 'failed';
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.user = null;
        state.isLoggedIn = false;
        state.status = 'succeeded';
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.error = action.payload;
        state.status = 'failed';
      })
      .addCase(updateUserDetails.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload };
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(updateUserDetails.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = 'failed';
      })
      .addCase(resetUserPassword.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(resetUserPassword.rejected, (state, action) => {
        state.error = action.error.message;
        state.status = 'failed';
      })
      .addCase(fetchUserData.fulfilled, (state, action)=>{
        state.user = {...state.user, ...action.payload};
        state.status ='succeeded';
        state.error = null;
      })
      .addCase(fetchUserData.rejected, (state, action)=>{
        state.error = action.payload;
        state.status = 'failed';
      })
      .addCase(setUser.fulfilled, (state, action)=>{
        state.user = action.payload;
        state.isLoggedIn =true;
        state.status = 'succeeded';
        state.error = null;
      })
      .addCase(setUser.rejected, (state, action)=>{
        state.error = action.payload;
        state.status = 'failed';
      });
  }
});

export const { initializeState, clearErrors } = userSlice.actions;
export default userSlice.reducer;
