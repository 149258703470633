import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import bannerImage from '../../assets/img/blogBanner.png';
import FeaturedPost from '../FeaturedPost';
import { updateDoc, doc } from 'firebase/firestore';
import { db } from '../../api/firebaseConfig';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, FacebookIcon, TwitterIcon, LinkedinIcon } from 'react-share';
import blogFormatter from './blogFormatter';

const BlogTemplate = ({ id, likes, title, content, author, authorPhoto, createdAt, imageUrl, tags, featuredPosts }) => {
  const [currentLikes, setCurrentLikes] = useState(likes);
  const bannerImageUrl = imageUrl || bannerImage;
  const formattedDate = createdAt ? new Date(createdAt).toLocaleDateString('en-US', {
    year: 'numeric', month: 'long', day: 'numeric'
  }) : 'Unknown date';

  const handleLike = async() => {
    const newLikes = currentLikes+1;
    setCurrentLikes(newLikes);
    try{
        const blogRef = doc(db, 'blogs', id);
        await updateDoc(blogRef, {
            likes: newLikes,
        })
    }catch(error){
        console.error("Error updating likes: ", error);
    }
  };

  const shareUrl = window.location.href;

  return (
    <div className="container mx-auto py-8 px-4 sm:px-6 lg:px-8">
      <div className="mb-4">
        <Link to="/blogs" className="text-primary hover:underline">Blog</Link>
        <span className="mx-2 text-gray-400">&gt;</span>
        <span className="text-gray-500">{title}</span>
      </div>

      <article className="flex flex-col lg:flex-row">
        <div className="lg:w-2/3">
          <div className="w-full h-64 sm:h-96 bg-cover bg-center rounded-lg shadow-md" style={{ backgroundImage: `url(${bannerImageUrl})` }}></div>

          <div className="mt-6">
            {/* <h1 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-2">{title}</h1> */}
            <div className="flex items-center text-gray-600 text-sm">
              <div className="flex items-center space-x-2">
                {/* {authorPhoto && <img src={authorPhoto} alt={author} className="w-8 h-8 rounded-full object-cover" />} */}
                <span>By {'Albuddy'}</span>
              </div>
              <time className="ml-4">{formattedDate}</time>
            </div>
            {tags && tags.length > 0 && (
              <div className="flex flex-wrap gap-2 mt-4">
                {tags.map((tag, index) => (
                  <span key={index} className="inline-block bg-blue-50 rounded-full px-3 py-1 text-sm font-normal text-webDarker mr-2 my-2">{tag}</span>
                ))}
              </div>
            )}
            {blogFormatter(content)}
            <div className="flex items-center mt-8 space-x-4">
              <button onClick={handleLike} className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-hoverColor transition-colors">
                Like ({currentLikes})
              </button>
              <div className="flex space-x-2">
                <FacebookShareButton url={shareUrl} quote={title}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton url={shareUrl} title={title}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <LinkedinShareButton url={shareUrl} title={title}>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </div>
            </div>
          </div>
        </div>

        <aside className="lg:w-1/3 lg:ml-8 mt-12 lg:mt-0">
          <div className="bg-white">
            {featuredPosts.map(post => (
              <FeaturedPost 
                key={post.id}
                id={post.id}
                tags={post.tags}
                title={post.title}
                imageUrl={post.imageUrl}
                author={post.author}
                createdAt={post.createdAt}
                content={post.content}
              />
            ))}
          </div>
        </aside>
      </article>
    </div>
  );
};

export default React.memo(BlogTemplate);
