import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addItemToCart } from '../../../redux/slices/cartSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCartPlus, faChevronRight, faExclamationCircle, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { formatDateRange, convertSessionTime } from '../../../utilities/parseTime';
import { logEvent } from 'firebase/analytics';
import { db, analytics } from '../../../api/firebaseConfig';
import { doc, onSnapshot } from 'firebase/firestore';
import CustomSelect from '../../../utilities/customSelect';
import {setTimeOnDate} from '../../../utilities/parseTime';
import { toast } from 'react-toastify';

const getCoursePrice = (course) => {
    return course.isDiscounted ? course.discountedPrice : course.price;
};

const AvailableSessions = ({ course, setShowCartModal }) => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const [expandedSessions, setExpandedSessions] = useState({});
    const [selectedTimes, setSelectedTimes] = useState([]);
    const [isPurchaseDisabled, setIsPurchaseDisabled] = useState(true);
    const [latestUser, setLatestUser] = useState(user);
    const durationDate = course?.duration.split('|')[1].trim();
    const [selectedTimeZone, setSelectedTimeZone] = useState('America/New_York');
    const [showWarningModal, setShowWarningModal] = useState(false);

    const [registeredTimeSlots, setRegisteredTimeSlots] = useState(new Set());

    useEffect(() => {
        if (user && user.uid) {
            const userDocRef = doc(db, 'users', user.uid);
            const unsubscribe = onSnapshot(userDocRef, (docSnap) => {
                if (docSnap.exists()) {
                    const userData = docSnap.data();
                    setLatestUser(userData);

                    const registeredCourses = userData.registeredCourses || {};
                    const timeSlotSet = new Set();

                    Object.values(registeredCourses).forEach((registeredCourse) => {
                        const { courseId, sessionId, timeSlot } = registeredCourse;
                        const key = `${courseId}_${sessionId}_${timeSlot}`;
                        timeSlotSet.add(key);
                    });

                    setRegisteredTimeSlots(timeSlotSet);
                }
            });
            return () => unsubscribe();
        }
    }, [user]);

    useEffect(() => {
        if (course?.sessions) {
            const initialSessionsState = course.sessions.reduce((acc, session) => {
                acc[session.sessionId] = false;
                return acc;
            }, {});
            setExpandedSessions(initialSessionsState);
        }
    }, [course]);

    // Handle selection and deselection of time slots
    const handleTimeSlotSelection = (session, timeSlot) => {
        const isAlreadySelected = selectedTimes.some(
            (selected) =>
                selected.sessionId === session.sessionId && selected.startTime === timeSlot.startTime
        );

        const convertedStartTime = convertSessionTime(timeSlot.startTime, selectedTimeZone);
        const convertedEndTime = convertSessionTime(timeSlot.endTime, selectedTimeZone);

        const now = new Date();
        const sessionEndDateObject = new Date(session.endDate);
        const sessionStartDateObject = new Date(session.startDate);
        const parsedEndDateWithTime = setTimeOnDate(new Date(sessionEndDateObject), convertedEndTime);
        const parsedStartDateWithTime = setTimeOnDate(new Date(sessionStartDateObject), convertedStartTime);
        const isExpiredCheck = now > parsedEndDateWithTime;
        const isStartedCheck = now > parsedStartDateWithTime;
        const checkpoint = isPurchaseDisabled && !isExpiredCheck && !isStartedCheck;
        if (latestUser){
            console.log("latestUser recieved");
        }
        console.log("checkpoint:", checkpoint);
        if (now > parsedStartDateWithTime && !isAlreadySelected) {
            setShowWarningModal(true);
        }

        if (isAlreadySelected) {
            // Remove the time slot if it's already selected
            setSelectedTimes((prev) =>
                prev.filter(
                    (selected) =>
                        !(selected.sessionId === session.sessionId && selected.startTime === timeSlot.startTime)
                )
            );
        } else {
            // Add the new time slot to the selectedTimes array
            setSelectedTimes((prev) => [
                ...prev,
                {
                    sessionId: session.sessionId,
                    startTime: timeSlot.startTime,
                    endTime: timeSlot.endTime,
                },
            ]);
        }

        setIsPurchaseDisabled(selectedTimes.length === 0);
    };

    // Handle purchase action
    const handlePurchase = () => {
        setShowCartModal(true);
        if (selectedTimes.length > 0) {
            selectedTimes.forEach((selectedTime) => {
                const session = course.sessions.find(s => s.sessionId === selectedTime.sessionId);
                if (!session) return;

                const uniqueSessionTimeId = `${selectedTime.sessionId}-${selectedTime.startTime.replace(/\s+/g, '')}`;
                const cartItem = {
                    courseId: course.id,
                    courseName: course.title,
                    price: getCoursePrice(course),
                    session: {
                        id: selectedTime.sessionId,
                        startDate: session.startDate,
                        endDate: session.endDate,
                        timeSlot: `${selectedTime.startTime} - ${selectedTime.endTime}`,
                    },
                    timeChosen: `${selectedTime.startTime} - ${selectedTime.endTime}`,
                    quantity: 1,
                    imageID: course.imageID,
                    uniqueSessionTimeId: uniqueSessionTimeId,
                };

                dispatch(addItemToCart(cartItem));
                logEvent(analytics, 'enroll_now', { course_id: course.id, session_id: selectedTime.sessionId, time_slot: selectedTime.startTime });
            });
        }
    };
    const confirmSelection = () => {
        setShowWarningModal(false);
        toast.success("You have selected a slot that has already started.");
      };
    
    // Function to cancel the selection after warning
    const cancelSelection = () => {
        setShowWarningModal(false);
        toast.info("Selection cancelled.");
    };

    const timeZoneOptions = [
        { value: 'UTC', label: 'UTC' },
        { value: 'America/New_York', label: 'Eastern Standard Time (EST)' },
        { value: 'America/Los_Angeles', label: 'Pacific Standard Zone (PST)' },
        { value: 'Asia/Tokyo', label: 'Japan Standard Time (JST)' },
        { value: 'Asia/Taipei', label: 'Taiwan Standard Time (CST)' },
        { value: 'Europe/London', label: 'Greenwich Mean Time (GMT)' },
        { value: 'Europe/Paris', label: 'Central European Time (CET)' },
        { value: 'Australia/Sydney', label: 'Australian Eastern Time (AET)' },
    ];

    return (
        <div className='bg-none max-w-full'>
            <div className="">
                {course ? (
                    <div className='flex flex-col lg:flex-row lg:ml-24'>
                        <div className='lg:w-full'>
                            <div className="bg-white p-6 rounded-lg shadow-lg">
                                <h3 className="text-2xl font-medium text-center">Available Sessions</h3>
                                <p className='text-xs text-center text-textGray mb-4'>Book sessions from the options based on your needs</p>
                                <p className="text-sm text-black mb-4 text-left font-normal">Your Time Zone</p>
                                <div className="flex items-center w-full">
                                    <CustomSelect
                                        options={timeZoneOptions}
                                        value={selectedTimeZone}
                                        onChange={(value) => setSelectedTimeZone(value)}
                                    />
                                </div>

                                <div className='flex flex-col mt-8 items-start overflow-y-auto max-h-[300px]'>
                                    {course.sessions && course.sessions.map((session) => (
                                        <div key={session.sessionId} className="mb-4 w-full">
                                            <button
                                                onClick={() => {
                                                    setExpandedSessions(prev => ({
                                                        ...prev,
                                                        [session.sessionId]: !prev[session.sessionId]
                                                    }));
                                                }}
                                                className={`w-full text-left p-4 rounded-lg flex justify-between items-center
                                                    ${expandedSessions[session.sessionId] ? 'bg-blue-50 text-black border border-webDarker' 
                                                        : 'text-black border border-gray-200'}`}
                                            >
                                                <div className='flex flex-col md:flex-row'>
                                                    {formatDateRange(session.startDate, session.endDate)}
                                                    <div className='md:ml-2 text-textGray'>
                                                        {durationDate}
                                                    </div>
                                                </div>
                                                <FontAwesomeIcon 
                                                    icon={faChevronRight} 
                                                    size='sm' 
                                                    className={`${expandedSessions[session.sessionId]?`rotate-90`:`rotate-0`} transition-transform duration-100 ease-in-out`}
                                                />
                                            </button>
                                            {expandedSessions[session.sessionId] && (
                                                <div className="mt-4 flex flex-row flex-wrap">
                                                    {Object.entries(session.times).map(([key, timeSlot], index) => {
                                                        const convertedStartTime = convertSessionTime(timeSlot.startTime, selectedTimeZone);
                                                        const convertedEndTime = convertSessionTime(timeSlot.endTime, selectedTimeZone);
                                                        const isSelected = selectedTimes.some(
                                                            (selected) =>
                                                                selected.sessionId === session.sessionId &&
                                                                selected.startTime === timeSlot.startTime
                                                        );
                                                        const animationDelay = `${index * 0.2}s`;

                                                        // Check if timeslot is already registered
                                                        const timeslotKey = `${course.id}_${session.sessionId}_${timeSlot.startTime} - ${timeSlot.endTime}`;
                                                        const isRegistered = registeredTimeSlots.has(timeslotKey);
                                                        const isSlotFull = timeSlot.slots <= 0;

                                                        //check if timeslot is already expired or has it started already
                                                        const now = new Date();
                                                        const sessionEndDateObject = new Date(session.endDate);
                                                        const sessionStartDateObject = new Date(session.startDate);
                                                        const parsedEndDateWithTime = setTimeOnDate(new Date(sessionEndDateObject), convertedEndTime);
                                                        const parsedStartDateWithTime = setTimeOnDate(new Date(sessionStartDateObject), convertedStartTime);
                                                        const isExpired = now > parsedEndDateWithTime;
                                                        const isStarted = now > parsedStartDateWithTime;

                                                        // Disable logic for registered or full timeslots only
                                                        // const isDisabled = isRegistered || isSlotFull;
                                                        const isDisabled = isRegistered || isSlotFull || isExpired;


                                                        return (
                                                            <button
                                                                key={key}
                                                                onClick={() => {
                                                                    if (isRegistered || isSlotFull) return;
                                                                    handleTimeSlotSelection(session, timeSlot);
                                                                }}
                                                                style={{ 
                                                                    animationDelay,
                                                                    opacity: 0,
                                                                    transform: 'translateX(100%)',
                                                                }}
                                                                className={`py-2 px-4 m-2 rounded-full animate-slideInRight
                                                                    ${isSelected ? 'bg-blue-50 text-black border border-webDarker' 
                                                                        : 'text-textGray border border-gray-300'}
                                                                    ${isDisabled ? 'opacity-50 text-textGray border border-gray-300 cursor-not-allowed' : ''}`}
                                                                title={isRegistered ? 'You have already registered for this timeslot' : ''}
                                                                disabled={isDisabled}
                                                            >
                                                                {convertedStartTime} - {convertedEndTime}
                                                                {isSlotFull && !isExpired && <span className="ml-2 text-xs text-textGray">(FULL)</span>}
                                                                {isRegistered && !isExpired && <span className="ml-2 text-xs text-textGray">(REGISTERED)</span>}
                                                                {isStarted && !isExpired && <span className="ml-2 text-xs text-textGray">(AlREADY STARTED)</span>}
                                                                {isExpired && <span className="ml-2 text-xs text-textGray">(EXPIRED)</span>}
                                                            </button>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>

                                {/* Display the Total Amount Dynamically */}
                                <div className={`flex justify-between mt-2 text-2xl items-center`}>
                                    <span className='text-textGray text-base'>Total:</span>
                                    {course.isDiscounted
                                        ? `$${selectedTimes.length * course.discountedPrice}`
                                        : `$${selectedTimes.length * course.price}`}
                                </div>

                                <button
                                    className={`w-full py-3 mt-4 text-white rounded-full ${selectedTimes.length === 0 ? 'bg-gray-300 cursor-not-allowed' : 'bg-webDarker hover:bg-hoverColor'}`}
                                    disabled={selectedTimes.length === 0}
                                    onClick={handlePurchase}
                                >
                                    <FontAwesomeIcon icon={faCartPlus} size='lg' className='mr-4'/>Add to Cart
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="flex justify-center items-center h-screen">
                        <FontAwesomeIcon icon={faSpinner} spin size="3x" />
                    </div>
                )}
            </div>
            {showWarningModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full transform transition-all scale-100">
                    {/* Modal Header */}
                    <div className="flex items-center justify-start space-x-3 mb-4">
                        <FontAwesomeIcon icon={faExclamationCircle} className="text-webDarker text-2xl" />
                        <h2 className="text-2xl font-bold text-gray-800">Warning</h2>
                    </div>

                    {/* Modal Content */}
                    <div className="text-left text-gray-600 mb-6">
                        <p className="leading-6">
                        The selected time slot has already started. Joining now means you might miss out on the initial content and your progress may be incomplete. Do you want to proceed?
                        </p>
                    </div>

                    {/* Modal Buttons */}
                    <div className="flex flex-col sm:flex-row justify-end space-y-4 sm:space-y-0 sm:space-x-4">
                        <button
                        onClick={confirmSelection}
                        className="relative w-full sm:w-1/2 px-12 py-6 bg-[#7aa2e338] text-webDarker rounded-full cursor-pointer text-base transition-colors duration-300 ease-in-out hover:bg-webDarker hover:text-white overflow-hidden group"
                        >
                        <span
                            className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out group-hover:-translate-x-full"
                        >
                            Continue Anyway
                        </span>
                        <span
                            className="absolute inset-0 flex items-center justify-center opacity-0 transform translate-x-full transition-all duration-300 ease-in-out group-hover:opacity-100 group-hover:translate-x-0"
                        >
                            <FontAwesomeIcon icon={faCheckCircle} className="text-xl" />
                        </span>
                        </button>

                        <button
                        onClick={cancelSelection}
                        className="relative w-full sm:w-1/2 px-12 py-6 bg-[#7aa2e338] text-webDarker rounded-full cursor-pointer text-base transition-colors duration-300 ease-in-out hover:bg-webDarker hover:text-white overflow-hidden group"
                        >
                        <span
                            className="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out group-hover:translate-x-full"
                        >
                            Cancel
                        </span>
                        <span
                            className="absolute inset-0 flex items-center justify-center opacity-0 transform -translate-x-full transition-all duration-300 ease-in-out group-hover:opacity-100 group-hover:translate-x-0"
                        >
                            <FontAwesomeIcon icon={faTimesCircle} className="text-xl" />
                        </span>
                        </button>
                    </div>
                    </div>
                </div>
            )}



        </div>
    );
};

export default React.memo(AvailableSessions);
