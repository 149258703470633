import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchBlogPosts } from "../../redux/slices/blogSlice";
import { blogFormatterTrimmed } from "../BlogTemplate/blogFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const BlogsFeaturedViewing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { posts, status } = useSelector((state) => state.blog);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [itemsPerView, setItemsPerView] = useState(3);

  useEffect(() => {
    if(status === 'idle' && (posts.length===0)){
      dispatch(fetchBlogPosts({ numToFetch: 10 }));
    }

    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setItemsPerView(3);
      } else if (window.innerWidth >= 768) {
        setItemsPerView(2);
      } else {
        setItemsPerView(1);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch, status, posts.length]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 < posts.length - itemsPerView + 1 ? prevIndex + 1 : 0
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? posts.length - itemsPerView : prevIndex - 1
    );
  };

  const handleClick = (postId) => {
    navigate(`/blogs/${postId}`);
  };

  return (
    <div className="relative bg-white py-10 px-4 overflow-hidden">
      <h1 className="text-3xl font-medium mb-6 mx-6 text-left">Learners are reading</h1>
      <div className="overflow-hidden w-full">
        <div
          className="flex transition-transform duration-500"
          style={{
            transform: `translateX(-${currentIndex * (100 / itemsPerView)}%)`,
            width: "100%",
          }}
        >
          {posts.map((post, index) => (
            <div
              key={index}
              className="w-full p-4 bg-white rounded-lg cursor-pointer transition-all hover:scale-105 hover:shadow-xl"
              style={{
                minWidth: `${100 / itemsPerView}%`,
                maxWidth: `${100 / itemsPerView}%`,
              }}
              onClick={() => handleClick(post.id)}
            >
              <img
                src={post.imageUrl}
                alt={post.title}
                className="w-full h-48 object-cover rounded-t-lg"
              />
              <div className="p-4">
                <h2 className="text-left text-lg font-medium mb-2 transition-colors duration-300 hover:text-hoverColor">{post.title}</h2>
                <p className="bg-blue-50 inline-flex w-auto text-webDarker rounded-full px-3 py-1 text-xs sm:text-sm font-normal">
                  {post.tags.join(", ")}
                </p>
                <p className="text-left text-sm text-gray-600">
                  {blogFormatterTrimmed(post.content, 150)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <button
        className="absolute top-1/2 transform -translate-y-1/2 left-2 bg-white p-2 rounded-full shadow-md hover:bg-gray-100 transition"
        onClick={handlePrev}
        aria-label="Previous Slide"
      >
        <FontAwesomeIcon icon={faChevronLeft}/>
      </button>
      <button
        className="absolute top-1/2 transform -translate-y-1/2 right-2 bg-white p-2 rounded-full shadow-md hover:bg-gray-100 transition"
        onClick={handleNext}
        aria-label="Next Slide"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
    </div>
  );
};

export default BlogsFeaturedViewing;
