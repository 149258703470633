/**
 * createCheckoutSession
 * 
 * This function creates a Stripe checkout session for the provided cart items and user.
 * 
 * - cartItems: An array of items in the user's cart to be purchased.
 * - userId: The unique identifier for the user making the purchase.
 * - promoCode: An optional promotional code to be applied to the purchase.
 * 
 * The function sends a POST request to the backend server to initiate the Stripe checkout session.
 * - fetch: Sends a request to the backend endpoint to create a checkout session.
 * - method: 'POST' - specifies the HTTP method to use.
 * - headers: Sets the Content-Type header to 'application/json' for JSON payload.
 * - body: Converts the cartItems, userId, and promoCode into a JSON string to send in the request body.
 * 
 * The function returns the response data if successful, otherwise it throws an error.
 */

// This creates a checkout session with stripe.
export const createCheckoutSession = async (cartItems, userId, promoCode) => {
    const functionsUrl = "https://createcheckoutses-3i3jvdnblq-uc.a.run.app";
    try {
      const response = await fetch(`${functionsUrl}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cartItems, userId, promoCode }),
      });
  
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || 'Failed to create Stripe checkout session');
      }

      return data;
      
    } catch (error) {
      console.error('Error creating checkout session:', error);
      throw error;
    }
  };


  export const requestRefund = async (paymentIntentId) => {
    // This function has to take the paymentIntendId stored in the user orderHistory.
    const functionsUrlRefund = process.env.REACT_APP_FIREBASE_FUNCTIONS_URL_REFUND_TES;
    try {
        const response = await fetch(`${functionsUrlRefund}/refund`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ paymentIntentId }),
        });

        const data = await response.json();

        if (!response.ok) {
            throw new Error(data.message || 'Refund failed');
        }

        return data;
    } catch (error) {
        console.error('Error processing refund:', error);
        throw error;
    }
};