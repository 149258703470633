import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { doc, getDoc, addDoc, collection } from 'firebase/firestore';
import { db } from '../../api/firebaseConfig';

export const fetchInstructorReviews = createAsyncThunk(
  'reviews/fetchInstructorReviews',
  async (reviewIds, { rejectWithValue }) => {
    try {
      const reviews = await Promise.all(
        reviewIds.map(async (reviewId) => {
          const reviewDocRef = doc(db, 'reviews', reviewId);
          const reviewDoc = await getDoc(reviewDocRef);
          if (reviewDoc.exists()) {
            return { id: reviewId, ...reviewDoc.data() };
          } else {
            return null;
          }
        })
      );
      return reviews.filter((review) => review !== null);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const submitReview = createAsyncThunk(
  'reviews/submitReview',
  async (reviewData, { rejectWithValue }) => {
    try {
      const modifiedReviewData = { ...reviewData, instructorID: reviewData.instructorId };
      delete modifiedReviewData.instructorId;

      const reviewDocRef = await addDoc(collection(db, 'reviews'), reviewData);
      const newReviewDoc = await getDoc(reviewDocRef);

      if (newReviewDoc.exists()) {
        return { id: newReviewDoc.id, ...newReviewDoc.data() };
      } else {
        return rejectWithValue('Failed to create review.');
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState: {
    reviews: [],
    status: 'idle',
    error: null,
    submitStatus: 'idle',
    submitError: null,
  },
  reducers: {
    clearReviews: (state) => {
      state.reviews = [];
    },
    clearSubmitState: (state) => {
      state.submitStatus = 'idle';
      state.submitError = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstructorReviews.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchInstructorReviews.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.reviews = action.payload;
      })
      .addCase(fetchInstructorReviews.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(submitReview.pending, (state) => {
        state.submitStatus = 'loading';
        state.submitError = null;
      })
      .addCase(submitReview.fulfilled, (state, action) => {
        state.submitStatus = 'succeeded';
        state.reviews.push(action.payload);
      })
      .addCase(submitReview.rejected, (state, action) => {
        state.submitStatus = 'failed';
        state.submitError = action.payload;
      });
  },
});

export const { clearReviews,clearSubmitState } = reviewsSlice.actions;

export default reviewsSlice.reducer;
