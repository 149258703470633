/**
 * AuthService Object:
 * loginWithEmail: Authenticates a user with their email and password.
 * signUpWithEmail: Creates a new user with an email and password.
 * logout: Signs out the currently logged-in user.
 * onAuthStateChanged: Observes changes to the user's sign-in state (e.g., user signs in or out).
 * 
 */
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, onAuthStateChanged, sendPasswordResetEmail} from 'firebase/auth';
import { auth } from './firebaseConfig';
import { collection, query, where, getDocs  } from 'firebase/firestore';
import { db } from '../api/firebaseConfig';

const authService = {
  loginWithEmail: async (email, password) => {
    try {
      console.log('Attempting to log in with email:', email);
      const result = await signInWithEmailAndPassword(auth, email, password);
      console.log('Login successful:', result);
      return result;
    } catch (error) {
      console.error('Error logging in:', error);
      throw error;
    }
  },
  signUpWithEmail: async (email, password) => {
    try {
      console.log('Attempting to sign up with email:', email);
      const result = await createUserWithEmailAndPassword(auth, email, password);
      console.log('Sign up successful:', result);
      return result;
    } catch (error) {
      console.error('Error signing up:', error);
      throw error;
    }
  },
  logout: () => signOut(auth),
  onAuthStateChanged: (callback) => onAuthStateChanged(auth, callback),
  resetPassword: async (email) => {
    try {
      const q = query(collection(db, 'users'), where('email', '==', email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        console.log("No user document found for email:", email);
        throw new Error('auth/user-not-found');
      }
  
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent:', email);
    } catch (error) {
      console.error('Error sending password reset email:', error);
      let errorCode;
      if (error.code) {
        errorCode = error.code;
      } else {
        errorCode = error.message;
      }
  
      throw new Error(errorCode);
    }
  }
  
};

export default authService;
