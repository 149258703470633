import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser, updateUserDetails, resetUserPassword, fetchUserData } from '../../redux/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import { getDoc, doc } from 'firebase/firestore';
import { db } from '../../api/firebaseConfig';
import Footer from '../../components/Footer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CourseModal from '../../components/CourseModal/CourseModal';
import RescheduleModal from './RescheduleModal';
import CourseParser from '../../components/CourseParser/CourseParser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus, faBan } from '@fortawesome/free-solid-svg-icons';
import { getUpcomingEvents, getFutureEvents, getPastEvents, getOngoingEvents } from './Utility';
import PortalHero from './PortalHero';
import { RendarNextClass, RenderCalendar } from './RenderMethods';
import { OrderHistory } from './PortalComponents';
import Tabs from './Tabs';
import CourseCard from './CourseCard';
import PortalPreSignUp from '../../components/PortalPreSignUp/PortalPreSignUp';
import heroImage from '../../assets/img/Archive/course card/Rectangle15.png'


function Portal() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.user.user);
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [selectedCourseReschedule, setSelectedCourseReschedule] = useState(null);
    const [activeTab, setActiveTab] = useState('activities');
    const [registeredCourses, setRegisteredCourses] = useState([]);
    const [parsedCourses, setParsedCourses] = useState([]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [activeViewTab, setActiveViewTab] = useState('calendar');
    const [editFields, setEditFields] = useState({
        name: user.name,
        email: user.email,
        age: user.age,
        programmingFluency: user.programmingFluency,
        phoneNumber: user.phoneNumber,
        gender: user.gender
    });
    const userInitial = user.name.charAt(0);
    const colorPalette = [
        'bg-blue-500', 
        'bg-blue-600', 
        'bg-blue-400', 
        'bg-blue-700', 
        'bg-blue-300',
        'bg-indigo-500',
        'bg-teal-500',
        'bg-blue-800',
        'bg-lightBlue-400',
        'bg-blue-200',
    ];

    const handleLogout = () => {
        dispatch(logoutUser()).then(() => navigate('/login'));
        
    };

    const handleFieldChange = (field, value) => {
        setEditFields({ ...editFields, [field]: value });
    };

    const handleUpdateDetails = () => {
        dispatch(updateUserDetails(editFields)).then(() => {
            toast.success('Details updated successfully!');
        }).catch(() => {
            toast.error('Failed to update details.');
        });
    };

    const handleResetPassword = () => {
        dispatch(resetUserPassword(user.email)).then(() => {
            toast.success('Password reset email sent!');
        }).catch(() => {
            toast.error('Failed to send password reset email.');
        });
    };
    
    const handleCourseClick = (course) => {
        setSelectedCourse(course);
    };

    const closeModal = () => {
        setSelectedCourse(null);
    };
    const handleCourseClickReschedule = (course) => {
        setSelectedCourseReschedule(course);
    };

    const closeModalReschedule = () => {
        setSelectedCourseReschedule(null);
    };
    const getDotIcon = (event, currentDate) => {
        const now = new Date();
        const threeDaysFromNow = new Date();
        threeDaysFromNow.setDate(now.getDate() + 3);
        const eventStartDate = new Date(event.parsedStartDate);
        const eventEndDate = new Date(event.parsedEndDate);
    
        if (eventEndDate < now) {
            return <FontAwesomeIcon icon={faCalendarPlus} className="dot-past" />; // Past events
        }
    
        if (eventStartDate <= now && eventEndDate >= now) {
            if (currentDate < now) {
                return <FontAwesomeIcon icon={faCalendarPlus} className="dot-ongoing-past" />; // Past part of ongoing events
            } else {
                return <FontAwesomeIcon icon={faCalendarPlus} className="dot-ongoing-active" />; // Future part of ongoing events
            }
        }
    
        if (eventStartDate > now && eventStartDate <= threeDaysFromNow) {
            return <FontAwesomeIcon icon={faCalendarPlus} className="dot-active" />; // Upcoming events
        }
    
        if (eventStartDate > threeDaysFromNow) {
            return <FontAwesomeIcon icon={faCalendarPlus} className="dot-future" />; // Future events
        }
    
        return null;
    };
    const handleIconClick = (e, event) => {
        e.stopPropagation(); // Prevent triggering cell click
        setSelectedCourse(event);
    };

    const fetchRegisteredCourseDetails = async (registeredCoursesMap) => {
        try {
            const courseDetailsPromises = Object.values(registeredCoursesMap).map(async course => {
                const courseDoc = await getDoc(doc(db, 'courses', course.courseId));
                if (courseDoc.exists()) {
                    const sessionDoc = await getDoc(doc(db, `courses/${course.courseId}/sessions`, course.sessionId));
                    if (sessionDoc.exists()) {
                        const courseData = {
                            courseId: courseDoc.id,
                            ...courseDoc.data(),
                            sessionId: sessionDoc.id,
                            ...sessionDoc.data(),
                            timeSlot: course.timeSlot
                        };
                        return courseData;
                    } else {
                        console.error(`No session found for ID ${course.sessionId}`);
                        return null;
                    }
                } else {
                    console.error(`No course found for ID ${course.courseId}`);
                    return null;
                }
            });

            const courseDetails = (await Promise.all(courseDetailsPromises)).filter(course => course !== null);
            setRegisteredCourses(courseDetails);
        } catch (error) {
            console.error('Error fetching course details:', error);
            toast.error('Failed to fetch course details.');
        }
    };

    const addIconsToCell = (eventsForDay, currentDate) => {
        console.log("eventsForDay", eventsForDay)
        return (
            <div className="event-icon-wrapper">
                {eventsForDay.map((event, index) => {
                    const dotIcon = getDotIcon(event, currentDate);
                    const iconStyle = { bottom: `${index * 30}px` }; 
                    return (
                        <span
                            key={index}
                            className="event-icon"
                            onClick={(e) => handleIconClick(e, event)}
                            style={iconStyle}
                        >
                            {dotIcon}
                        </span>
                    );
                })}
            </div>
        );
    };

    const handleCellClick = (day) => {
        const selectedDayEvents = parsedCourses.filter(course => {
            const eventStartDate = new Date(course.parsedStartDate);
            const eventEndDate = new Date(course.parsedEndDate);
            return (eventStartDate.getDate() === day && eventStartDate.getMonth() === selectedMonth && eventStartDate.getFullYear() === selectedYear) ||
                (eventEndDate.getDate() === day && eventEndDate.getMonth() === selectedMonth && eventEndDate.getFullYear() === selectedYear);
        });
        if (selectedDayEvents.length > 0) {
            setSelectedCourse(selectedDayEvents[0]);
        }
    };

    const getEventMarkerColor = (event, currentDate) => {
        const now = new Date();
        const threeDaysFromNow = new Date();
        threeDaysFromNow.setDate(now.getDate() + 3);
        const { parsedStartDate, parsedEndDate } = event;
        const eventStartDate = new Date(parsedStartDate);
        const eventEndDate = new Date(parsedEndDate);

        if (currentDate.toDateString() === eventStartDate.toDateString()) {
            return '';
        }

        if (eventEndDate < now) {
            if (currentDate > eventStartDate && currentDate <= eventEndDate) {
                return '';
            }
        }

        if (eventStartDate <= now && eventEndDate >= now) {
            if (currentDate > eventStartDate && currentDate < now && currentDate <= eventEndDate) {
                return '';
            }
            if (currentDate >= now && currentDate <= eventEndDate) {
                return '';
            }
        }

        if (eventStartDate > now && eventStartDate <= threeDaysFromNow) {
            if (currentDate > eventStartDate && currentDate <= eventEndDate) {
                return '';
            }
        }

        if (eventStartDate > threeDaysFromNow) {
            if (currentDate > eventStartDate && currentDate <= eventEndDate) {
                return '';
            }
        }

        return '';
    };

    useEffect(() => {
        if (activeTab === 'activities') {
            dispatch(fetchUserData(user.uid)).unwrap().then((data) => {
                if (data && data.registeredCourses) {
                    fetchRegisteredCourseDetails(data.registeredCourses);
                } else {
                    console.log('No registered courses found.');
                }
            })
                .catch((error) => {
                    console.error('Error fetching user data:', error);
                    toast.error('Failed to fetch registered courses.');
                });
        }
    }, [activeTab, dispatch, user.uid]);

    const tabs = [
        {
          name: 'View in Calendar',
          value: 'calendar',
          content: (
            <div>
              <h2 className="text-xl font-semibold text-gray-800">Calendar View</h2>
              <p className="mt-4 text-gray-600">
                This is the calendar view.
              </p>
            </div>
          ),
        },
        {
          name: 'View in List',
          value: 'list',
          content: (
            <div>
              <h2 className="text-xl font-semibold text-gray-800">List View</h2>
              <p className="mt-4 text-gray-600">
                This is the list view.
              </p>
            </div>
          ),
        },
      ];

      const renderContent = () => {
        const upcomingCourses = getUpcomingEvents(parsedCourses);
        const pastCourses = getPastEvents(parsedCourses);
        const futureCourses = getFutureEvents(parsedCourses);
        const ongoingCourses = getOngoingEvents(parsedCourses);
        switch (activeTab) {
          case 'preSignUp':
            return (
              <PortalPreSignUp/>
            );
          case 'activities':
            return (
              <div className="space-y-6 animate-fadeInUp">
                <div>
                  <Tabs
                    tabs={tabs}
                    activeTabValue={activeViewTab}
                    handleTabClick={setActiveViewTab}
                  />
                </div>
      
                {activeViewTab === 'calendar' && (
                  <div className="calendar-container mt-6 animate-slideInLeft">
                    <RenderCalendar
                      events={[...pastCourses, ...ongoingCourses, ...upcomingCourses, ...futureCourses]}
                      selectedYear={selectedYear}
                      selectedMonth={selectedMonth}
                      getEventMarkerColor={getEventMarkerColor}
                      handleCellClick={handleCellClick}
                      addIconsToCell={addIconsToCell}
                      setSelectedYear={setSelectedYear}
                      setSelectedMonth={setSelectedMonth}
                      handleIconClick={handleIconClick}
                      colorPalette={colorPalette}
                    />
                  </div>
                )}
      
                {activeViewTab === 'calendar' && (
                  <div className="nextClass-container mt-6 animate-fadeInUp">
                    <RendarNextClass upcomingCourses={ongoingCourses} handleCourseClick={handleCourseClick} handleCourseClickReschedule={handleCourseClickReschedule} />
                  </div>
                )}
                {activeViewTab === 'list' && (
                    <div className="event-list-container mt-6 space-y-6 animate-slideInRight">
                        <div>
                        <h6 className="text-xl font-bold text-gray-800 mb-4">Ongoing Classes</h6>
                        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6">
                            {ongoingCourses.length > 0 ? (
                            ongoingCourses.map((course, index) => (
                                <CourseCard 
                                course={course}
                                onClick={() => handleCourseClick(course)}
                                onClickReschedule={() => handleCourseClickReschedule(course)}
                                />
                            ))
                            ) : (
                                <p className="text-left text-gray-400">
                                <FontAwesomeIcon icon={faBan} /> No ongoing events found.
                            </p>
                            )}
                        </div>
                        </div>

                        <div>
                        <h6 className="text-xl font-bold text-gray-800 mb-4">Upcoming Classes</h6>
                        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6">
                            {upcomingCourses.length > 0 ? (
                            upcomingCourses.map((course, index) => (
                                <CourseCard 
                                course={course}
                                onClick={() => handleCourseClick(course)}
                                onClickReschedule={() => handleCourseClickReschedule(course)}
                                />
                            ))
                            ) : (
                            <p className="text-left text-gray-400">
                                <FontAwesomeIcon icon={faBan} /> No upcoming events found.
                            </p>
                            )}
                        </div>
                        </div>

                        <div>
                        <h6 className="text-xl font-bold text-gray-800 mb-4">Past Classes</h6>
                        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6">
                            {pastCourses.length > 0 ? (
                            pastCourses.map((course, index) => (
                                <CourseCard 
                                course={course}
                                onClick={() => handleCourseClick(course)}
                                onClickReschedule={() => handleCourseClickReschedule(course)}
                                />
                            ))
                            ) : (
                            <p className="text-left text-gray-400">
                                <FontAwesomeIcon icon={faBan} /> No past events found.
                            </p>
                            )}
                        </div>
                        </div>
                    </div>
                )}

                
              </div>
            );
      
          case 'settings':
            return (
              <div className="mt-6 space-y-8 animate-blurIn">
                  {/* Personal Information Section */}
                  <div className="space-y-4">
                      <h2 className="mb-8 text-2xl sm:text-3xl font-medium text-text">Personal Information</h2>
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
                          {/* Name Field */}
                          <div className="form-group">
                              <label
                                  htmlFor="input-name"
                                  className="block text-sm font-medium text-text mb-1"
                              >
                                  Name
                              </label>
                              <input
                                  id="input-name"
                                  type="text"
                                  value={editFields.name}
                                  onChange={(e) => handleFieldChange('name', e.target.value)}
                                  className="block w-full p-2 sm:p-3 border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:border-primary sm:text-sm"
                              />
                          </div>
          
                          {/* Age Field */}
                          <div className="form-group">
                              <label
                                  htmlFor="input-age"
                                  className="block text-sm font-medium text-text mb-1"
                              >
                                  Age
                              </label>
                              <select
                                  id="input-age"
                                  value={editFields.age}
                                  onChange={(e) => handleFieldChange('age', e.target.value)}
                                  className="block w-full p-2 sm:p-3 border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:border-primary sm:text-sm"
                              >
                                  <option value="" disabled>
                                      Select Age
                                  </option>
                                  {[...Array(100)].map((_, i) => (
                                      <option key={i + 1} value={i + 1}>
                                          {i + 1}
                                      </option>
                                  ))}
                              </select>
                          </div>
          
                          {/* Gender Field */}
                          <div className="form-group">
                              <label
                                  htmlFor="input-gender"
                                  className="block text-sm font-medium text-text mb-1"
                              >
                                  Gender
                              </label>
                              <select
                                  id="input-gender"
                                  value={editFields.gender}
                                  onChange={(e) => handleFieldChange('gender', e.target.value)}
                                  className="block w-full p-2 sm:p-3 border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:border-primary sm:text-sm"
                              >
                                  <option value="" disabled>
                                      Select Gender
                                  </option>
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                  <option value="non-binary">Non-binary</option>
                                  <option value="prefer-not-to-say">Prefer not to say</option>
                                  <option value="other">Other</option>
                              </select>
                          </div>
                      </div>
                  </div>
          
                  {/* Contact Section */}
                  <div className="space-y-4">
                      <h2 className="mb-8 text-2xl sm:text-3xl font-medium text-text">Contact</h2>
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
                          {/* Email Field */}
                          <div className="form-group">
                              <label
                                  htmlFor="input-email"
                                  className="block text-sm font-medium text-text mb-1"
                              >
                                  Email
                              </label>
                              <input
                                  id="input-email"
                                  type="email"
                                  value={editFields.email}
                                  onChange={(e) => handleFieldChange('email', e.target.value)}
                                  className="block w-full p-2 sm:p-3 border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:border-primary sm:text-sm"
                              />
                          </div>
          
                          {/* Phone Number Field */}
                          <div className="form-group">
                              <label
                                  htmlFor="input-phone"
                                  className="block text-sm font-medium text-gray-700 mb-1"
                              >
                                  Phone Number
                              </label>
                              <input
                                  id="input-phone"
                                  type="text"
                                  value={editFields.phoneNumber}
                                  onChange={(e) => handleFieldChange('phoneNumber', e.target.value)}
                                  className="block w-full p-2 sm:p-3 border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:border-primary sm:text-sm"
                              />
                          </div>
                      </div>
                  </div>
          
                  {/* Level Section */}
                  <div className="space-y-4">
                      <h2 className="mb-8 text-2xl sm:text-3xl font-medium text-text">Level</h2>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 sm:gap-8">  
                          <div className="form-group">
                              <label
                                  htmlFor="input-programmingFluency"
                                  className="block text-sm font-medium text-text mb-1"
                              >
                                  Programming Fluency
                              </label>
                              <select
                                  id="input-programmingFluency"
                                  value={editFields.programmingFluency}
                                  onChange={(e) => handleFieldChange('programmingFluency', e.target.value)}
                                  className="block w-full p-2 sm:p-3 border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:border-primary sm:text-sm"
                              >
                                  <option value="" disabled>
                                      Select Level
                                  </option>
                                  <option value="beginner">Beginner</option>
                                  <option value="advance">Advance</option>
                                  <option value="expert">Expert</option>
                              </select>
                          </div>
                      </div>        
                  </div>
          
                  {/* Buttons */}
                  <div className="flex flex-col sm:flex-row sm:justify-start sm:space-x-4 space-y-4 sm:space-y-0 mt-6">
                      <button
                          onClick={handleUpdateDetails}
                          className="w-full sm:w-auto py-2 px-4 text-sm font-medium text-white bg-webDarker rounded-md shadow hover:bg-hoverColor transition-colors"
                      >
                          Update Details
                      </button>
                      <button
                          onClick={handleResetPassword}
                          className="w-full sm:w-auto py-2 px-4 text-sm font-medium text-primary bg-white border border-primary rounded-md shadow hover:bg-gray-100 transition-colors"
                      >
                          Reset Password
                      </button>
                  </div>
              </div>
          );
          
              
                
              
      
          case 'order-history':
            return (
              <div className="content-order-history mt-6 animate-blurI">
                <OrderHistory orders={user.orderHistory || {}} user={user} />
              </div>
            );
      
          default:
            return null;
        }
      };
      

      return (
        <div className="flex flex-col min-h-screen bg-[#EBF1FB4D]">
            <PortalHero imageUrl ={heroImage} user={user} userInitial={userInitial} handleLogout={handleLogout} setActiveTab={setActiveTab}></PortalHero>
            <div className="flex md:pl-32 bg-white sm:flex-row justify-center md:justify-start items-center border-b border-gray-200 mb-6 space-y-2 sm:space-y-0 sm:space-x-4">
                <button
                    className={`portal-tab py-3 px-6 text-lg font-medium ${
                    activeTab === 'activities' ? 'text-primary border-b-2 border-primary' : 'text-gray-500'
                    }`}
                    onClick={() => setActiveTab('activities')}
                >
                    Course Schedule
                </button>
                <button
                    className={`portal-tab py-3 px-6 text-lg font-medium ${
                    activeTab === 'preSignUp' ? 'text-primary border-b-2 border-primary' : 'text-gray-500'
                    }`}
                    onClick={() => setActiveTab('preSignUp')}
                >
                    Pre Signup
                </button>
                <button
                    className={`portal-tab py-3 px-6 text-lg font-medium ${
                    activeTab === 'order-history' ? 'text-primary border-b-2 border-primary' : 'text-gray-500'
                    }`}
                    onClick={() => setActiveTab('order-history')}
                >
                    Order History
                </button>
            </div>

            <div className="flex-1 flex flex-col">
                <div className="container mx-auto py-6 sm:py-8 md:py-10 px-4 sm:px-6 lg:px-8">

                    <div className="portal-content">
                    {renderContent()}
                    </div>
                </div>
            </div>
            {/* <ToastContainer /> */}
            <CourseParser courses={registeredCourses} onParsed={setParsedCourses} />
            {selectedCourse && <CourseModal course={selectedCourse} onClose={closeModal} />}
            {selectedCourseReschedule && <RescheduleModal course={selectedCourseReschedule} onClose={closeModalReschedule} />}
            <Footer />
        </div>
        );

      
      
}

export default React.memo(Portal);
