import React from 'react';
import { Link } from 'react-router-dom';
import {blogFormatterTrimmed} from '../BlogTemplate/blogFormatter';

const FeaturedPost = ({ id, title, tags, author, createdAt, content }) => {
  const trimmedContent = blogFormatterTrimmed(content);

  
  console.log("content:", author)
  const formattedDateTime = createdAt
    ? new Date(createdAt).toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    : 'Date unknown';

  return (
    <Link 
      to={`/blogs/${id}`} 
      className="block mb-6 p-4 rounded-md text-gray-800 hover:text-hoverColor hover:shadow-lg transition-colors"
    >
      <div className="space-y-1">
        <div className="text-sm text-webDarker">
          By Albuddy | {formattedDateTime}
        </div>
        <h3 className="text-xl font-medium leading-snug">{title}</h3>
        <div className=''>{trimmedContent}</div>
        <div>
          {tags.map((tag)=>(
            <span key={tag} className="inline-block bg-blue-50 rounded-full px-3 py-1 text-sm font-normal text-webDarker mr-2 my-2">
              {tag}
            </span>
          ))}
        </div>
      </div>
      <div className='border-b border-gray-300 w-full mt-6'/>
    </Link>
  );
};

export default FeaturedPost;
