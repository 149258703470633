import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BlogPreview from '../../components/BlogPreview';
import { fetchBlogPosts } from '../../redux/slices/blogSlice';
import Footer from '../../components/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faExclamationTriangle, faSmileBeam, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import FeaturedPost from '../../components/FeaturedPost';
import { analytics } from '../../api/firebaseConfig';
import { logEvent } from 'firebase/analytics';

function Blog() {
  const dispatch = useDispatch();
  const posts = useSelector((state) => state.blog.posts);
  const status = useSelector((state) => state.blog.status);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 2;  // Display two blogs per page

  useEffect(() => {
    dispatch(fetchBlogPosts({ numToFetch: 10 }));
    logEvent(analytics, 'page_view', { page_title: 'Blog Page' });
  }, [dispatch]);

  const handleBlogClick = (post) => {
    logEvent(analytics, 'blog_click', { post_id: post.id, post_title: post.title });
  };

  // Pagination logic
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);
  const totalPages = Math.ceil(posts.length / postsPerPage);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Next Page
  const nextPage = () => setCurrentPage(prevPage => Math.min(prevPage + 1, totalPages));

  // Previous Page
  const prevPage = () => setCurrentPage(prevPage => Math.max(prevPage - 1, 1));

  let content;
  if (status === 'loading') {
    content = (
      <div className="flex justify-center items-center py-16 text-webDarker">
        <FontAwesomeIcon icon={faSpinner} spin size="2x" />
      </div>
    );
  } else if (status === 'succeeded') {
    content = currentPosts.length > 0 ? currentPosts.map((post) => (
      <div onClick={() => handleBlogClick(post)} key={post.id} className="cursor-pointer">
        <BlogPreview
          id={post.id}
          tags={post.tags}
          title={post.title}
          summary={post.summary}
          createdAt={new Date(post.createdAt)}
          author={post.author}
          imageUrl={post.imageUrl}
          content={post.content}
        />
      </div>
    )) : (
      <div className="flex flex-col items-center justify-center py-16">
        <FontAwesomeIcon icon={faSmileBeam} size="3x" />
        <p>No matching posts found. Try a different search!</p>
      </div>
    );
  } else if (status === 'failed') {
    content = (
      <div className="flex flex-col items-center justify-center py-16">
        <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
        <p>Error loading posts. Please try again later.</p>
      </div>
    );
  }

  return (
    <div className='min-h-screen bg-white'>
      <Helmet>
        <title>The Agile Blog</title>
        <meta name="description" content="Explore expert tips and strategies that empower both students and educators." />
        <meta name="keywords" content="blog, education, tips, strategies, tech, Technology, EdTech" />
      </Helmet>
      <div className="mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-medium text-center mb-4">The Agile Blog</h1>
        <p className="text-lg text-center text-textGray mb-12">The latest and greatest news on education</p>
        
        <div className="flex flex-col lg:flex-row lg:space-x-4">
          <div className="lg:w-lg:w-2/3 xl:w-3/5 animate-slideInLeft">
            <div className="space-y-8">
              {content}
            </div>

            {/* Pagination Controls */}
            <div className="flex items-center justify-center space-x-12 mt-8">
              <button 
                onClick={prevPage}
                disabled={currentPage === 1}
                className="bg-blue-50 text-webDarker p-2 rounded-full hover:bg-webDarker hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>

              {/* Page numbers */}
              {[...Array(totalPages).keys()].map(num => (
                <span 
                  key={num} 
                  onClick={() => paginate(num + 1)} 
                  className={`cursor-pointer px-2 ${currentPage === num + 1 ? 'text-blue-600' : 'text-gray-500'}`}
                >
                  {num + 1}
                </span>
              ))}

              <button 
                onClick={nextPage}
                disabled={currentPage === totalPages}
                className="bg-blue-50 text-webDarker p-2 rounded-full hover:bg-webDarker hover:text-white disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <FontAwesomeIcon icon={faChevronRight}/>
              </button>
            </div>
          </div>
          
          <div className="lg:w-1/3 mt-12 lg:mt-0">
            <div className="space-y-12 animate-blurIn">
              {posts.slice(0, 3).map(post => (
                <div onClick={() => handleBlogClick(post)} key={post.id} className="cursor-pointer">
                  <FeaturedPost 
                    id={post.id}
                    title={post.title}
                    tags={post.tags}
                    author={post.author}
                    content={post.content}
                    createdAt={new Date(post.createdAt)}
                  />
                </div>
              ))}
            </div>
          </div>

        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Blog;
