import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as fullStar, faStarHalfAlt as halfStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons';

const InteractiveStarRating = ({ rating, setRating }) => {
  const [hoverIndex, setHoverIndex] = useState(-1);
  const [isHalfStar, setIsHalfStar] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const totalStars = 5;

  useEffect(() => {
    const checkScreenWidth = () => {
      if (window.innerWidth < 330) {
        setIsSmallScreen(true);
      } else {
        setIsSmallScreen(false);
      }
    };

    checkScreenWidth();
    window.addEventListener('resize', checkScreenWidth);

    return () => window.removeEventListener('resize', checkScreenWidth);
  }, []);

  const handleStarClick = (index, isHalf) => {
    setRating(index + (isHalf ? 0.5 : 1));
  };

  const handleMouseMove = (e, index) => {
    const { left, width } = e.currentTarget.getBoundingClientRect();
    const mouseX = e.clientX - left;
    if (mouseX < width / 2) {
      setHoverIndex(index);
      setIsHalfStar(true);
    } else {
      setHoverIndex(index);
      setIsHalfStar(false);
    }
  };

  return (
    <div className="flex items-center justify-center space-x-1 sm:space-x-0.5 md:space-x-1">
      {/* Conditionally render dropdown or stars based on screen size */}
      {isSmallScreen ? (
        <div className="relative">
          {/* Dropdown for rating */}
          <select
            value={rating}
            onChange={(e) => setRating(parseFloat(e.target.value))}
            className="block w-full p-2 text-base border border-gray-300 rounded-lg bg-white focus:outline-none focus:border-blue-500"
          >
            <option value="" disabled>
              Select Rating
            </option>
            {[...Array(totalStars)].map((_, index) => (
              <option key={index} value={index + 1}>
                {index + 1} Star{index > 0 ? 's' : ''}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div className="flex items-center space-x-1">
          {[...Array(totalStars)].map((_, index) => (
            <span
              key={index}
              className="relative cursor-pointer group"
              onClick={() => handleStarClick(index, isHalfStar)}
              onMouseMove={(e) => handleMouseMove(e, index)}
              onMouseLeave={() => {
                setHoverIndex(-1);
                setIsHalfStar(false);
              }}
              style={{ width: '24px', height: '24px' }}
            >
              {/* Star animation wrapper */}
              <span
                className={`transition-transform duration-300 transform group-hover:scale-125 group-active:scale-150 ${
                  hoverIndex === index && isHalfStar ? 'scale-110' : ''
                }`}
              >
                {/* Determine which star to show based on current rating and hover state */}
                {index + 1 <= rating || (index === hoverIndex && !isHalfStar && index + 1 <= rating) ? (
                  <FontAwesomeIcon
                    icon={fullStar}
                    className="text-[#69D4DD] sm:text-lg md:text-xl transition-all duration-300 ease-in-out transform"
                  />
                ) : index + 0.5 === rating || (index === hoverIndex && isHalfStar) ? (
                  <FontAwesomeIcon
                    icon={halfStar}
                    className="text-[#69D4DD] sm:text-lg md:text-xl transition-all duration-300 ease-in-out transform"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={emptyStar}
                    className="text-gray-300 sm:text-lg md:text-xl transition-all duration-300 ease-in-out transform"
                  />
                )}
              </span>
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default InteractiveStarRating;
