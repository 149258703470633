import React from 'react';
import { Link } from 'react-router-dom';
import defaultBanner from '../../assets/img/blogBanner.png';
import { blogFormatterTrimmed } from '../BlogTemplate/blogFormatter';

const BlogPreview = ({ id, title, summary, imageUrl, tags, createdAt, author, large, content }) => {
  const trimmedContent = blogFormatterTrimmed(content, 150); // Trim content to 150 characters
  const bannerImageUrl = imageUrl || defaultBanner;
  const formattedDateTime = createdAt
    ? new Date(createdAt).toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      })
    : 'Date unknown';

  return (
    <Link 
      to={`/blogs/${id}`} 
      className="flex flex-col gap-4 py-1 px-14 hover:bg-gray-50 transition-all duration-300"
    >
      {/* Banner Image */}
      <div className="w-full">
        <img 
          src={bannerImageUrl} 
          alt={title} 
          className="w-full h-[300px] object-cover rounded-lg shadow-sm"
        />
      </div>

      {/* Content Section */}
      <div className="flex flex-col space-y-2">
        {/* Author and Date */}
        <div className="text-webDarker text-sm">
          {`Albuddy`} {formattedDateTime && `| ${formattedDateTime}`}
        </div>

        {/* Blog Title */}
        <h2 className="text-2xl font-medium text-gray-800 leading-snug hover:text-webDarker transition-colors duration-300">
          {title}
        </h2>

        {/* Blog Summary */}
        <p className="text-gray-600 text-base sm:text-base">
          {trimmedContent}
        </p>

        {/* Tags */}
        {tags?.length > 0 && (
          <div className="flex flex-wrap gap-2 mt-2">
            {tags.map((tag, index) => (
              <span 
                key={index} 
                className="bg-blue-50 text-webDarker rounded-full px-3 py-1 text-xs sm:text-sm font-normal"
              >
                {tag}
              </span>
            ))}
          </div>
        )}
      </div>
    </Link>
  );
};

export default BlogPreview;
