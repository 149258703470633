import { useEffect } from 'react';

const convertTo24HourFormat = (time) => {
    time = time.trim();
    const timeRegex = /^(\d{1,2})(?::(\d{2}))?\s*(am|pm)?$/i;
    const match = time.match(timeRegex);
    if (!match) return null;

    let [hours, minutes, modifier] = [match[1], match[2], match[3]];
    hours = parseInt(hours, 10);
    minutes = parseInt(minutes || '0', 10);

    if (modifier) {
        modifier = modifier.toLowerCase();
        if (modifier === 'pm' && hours !== 12) {
            hours += 12;
        } else if (modifier === 'am' && hours === 12) {
            hours = 0;
        }
    }

    return { hours, minutes };
};

const parseTimeSlot = (timeSlot) => {
    if (!timeSlot) return { startTime: null, endTime: null };
    const [start, end] = timeSlot.split(' - ');

    const startTime = convertTo24HourFormat(start);
    const endTime = convertTo24HourFormat(end);

    return { startTime, endTime };
};

const CourseParser = ({ courses, onParsed }) => {
    useEffect(() => {
        if (courses.length > 0) {
            const parsed = courses.map((course) => {
                const { startDate, endDate, timeSlot } = course;
                const { startTime, endTime } = parseTimeSlot(timeSlot);

                const parsedStartDate = startDate.toDate();
                const parsedEndDate = endDate.toDate();

                if (startTime) {
                    parsedStartDate.setHours(startTime.hours, startTime.minutes, 0, 0);
                }

                if (endTime) {
                    parsedEndDate.setHours(endTime.hours, endTime.minutes, 0, 0);
                }

                return {
                    ...course,
                    parsedStartDate,
                    parsedEndDate,
                };
            });
            onParsed(parsed);
        }
    }, [courses, onParsed]);

    return null;
};

export default CourseParser;
