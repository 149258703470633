
import React from 'react';
import { useNavigate } from 'react-router-dom';

const DiscountMessage = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate('/courses');
  };

  return (
    <div className="fixed left-0 right-0 min-w-full bg-webDarker text-white text-center py-2 animate-slideDown">
      <span>
        30% OFF on all courses! Use code{' '}
        <span
          onClick={handleNavigation}
          className="font-extrabold underline cursor-pointer"
        >
          Albuddylaunch24
        </span>
      </span>
    </div>
  );
};

export default DiscountMessage;