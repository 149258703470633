import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { collection, getDocs, query, orderBy, limit, startAfter, doc, getDoc } from 'firebase/firestore';
import { db } from '../../api/firebaseConfig';

export const fetchBlogPosts = createAsyncThunk(
  'blog/fetchPosts',
  async ({ lastVisible, numToFetch }, { rejectWithValue }) => {
    try {
      const blogsRef = collection(db, 'blogs');
      let q = query(blogsRef, orderBy('createdAt', 'desc'), limit(numToFetch));

      if (lastVisible) {
        q = query(blogsRef, orderBy('createdAt', 'desc'), startAfter(lastVisible), limit(numToFetch));
      }

      const querySnapshot = await getDocs(q);
      let posts = [];
      let newLastVisible = null;

      if (!querySnapshot.empty) {
        newLastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        posts = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            createdAt: data.createdAt.toDate().toISOString() // Convert Timestamp to ISO String
          };
        });
      }

      return { posts, lastVisible: newLastVisible ? newLastVisible.id : null }; // Store only the document ID
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchSingleBlogPost = createAsyncThunk(
  'blog/fetchSinglePost',
  async (postId, { rejectWithValue }) => {
    try {
      const postRef = doc(db, 'blogs', postId);
      const postSnap = await getDoc(postRef);

      if (postSnap.exists()) {
        const postData = postSnap.data();
        return {
          id: postSnap.id,
          ...postData,
          createdAt: postData.createdAt.toDate().toISOString() // Convert Timestamp to ISO String
        };
      } else {
        return rejectWithValue('Post not found');
      }
    } catch (err) {
      console.error('Error fetching single blog post:', err.message);
      return rejectWithValue(err.message);
    }
  }
);

const blogSlice = createSlice({
  name: 'blog',
  initialState: {
    posts: [],
    lastVisible: null,
    status: 'idle',
    error: null,
    loadingSinglePost: 'idle',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogPosts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBlogPosts.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.posts = action.payload.posts;
        state.lastVisible = action.payload.lastVisible;
        console.log('Blog posts fetched successfully:');
      })
      .addCase(fetchBlogPosts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        console.error('Error fetching blog posts:', state.error);
      })
      .addCase(fetchSingleBlogPost.pending, (state) => {
        state.loadingSinglePost = 'loading';
      })
      .addCase(fetchSingleBlogPost.fulfilled, (state, action) => {
        state.loadingSinglePost = 'idle';
        const index = state.posts.findIndex(post => post.id === action.payload.id);
        if (index !== -1) {
          state.posts[index] = action.payload;
        } else {
          state.posts.push(action.payload);
        }
        console.log('Single blog post fetched successfully:', action.payload);
      })
      .addCase(fetchSingleBlogPost.rejected, (state, action) => {
        state.loadingSinglePost = 'failed';
        state.error = action.payload || 'An error occurred while fetching the post';
        console.error('Error fetching single blog post:', state.error);
      });
  }
});

export default blogSlice.reducer;
