import React, { useState, useEffect } from 'react';
import Footer from '../../components/Footer';
import { analytics } from '../../api/firebaseConfig';
import { logEvent } from 'firebase/analytics';

const questionsAnswers = [
  {
    question: "How does the signup process work?",
    answer: "Signing up is easy! Go to courses, choose your preferred course, after the payment, the course will be displayed instantly in your portal."
  },
  {
    question: "What payment methods do you accept?",
    answer: "Partnering with Stripe, we accept all major credit cards, including Visa, MasterCard, and American Express. You can also pay through PayPal, Apple Pay, and Google Pay for your convenience."
  },
  {
    question: "Are there any prerequisites for joining a course?",
    answer: "No prior knowledge is needed. Our courses guide you through realistic projects using agile development methods, providing all necessary training and support."
  },
  {
    question: "How can I track my progress?",
    answer: "Our platform features a personalized dashboard where you can see your scheduled courses. Each course you registered will provide a link to the scheduled meeting."
  },
  {
    question: "What is your refund policy?",
    answer: "Before the course starts, you can receive a 100% refund. During the first week, you will receive a 75% refund. In the second week, the refund is 50%, and from the third week until the course finishes, the refund is 25%. If you complete the course, we will provide you with in-store credit."
  },
  {
    question: "Do you offer any group workshops or seminars?",
    answer: "Yes, the courses are hosted in either group works or individualized work depending on the course type. These courses cover a range of topics and offer a dynamic way to engage with the material and other learners."
  },
  {
    question: "How do you ensure the quality of online instruction?",
    answer: "Our online instruction quality is maintained through a rigorous selection of qualified tutors, ongoing training, and feedback systems. We also use the latest in educational technology to provide an interactive and comprehensive learning experience."
  },
];

function FAQPage() {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
    logEvent(analytics, 'faq_toggle', { question: questionsAnswers[index].question });
  };

  useEffect(() => {
    logEvent(analytics, 'page_view', { page_title: 'FAQ Page' });
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <div className="flex-grow py-12 px-4 lg:px-8 xl:px-16 max-w-screen-lg mx-auto">
        <h1 className="text-4xl lg:text-5xl font-medium text-center text-primary mb-10">Frequently Asked Questions</h1>
        <div className="space-y-6">
          {questionsAnswers.map((qa, index) => (
            <div
              key={index}
              className={`border-b ${activeIndex === index ? 'bg-glassmorphic' : 'bg-white'} rounded-md shadow-lg transition-colors duration-300`}
            >
              <button
                onClick={() => handleToggle(index)}
                aria-expanded={activeIndex === index ? "true" : "false"}
                aria-controls={`answer-${index}`}
                className="w-full text-left p-6 font-medium text-xl flex justify-between items-center"
              >
                {qa.question}
                <span className={`transform ${activeIndex === index ? 'rotate-180' : 'rotate-0'} transition-transform duration-300 text-webDarker`}>
                  {activeIndex === index ? '-' : '+'}
                </span>
              </button>
              {activeIndex === index && (
                <div id={`answer-${index}`} className="p-6 text-textGray animate-blurIn">
                  {qa.answer}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FAQPage;
