import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CoursePreview from '../../components/CoursePreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { analytics } from '../../api/firebaseConfig';
import { logEvent } from 'firebase/analytics';
import isEqual from 'lodash.isequal';

const categories = ['All', 'Featured', 'IBDP', 'language'];

function CoursePage() {
  const { courses, status, error } = useSelector((state) => state.courses);
  const [searchResult, setSearchResult] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');

  useEffect(() => {
    if (status === 'succeeded') {
      let result = Array.isArray(courses) ? [...courses] : [];

      // Apply category filter
      if (selectedCategory !== 'All') {
        result = result.filter((course) =>
          course?.category?.includes(selectedCategory)
        );
      }

      // Update searchResult only if it has changed
      if (!isEqual(searchResult, result)) {
        setSearchResult(result);
      }
    }
  }, [courses, selectedCategory, status, searchResult]);

  // Log category change
  useEffect(() => {
    if (selectedCategory !== 'All') {
      logEvent(analytics, 'filter_category', { category: selectedCategory });
    }
  }, [selectedCategory]);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div
      id="featuredCourses"
      className="min-h-screen flex flex-col justify-between bg-[#EBF1FB4D]"
    >
      <main className="flex-grow">
        {/* Hero Section */}
        <div className="bg-none py-10">
          <header className="flex flex-col items-center justify-center mb-10 px-4 md:px-10 text-center max-w-7xl mx-auto">
            <div>
              <h1 className="text-4xl font-medium text-webDarker">
                Perfect Online Courses
              </h1>
              <h1 className="text-4xl font-medium text-webDarker mt-2">
                Picked for You
              </h1>
            </div>
          </header>

          {/* Filter Menu */}
          <div className="flex justify-center items-center space-x-14 mt-4">
            {categories.map((category, index) => (
              <React.Fragment key={category}>
                <button
                  className={`px-4 py-2 text-lg ${
                    selectedCategory === category
                      ? 'text-blue-600 border-b-2 border-blue-600'
                      : 'text-gray-500'
                  }`}
                  onClick={() => handleCategoryChange(category)}
                >
                  {category}
                </button>
                {index < categories.length - 1 && (
                  <span className="text-textGray text-2xl">|</span> /* Divider */
                )}
              </React.Fragment>
            ))}
          </div>
        </div>

        {/* Main Content */}
        <div className="px-4 md:px-10 max-w-7xl mx-auto">
          <div className="text-center mb-6">
            {searchResult.length > 0 ? (
              <p className="text-lg text-gray-800"></p>
            ) : (
              <p className="text-lg text-gray-800">No Available Courses</p>
            )}
          </div>

          {status === 'loading' ? (
            <div className="flex justify-center items-center py-10">
              <FontAwesomeIcon
                icon={faSpinner}
                className="text-4xl text-webDarker animate-spin"
              />
            </div>
          ) : error ? (
            <p className="text-red-400 text-center">Error: {error}</p>
          ) : (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6 lg:gap-8 mb-10">
              {searchResult.map((course) => (
                <div key={course?.id} className="rounded-lg animate-blurIn">
                  <CoursePreview course={course} />
                </div>
              ))}
            </div>
          )}
        </div>
      </main>

      {/* Sticky View All Courses Button */}
      <div className="sticky bottom-0 bg-none py-4 mb-2">
        <div className="flex justify-center">
          <Link to="/courses">
            <button className="bg-blue-500 text-white px-6 py-3 rounded-full hover:bg-blue-600 transition duration-300">
              View all courses
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default React.memo(CoursePage);
