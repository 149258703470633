import React, { useEffect, useState } from 'react';

const SampleSchedule = ({ schedule, removeHeader }) => {
    const [expanded, setExpanded] = useState({});
    const [sortedSchedule, setSortedSchedule] = useState({});
    
    const toggleWeek = (week) => {
        setExpanded((prev) => ({ ...prev, [week]: !prev[week] }));
    };
    
    const sortSchedule = (schedule) => {
        const sortedWeeks = Object.keys(schedule).sort((a, b) => {
            const weekA = parseInt(a.split(' ')[1], 10);
            const weekB = parseInt(b.split(' ')[1], 10);
            return weekA - weekB;
        });
    
        const sortedSchedule = {};
        sortedWeeks.forEach((week) => {
            sortedSchedule[week] = schedule[week];
        });
    
        return sortedSchedule;
    };

    useEffect(() => {
        if (!schedule) return;
        setSortedSchedule(sortSchedule(schedule));
    }, [schedule]);

    if (!sortedSchedule || Object.keys(sortedSchedule).length === 0) {
        return null;
    }

    return (
        <div className={`${removeHeader ? 'mt-0' : 'mt-12'} px-8`}>
            <h2 className={`text-3xl font-medium mb-4 ${removeHeader ? 'hidden' : ''}`}>Sample Schedule</h2>
            {Object.keys(sortedSchedule).map((week, index) => (
                <div key={index} className="mb-4">
                    <div className="flex justify-start items-center cursor-pointer" onClick={() => toggleWeek(week)}>
                        <span 
                            className={`text-blue-600 text-3xl mr-2 transition-transform duration-300 transform ${expanded[week] ? 'rotate-180' : 'rotate-0'}`}
                        >
                            {expanded[week] ? '−' : '+'}
                        </span>
                        <h3 className="text-lg font-bold">{week}</h3>
                    </div>
                    {expanded[week] && (
                        <div className="mt-2 pl-4 animate-slideInLeft">
                            {Object.entries(sortedSchedule[week]).map(([day, activities], idx) => (
                                <div key={idx} className="mb-2">
                                    <h4 className="text-md font-semibold animate-blurIn">{day}</h4>
                                    <ul className="list-disc list-inside text-gray-700 animate-slideInRight">
                                        {activities.map((activity, idy) => (
                                            <li key={idy}>{activity}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default SampleSchedule;
